export const LeadActionTypes = {
   LOAD_LEADS: 'LOAD_LEADS',
   FETCH_LEADS_START: 'FETCH_LEADS_START',
   FETCH_LEADS_SUCCESS: 'FETCH_LEADS_SUCCESS',
   FETCH_LEADS_FAILURE: 'FETCH_LEADS_FAILURE',
   FETCH_FILTERED_LEADS: 'FETCH_FILTERED_LEADS',
   TOGGLE_LEAD_FORM_HIDDEN: 'TOGGLE_LEAD_FORM_HIDDEN',
   CHANGE_LEAD_FORM_ID: 'CHANGE_LEAD_FORM_ID',
   UPDATE_LEAD: 'UPDATE_LEAD',
   UPDATE_LEADS_FILTER_CONDITION: 'UPDATE_LEADS_FILTER_CONDITION',
   SET_CURRENT_LEAD: 'SET_CURRENT_LEAD',
   UPDATE_LEAD_ACTION: 'UPDATE_LEAD_ACTION',
   FETCH_LEAD_ACTION_SUCCESS: 'FETCH_LEAD_ACTION_SUCCESS',
   FETCH_LEAD_ACTION_FAILURE: 'FETCH_LEAD_ACTION_FAILURE',
   TOGGLE_EDIT_FORM_SHOW: 'TOGGLE_EDIT_FORM_SHOW',
   DELETE_LEAD_SUCCESS: 'DELETE_LEAD_SUCCESS',
   DELETE_LEAD_FAILURE: 'DELETE_LEAD_FAILURE',

};