import { LeadActionTypes } from './lead.types';

const INITIAL_STATE = {
    isFetching: false,
    errorMessage: undefined,
    isLoaded: false,
    leadFormHidden: true,
    leadFormId: '',
    leads: [],
    filterLeadsCondition: 'affiliateId',
    leadActionsLogs: [],
    currentLead: '',
    editFormShow: false
};

const leadReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case LeadActionTypes.FETCH_LEADS_START:
            return{
                ...state,
                isFetching: true,
                isLoaded: false
            };
        case LeadActionTypes.FETCH_LEADS_SUCCESS:
            return{
                ...state,
                isFetching: false,
                leads: action.payload,
                isLoaded: true
            };
        case LeadActionTypes.FETCH_LEADS_FAILURE:
            return{
                ...state,
                isFetching: false,
                errorMessage: action.payload,
                isLoaded: false
            };
        case LeadActionTypes.TOGGLE_LEAD_FORM_HIDDEN:
            return{
                ...state,
                leadFormHidden: !state.leadFormHidden,
                leadFormId: action.payload
            }
        case LeadActionTypes.CHANGE_LEAD_FORM_ID:
            return{
                ...state,
                leadFormId: action.payload
            }
        case LeadActionTypes.UPDATE_LEAD:
            return{
                ...state,
                leads: action.payload
            }
        case LeadActionTypes.UPDATE_LEADS_FILTER_CONDITION:
            return{
                ...state,
                filterLeadsCondition: action.payload
            }
        case LeadActionTypes.SET_CURRENT_LEAD:
            return{
                ...state,
                currentLead: action.payload 
            }
        case LeadActionTypes.UPDATE_LEAD_ACTION:
            return{
                ...state,
                leadActionsLogs: action.payload
            }
        case LeadActionTypes.FETCH_LEAD_ACTION_SUCCESS:
            return{
                ...state,
                leadActionsLogs: action.payload
            }
        case LeadActionTypes.FETCH_LEAD_ACTION_FAILURE:
            return{
                ...state,
            }
        case LeadActionTypes.TOGGLE_EDIT_FORM_SHOW:
            return{
                ...state,
                editFormShow: !state.editFormShow,
            }
        case LeadActionTypes.DELETE_LEAD_SUCCESS:
            return{
                ...state,
                leads: action.payload,
            }
        case LeadActionTypes.DELETE_LEAD_FAILURE:
            return{
                ...state,
            }
        default:
            return state;
    }
};

export default leadReducer;

