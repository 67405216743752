import React from 'react';
import './items-dropdown.styles.scss'
import FormInput from '../form-input/form-input.component';
import uuid from 'react-uuid';

class ItemsDropDown extends React.Component{
    constructor(){
        super();

        this.state = {
            searchField: '',
            items:[],
            monsters:[],
        }
    }

    componentDidMount(){
        const {items} = this.props;
        this.setState({
            items: items,
        })
    }

    onSearchChange = event => {
        this.setState({ searchField: event.target.value });
      };

    handleClick = (item) =>{
        const {name, keyToMap, clickItem} = this.props;
        clickItem(item, name, keyToMap);
       
    }

    render(){
        const {keyToMap, name} = this.props;
        const {items, searchField} = this.state;
        const filteredItems = items.filter(item=>{
            if(item[keyToMap]){
            return(
                 item[keyToMap].toLowerCase().includes(searchField.toLowerCase())
            )}
            return false
        })

        return(
            <div className='iddc-table'>
                <div className='iddc-row'>
                    <div className='iddc-close' onClick={()=>this.props.toggleDropDown(name)}>&#10006;</div>
                </div>
                
                <div className='iddc-search'>
                <FormInput
                    type='search'
                    name={uuid()} //prevent autofill hack
                    id='search'
                    value={this.state.searchField}
                    onChange={this.onSearchChange}
                    label='search'
                />
                </div>

                <div className = 'iddc-list'>
                {filteredItems.map((item, i)=>{
                    return(
                        <div key={uuid()} className='iddc-items' onClick={()=>{this.handleClick(item)}}>{item[keyToMap]}</div>
                    )
                })}    
                </div>
            </div>
        )
    }
}

export default ItemsDropDown 