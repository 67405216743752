import { PhotoActionTypes } from './photo.types';

const INITIAL_STATE = {
    photos: [],
    isFetching: false,
};

const photoReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case PhotoActionTypes.LOAD_PHOTOS_START:
            return{
                ...state,
                isFetching: true,
            }
        case PhotoActionTypes.LOAD_PHOTOS_SUCCESS:
            return{
                ...state,
                photos: action.payload
            }
        default:
            return state
    }
}

export default photoReducer