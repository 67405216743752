import firebase from "../../firebase/firebase.utils";

export const trainings = [
  {
    createdBy: "Dave Chong",
    id: "abc1",
    trainingId: "1",
    createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    description: "[English] Social Media Strategy For Property Agents | Adrian Seow",
    author: "Adrian Seow",
    tag: ["Digital Marketing"],
    category: "Leads",
    language: "English",
    videoUrl: "https://www.youtube.com/watch?v=heM3OOtuaTM&list=PLoGU-9V--6PSQrFZo9DsSiJfuuSp1uX_Y",
    imageUrl: "https://adrianseowmarketing.com/wp-content/uploads/2020/06/Classes-Attended-768x432.jpg",
    documentUrl: "https://www.youtube.com/watch?v=heM3OOtuaTM&list=PLoGU-9V--6PSQrFZo9DsSiJfuuSp1uX_Y",
    seen: ['Dave Chong', 'Tony Yap'],
    liked: ['Dave Chong', 'Tony Yap'],
  },
  {
    createdBy: "Dave Chong",
    id: "abc2",
    trainingId: "2",
    createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    description: "[English] Branding vs Selling? Adrian Seow",
    author: "Adrian Seow",
    tag: ["Digital Marketing"],
    category: "Marketing",
    language: "English",
    videoUrl: "https://www.youtube.com/watch?v=DqCe_rWVsMM&list=PLoGU-9V--6PSQrFZo9DsSiJfuuSp1uX_Y&index=2",
    imageUrl: "http://adrianseow.co/wp-content/uploads/2019/07/28516066_10214393643128570_6157464474964390359_o-768x576.jpg",
    documentUrl: "https://www.youtube.com/watch?v=DqCe_rWVsMM&list=PLoGU-9V--6PSQrFZo9DsSiJfuuSp1uX_Y&index=2",
    seen: ['Dave Chong', 'Tony Yap'],
    liked: ['Dave Chong', 'Tony Yap'],
  },
];
