import { SaleActionTypes } from './sale.types';

const INITIAL_STATE = {
    sales: [],
    isFetching: false,
    isSalesLoaded:{ isLoaded: false, lastLoaded:'2020-05-01'},
    isTeamSalesLoading: false,
    flattenedSales: [],
    teamSales: [],
    totalSalesAndCommForRens: [],
    totalSalesAndCommForProjects: [],
    saleReport: '',
    salesDisplayYear: (new Date()).getFullYear(),
};

const saleReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case SaleActionTypes.FETCH_SALES_START:
            return{
                ...state,
                isFetching: true,
            }
        case SaleActionTypes.FETCH_SALES_SUCCESS:
            return{
                ...state,
                sales: action.payload,
                isSalesLoaded: {isLoaded: true, lastLoaded: new Date()},
                isFetching: false,
            }
        case SaleActionTypes.ADD_SALE_SUCCESS:
            return {
                ...state,
                sales: action.payload,
            }
        case SaleActionTypes.UPDATE_SALE:
            return {
                ...state,
                sales: action.payload,
            }
        case SaleActionTypes.LOAD_FLATTENED_SALES:
            return{
                ...state,
                flattenedSales: action.payload,
            }
        case SaleActionTypes.DELETE_SALE_SUCCESS:
            return{
                ...state,
                sales: action.payload,
            }
        case SaleActionTypes.LOAD_TEAM_SALES_START:
            return{
                ...state,
                isTeamSalesLoading: true,
                isFetching: true
            }
        case SaleActionTypes.LOAD_TEAM_SALES_SUCCESS:
            return{
                ...state,
                teamSales: action.payload,
                isTeamSalesLoading: false,
                isFetching: false,
            }
        case SaleActionTypes.LOAD_TEAM_SALES_FAILURE:
            return{
                ...state,
                teamSales: action.payload,
                isTeamSalesLoading: false,
                isFetching: false,
            }
        case SaleActionTypes.SALE_SIGN_OUT:
            return INITIAL_STATE
        case SaleActionTypes.LOAD_TOTAL_SALES_AND_COMM_FOR_RENS_SUCCESS:
            return{
                ...state,
                totalSalesAndCommForRens: action.payload,
            }
        case SaleActionTypes.UPDATE_SALE_REPORT:
            return{
                ...state,
                saleReport: action.payload,
            }
        case SaleActionTypes.LOAD_DATA_IN_SEQUENCE_START:
            return{
                ...state,
                isFetching: true
            }
        case SaleActionTypes.LOAD_TOTAL_SALES_BY_PROJECTS_SUCCESS:
            return{
                ...state,
                totalSalesAndCommForProjects: action.payload
            }
        default:
            return state
    }
}

export default saleReducer