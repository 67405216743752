import React from 'react';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import {addCollectionAndDocuments, dateToFirestoreTimestamp} from './seeding.utils';
import CustomButton from '../components/custom-button/custom-button.component';
import { selectProjectProjects } from '../Redux/project/project.selector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

class SeedProjects extends React.Component {
    constructor(){
        super()

        this.state = {
            cols: [],
            rows: [],
            obj: [],
            commStructures: [],
        }
    }

    render(){
        const obj = [];
        let commStructures = [];
        const fileHandler = (event) => {
            let fileObj = event.target.files[0];
        
            //just pass the fileObj as parameter
            ExcelRenderer(fileObj, (err, resp) => {
              if(err){
                console.log(err);            
              }
              else{
                this.setState({
                  cols: resp.cols,
                  rows: resp.rows
                },()=>{
                    this.state.rows.forEach((row,i)=>{
                        if(i){ //skip header

                           const newObj = {
                               projectId: row[0],
                               projectName: row[1],
                               location: row[2],
                               developer: row[3],
                               commStructureId: row[0],
                               createdAt: row[10],
                               updatedAt: row[11],
                           }

                           const newCommStructure ={
                                commStructureId: row[0],
                                commRate: [{
                                                ren: row[4],
                                                date: row[5],
                                            },
                                            {
                                                ren: row[6] ? row[6] : '',
                                                date: row[7] ? row[7] : '',
                                            },
                                            {
                                                ren: row[8] ? row[8] : '',
                                                date: row[9] ? row[9] : '',
                                            }]
                                
                                        }
                                obj.push(newObj);
                                commStructures.push(newCommStructure);        
                           }
                    })
                    console.log(obj);
                    console.log(commStructures);
                    this.setState({...this.state, obj: obj, commStructures: commStructures});
                });

              }
            });               
          }

        const handleClick = ()=>{
            
            console.log("CLICKED!")
            console.log(this.state.obj);
            console.log(this.state.commStructures);
            addCollectionAndDocuments('projects', this.state.obj, 'projectId');
            addCollectionAndDocuments('commStructures', this.state.commStructures, 'commSt');
        }

        const handleDateToTimestamp = () => {
            const {projects} = this.props;
            console.log('CLICKED!')
            dateToFirestoreTimestamp(projects, 'projects', 'createdAt');
        }

        return(
            <div>
            <h1>Seed Projects</h1>
                <input type="file" onChange={fileHandler.bind(this)} style={{"padding":"10px"}} />
                <div>
                    <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                    <p></p>
                    <CustomButton onClick={handleClick}>Upload to Firestore</CustomButton>
                    <p></p>
                    <CustomButton onClick={handleDateToTimestamp}>Test Date to Timestamp</CustomButton>
                </div>
            </div>
           
        )
    }
}

const mapStateToProps = createStructuredSelector({
    projects: selectProjectProjects,
  });
  
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SeedProjects) 