import { createSelector } from 'reselect';

const selectLead = state => state.lead;

export const selectLeadLeads = createSelector(
  [selectLead],
  lead => lead.leads
);

export const selectLeadisFetching = createSelector(
  [selectLead],
  lead => lead.isFetching
);

//Filter the leads based on referralID from currentUser
const selectCurrentUser = state => state.user.currentUser;
const selectLeads = state => state.lead.leads;
const selectLeadFilterLeadsCondition = state => state.lead.filterLeadsCondition;
export const selectLeadFilteredLeads = createSelector(
  [selectLeads, selectLeadFilterLeadsCondition, selectCurrentUser],
  (leads, filterLeadsCondition, currentUser) => {
    let sortedLeads = [];
   
    if(leads && currentUser){
      if(currentUser.affiliateId){
        switch (filterLeadsCondition){
        case 'affiliateId':
            const filteredLeads = leads.filter(lead=>(
            lead.affiliateId === currentUser.affiliateId || lead.referral === currentUser.affiliateId));
            //sort leads by date
            sortedLeads = filteredLeads.sort((a,b)=>new Date(b.date) - new Date(a.date));
            return sortedLeads
        case 'all':
            //sort leads by date
            //only allow admin to view ALL LEADS
            if(currentUser.isAdmin){
               sortedLeads = leads.sort((a,b)=>new Date(b.date) - new Date(a.date));
              return sortedLeads
            }else{return null} 
        default:
          return null
      }
      }else{return null}  
    }  
  }
);

export const selectLeadisLoaded = createSelector(
  [selectLead],
  lead => lead.isLoaded
);

export const selectLeadLeadFormHidden = createSelector(
  [selectLead],
  lead => lead.leadFormHidden
)

export const selectLeadLeadFormId = createSelector(
  [selectLead],
  lead => lead.leadFormId
)

export const selectLeadCurrentLead = createSelector(
  [selectLead],
  lead => lead.currentLead 
)

export const selectLeadActionsLogs = createSelector(
  [selectLead],
  lead => lead.leadActionsLogs
)

export const selectLeadEditFormShow = createSelector(
  [selectLead],
  lead => lead.editFormShow
)