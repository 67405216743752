import React from 'react';
import {connect} from 'react-redux';
import User from '../user/user.component';
import { createStructuredSelector } from 'reselect';
import uuid from 'react-uuid';
import './user-list.styles.scss'

class UserList extends React.Component{

render(){
    const {users} = this.props;
    const fieldToDisplay = {
        userId: 'Id',
        displayName: 'Name',
        email: 'Email',
        phoneNumber: 'Phone',
        immediateUpline: 'Upline',
        location: 'Location',
        numberOfSales: 'No. of Sales',
        delete: 'Delete',
    }

    return(
        <div className='userlist'>
        <h1>User List</h1>
        {users.map((user, i)=>(
            <div key={uuid()}>
                <User user={user} fieldToDisplay={fieldToDisplay} i={i}/>
            </div>
        ))}
    </div>

    )
}
}

const mapStateToProps = createStructuredSelector({
    
  });

export default connect(mapStateToProps)(UserList)