import {firestore} from '../../firebase/firebase.utils';
import { dateFormat} from '../../util-functions/date-format';

export const convertLeadsSnapshotToMap = leads => {
    const transformedLeads = leads.docs.map(doc=>{
      return{
        ...doc.data(),
        id: doc.id,
        date: dateFormat(doc.data().date),
      };  
    })
    return transformedLeads;
  };

export const updateLeadInArray = (newLead, leads) =>{
    const newLeads = leads.map(lead =>{
      if(lead.id === newLead.id){
        return newLead
      }else{
        return lead
      }
    })
    
    const {id, ...otherProps} = newLead;
    const leadRef = firestore.collection('leads').doc(newLead.id);
    leadRef.get().then(snapShot=>{
      if(snapShot.exists){
        leadRef.set({
          ...otherProps
        }).then(()=>{
          console.log("updated to Firestore")
        }).catch(err=>{
          console.log(err.message);
        })
      }
    }).catch(err=>{
      console.log(err.message);
    })

    return newLeads;
}


