import { createSelector } from 'reselect';
import {numberToPercentage} from '../../util-functions/numer-formating';
import {getRen, getProject} from './sale.utils';

const selectSales = state => state.sale.sales;
export const selectSaleSales = createSelector(
  [selectSales],
  (sales) => sales
);

const selectSale = state => state.sale;
export const selectSaleIsFetching = createSelector(
  [selectSale],
  (sale) => sale.isFetching
);

export const selectSaleIsLoaded =  createSelector(
  [selectSale],
  (sale) => sale.isSalesLoaded
)

//flatten the Sales and Ren array
//e.g if ren has 2 objects, 2 sales will be generated
export const selectSaleFlattenedSales = createSelector(
  [selectSale],
  sale => sale.flattenedSales
)

// put all renNames in 1 line of sale
// e.g. if ren has 2 objects, 1 row of sale will be generated with Ren1 and Ren2
const selectProjects = state => state.project.projects;
const selectUsers = state => state.user.users;
export const selectSaleCombinedRenSales = createSelector(
  [selectSales, selectUsers, selectProjects],
  (sales, users, projects) =>{
    let salesArray=[];
    let maxNumberOfRens = 1;

    sales.forEach((sale, i)=>{
      const {rens, ...others} = sale;
      let saleObject = {};
      rens.forEach((ren,j)=>{
        if(j===0){
          saleObject={
             ...others,
             projectName: getProject(sale.projectFid, projects).projectName,
            [`ren${j+1}Name`]: getRen(ren.userFid, users).displayName,
            [`ren${j+1}Percentage`]: numberToPercentage(ren.renPercentage),
            numberOfRen: 1
          } 
          }else {
            saleObject[`ren${j+1}Name`] = getRen(ren.userFid, users).displayName;
            saleObject[`ren${j+1}Percentage`] = numberToPercentage(ren.renPercentage);
            saleObject['numberOfRen'] = j+1;
            saleObject['projectName'] = getProject(sale.projectFid, projects).projectName;
            if(j+1>maxNumberOfRens){maxNumberOfRens=j+1};
        }
      })
      salesArray.push(saleObject);
      
    })
    return {salesArray, maxNumberOfRens}
  }
)

export const selectSaleTeamSales = createSelector(
  [selectSale],
  sale => sale.teamSales
)

export const selectSaleTotalSalesAndCommForRens = createSelector(
  [selectSale],
  sale => sale.totalSalesAndCommForRens
)

export const selectSaleSaleReport = createSelector(
  [selectSale],
  sale => sale.saleReport
)

export const selectSaleTotalSalesAndCommForProjects = createSelector(
  [selectSale],
  sale => sale.totalSalesAndCommForProjects
)
