import React from 'react';
import './comm-rate.styles.scss';

import { withRouter } from "react-router-dom";
import { dateFormat } from '../../util-functions/date-format';


class CommRate extends React.Component{

    handleClick = (key) =>{
       
    }

    render(){
        const {rate, i, handleDeleteRate} = this.props;
        const {date, ...others} = rate;

        let fieldToDisplay = ({date: '', ...others, delete:''});
      
        return(
            <React.Fragment>
            {!i &&
            <div className='crc-row-header'>
                {Object.keys(fieldToDisplay).map((key,i) => {
                    return(
                        <div key={`header${i}`} className='crc-item'>{key}</div>
                    )
                })}
            </div>
            }
            <div className={'crc-row'}>
            {Object.keys(fieldToDisplay).map((key,j) => {
                return(
                    <div key={`${fieldToDisplay}${j}`} className='crc-item'>
                    {(()=>{switch(key){
                        case 'delete':
                            return <div style={{cursor: 'pointer'}} onClick={()=>handleDeleteRate(i)}>&#10006;</div>
                        case 'date':
                            return <div>{dateFormat(rate[key])}</div>
                        default:
                            return <div>{rate[key]}</div>
                    }
                    })()}
                    </div>
                )
            })}
            </div>
            </React.Fragment>
        )
    }
}

export default withRouter(CommRate);

