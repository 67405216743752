import React from 'react';
import './user-edit-form.styles.scss';
import {connect} from 'react-redux';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import {createStructuredSelector} from 'reselect';
import {updateUser, toggleEditFormShow} from '../../Redux/user/user.actions'
import {selectUserUsers, selectUserEditFormShow} from '../../Redux/user/user.selector'

class UserEditForm extends React.Component {
    constructor(){
        super()

        this.state={
            value: ''
        }
    }

    componentDidMount(){
        const {initialValue} = this.props;
        this.setState({
            ...this.state,
            value: initialValue,
        })
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        let {user, users, updateUser, para, toggleEditFormShow} = this.props;

        //if para = isAdmin to it into a boolean
        let valueToBeSubmitted = '';
        if(para==='isAdmin'){
            if(this.state.value === 'true'){
                valueToBeSubmitted = true
            } else {valueToBeSubmitted = false}
        }else{valueToBeSubmitted = this.state.value}
            
        user = ({
            ...user,
            [para]: valueToBeSubmitted
        })
        updateUser(user, users)
        toggleEditFormShow();
    }

    render(){
        const {user, para, toggleEditFormShow} = this.props
        return(
            <div className='uef-table'>
                <div className='uef-row'>
                    <div className='uef-close' onClick={toggleEditFormShow}>&#10006;</div>
                    <div className='uef-label'>{para}</div>
                    <div className='uef-value'>{user[para]}</div>
                    <div className='uef-form'>
                        <form onSubmit={this.handleSubmit}>
                            <FormInput 
                                handleChange={this.handleChange} 
                                name='value'
                                type='text'
                                value={this.state.value}
                                label={'edit'}
                            />
                        <CustomButton type='submit'> Update </CustomButton>
                    </form>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = createStructuredSelector({
    users: selectUserUsers,
    editFormShow: selectUserEditFormShow
  });

const mapDispatchToProps = dispatch => ({
    updateUser: (user, users) => dispatch(updateUser(user, users)),
    toggleEditFormShow: ()=> dispatch(toggleEditFormShow())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEditForm)