import { firestore } from "../firebase/firebase.utils";

export const sendTelegramMessage = async (chatId, message, sender) => {
  const telegramRef = firestore.collection("telegram");
  telegramRef
    .add({
      createdAt: new Date(),
      sender: sender,
      chatId: chatId,
      message: message,
    })
    .then((doc) => {
      console.log("Telegram Message Sent to " + chatId + "\nID: " + doc.id );
    })
    .catch((err) => {
      console.log(err);
    });
};
