import React, {useState} from "react";
import firebase from "../../firebase/firebase.utils.js";
import CustomButton from '../custom-button/custom-button.component';

const UploadPhotos = (props) => {
    const initialFile = {
        name: "Choose File"
    }
    
    const [progress, setProgress] = useState(0);
    const [selectedFile, setFile] = useState(initialFile);
    const [formData, setFormData] = useState({name: '', imageTag: props.imageTag});

    const saveToFB = (imgURL)=>{
        const image = {
            name: formData.name,
            fileName: selectedFile.name,
            imageTag: formData.imageTag,
            imgURL: imgURL
        }

        firebase.firestore()
            .collection("images")
            .add(image)
            .then(()=>{
                console.log("Document Added");
                setFormData({name: '', imageTag: props.imageTag});
                
            }).catch(err => {
                console.log(err);
        })
    }

    const handleFormChange = (e) => {
        const nam = e.target.name;
        const val = e.target.value;
        setFormData({
            ...formData,
            [nam]: val
        });
    }

    const handleChange = (e)=>{
        const file = e.target.files[0];
        setFile(file);
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        const storageRef = firebase.storage()
            .ref('images/' + selectedFile.name);
    
        const task = storageRef.put(selectedFile);
    
        task.on("state_changed",
            function progress(snapshot){
                let percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(percentage);
            },
    
            function error(err){
                console.log(err);
            },
    
            function complete(){
                storageRef.getDownloadURL().then(function(downloadURL) {
                    console.log('File available at', downloadURL);
                    setProgress(0);
                    saveToFB(downloadURL);
                  }); 
            }
        )
    }

    return (
        <div className="container">
            
            <div className="row justify-content-center add-padding">
                <div className="col-lg-6 col-md-8">
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{width: `${progress}%`}} aria-valuenow={parseInt(progress)} aria-valuemin="0" aria-valuemax="100">{parseInt(progress)}%</div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center add-padding">
                <div className="col-lg-6 col-md-8">
                    <form onSubmit={handleSubmit}>
                    <div className="custom-file form-group">
                      <input type="file" accept="image/*" onChange={handleChange} className="custom-file-input" id="fileButton" />
                      <label className="custom-file-label" htmlFor="fileButton">{selectedFile.name ? selectedFile.name : ''}</label>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control" id="name" name="name" aria-describedby="emailHelp" placeholder="Name" value={formData.name} onChange={handleFormChange} />
                    </div>
                    <div className="mt-3">
                        <CustomButton type="submit">Submit</CustomButton>
                    </div>
                  </form>

                </div>
            </div>
        </div>
        
    )
}

export default UploadPhotos;