import React from "react";
import { OutTable, ExcelRenderer } from "react-excel-renderer";
import {
  addCollectionAndDocuments,
  fillInSalesProjectId,
  updateSalesWithUserFid,
  fillInProjectsWithCommStructureFid,
  loadSalesIdIntoUplines,
  turnDoneToConverted,
  removeDuplicatedSales,
  testTelegram,
} from "./seeding.utils";
import CustomButton from "../components/custom-button/custom-button.component";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectSaleSales } from "../Redux/sale/sale.selectors";
import { selectUserUsers } from "../Redux/user/user.selector";
import { selectProjectProjects } from "../Redux/project/project.selector";
import firebase from "../firebase/firebase.utils";
import { forceLoadAllData } from "../Redux/functions/reducer-functions";

class SeedSales extends React.Component {
  constructor() {
    super();

    this.state = {
      cols: [],
      rows: [],
      obj: [],
    };
  }

  render() {
    const { users, projects, sales } = this.props;
    let usersWithNoFid = [];

    const getUserFid = (renName) => {
      const foundUser = users.find(
        (user) => user.displayName.toLowerCase() === renName.toLowerCase()
      );
      if (!foundUser) {
        usersWithNoFid.push(renName);
        console.log("Cannot Find userFid for " + renName);
        return "";
      }
      return foundUser.id;
    };

    const getProjectFid = (projectName) => {
      const foundProject = projects.find(
        (project) => project.projectName === projectName
      );
      if (!foundProject) {
        console.log("Cannot Find projectFid for " + projectName);
        return "";
      }
      return foundProject.id;
    };

    const obj = [];
    const fileHandler = (event) => {
      let fileObj = event.target.files[0];

      //just pass the fileObj as parameter
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          this.setState(
            {
              cols: resp.cols,
              rows: resp.rows,
            },
            () => {
              this.state.rows.forEach((row, i) => {
                if (i) {
                  //skip header
                  let rens = [];
                  for (let i = 0; i < 13; i = i + 2) {
                    if (row[i + 7]) {
                      rens.push({
                        renName: row[i + 7],
                        renPercentage: row[i + 8],
                        userFid: getUserFid(row[i + 7]),
                      });
                    }
                  }
                  console.log(rens);
                  const newObj = {
                    saleId: row[0],
                    date: firebase.firestore.Timestamp.fromDate(
                      new Date(row[1])
                    ),
                    status: row[2] === "Done" ? "Converted" : row[2],
                    projectName: row[3],
                    projectFid: getProjectFid(row[3]),
                    unitNumber: row[4],
                    buyerName: row[5],
                    buyerSource: row[6] ? row[6] : "",
                    unitSize: row[21] ? row[21] : "",
                    spaPrice: row[22],
                    netPrice: row[23],
                    rens: rens,
                  };
                  obj.push(newObj);
                }
              });
              console.log(obj);
              this.setState({ ...this.state, obj: obj });
              console.log("Users with no Fid");
              console.log(usersWithNoFid);
            }
          );
        }
      });
    };

    const handleClick = async () => {
      console.log("CLICKED!");
      console.log(this.state.obj);
      let objectsArray = [];
      let x = Math.floor(this.state.obj.length / 500);
      for (let n = 0; n < x + 1; n++) {
        let object = [];
        for (let i = n * 500; i < (n + 1) * 500; i++) {
          if (this.state.obj[i]) {
            console.log("I am " + i + " in group" + n);
            object.push(this.state.obj[i]);
          }
        }
        objectsArray.push(object);
      }
      console.log(objectsArray);

      // const seedObjects = this.state.obj.splice(0,2);
      // console.log(seedObjects)
      // addCollectionAndDocuments('sales', seedObjects, 'saleId');

      objectsArray.forEach((object, i) => {
        addCollectionAndDocuments("sales", object, "saleId");
      });
    };

    const handleDateToTimestamp = () => {
      // const {sales} = this.props;

      console.log("CLICKED!");
      // dateToFirestoreTimestamp(sales, 'sales', 'date');
    };

    const testFunction = async () => {
      const sale = {
        date: "2020-02-03",
        saleId: "123",
        projectId: "112312",
        projectName: "Millerz",
        unitNo: "A-12-12",
        unitSize: "550sf",
        buyer: "John",
        spaPrice: "780000",
        netPrice: "680000",
        package: "10%",
        remark: "",
        ren: [
          {
            renId: "60126098229",
            renPercentage: "0.5",
            renName: "Dave Chong",
          },
          {
            renId: "60165462258",
            renPercentage: "0.5",
            renName: "John Teh",
          },
        ],
      };
      const url =
        "https://asia-east2-eliteone-react-sales-app.cloudfunctions.net/receiveSale";
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sale),
        mode: "no-cors",
      };
      const response = await fetch(url, requestOptions);
      console.log(response);
    };

    return (
      <div>
        <h1>Seed Sales</h1>
        <input
          type="file"
          onChange={fileHandler.bind(this)}
          style={{ padding: "10px" }}
        />
        <div>
          <CustomButton onClick={handleClick}>Upload to Firestore</CustomButton>
          <p></p>
          <CustomButton onClick={fillInSalesProjectId}>
            Fill In Sales With Projects ID
          </CustomButton>
          <p></p>
          <CustomButton onClick={updateSalesWithUserFid}>
            Fill In Sales With User ID
          </CustomButton>
          <p></p>
          <CustomButton onClick={fillInProjectsWithCommStructureFid}>
            Fill In Projects With Comm Structures Fid
          </CustomButton>
          <p></p>
          <OutTable
            data={this.state.rows}
            columns={this.state.cols}
            tableClassName="ExcelTable2007"
            tableHeaderRowClass="heading"
          />
          <p></p>
          <CustomButton onClick={handleDateToTimestamp}>
            Date to Timestamp
          </CustomButton>
          <p></p>
          <CustomButton
            onClick={() => {
              loadSalesIdIntoUplines(sales, users);
            }}
          >
            Load Sales Ids into Uplines
          </CustomButton>
          <p></p>
          <CustomButton
            onClick={() => {
              turnDoneToConverted();
            }}
          >
            Turn Done to Converted
          </CustomButton>
          <p></p>
          <CustomButton
            onClick={() => {
              removeDuplicatedSales();
            }}
          >
            Remove Duplicated Sales in Sales Index
          </CustomButton>
          <p></p>
          <CustomButton
            onClick={() => {
              testTelegram(sales);
            }}
          >
            Test Telegram
          </CustomButton>
          <p></p>
          <CustomButton onClick={testFunction}>Test Function</CustomButton>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  sales: selectSaleSales,
  users: selectUserUsers,
  projects: selectProjectProjects,
});

const mapDispatchToProps = (dispatch) => ({
  forceLoadAllData: () => {
    dispatch(forceLoadAllData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SeedSales);
