import { PhotoActionTypes } from './photo.types';
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { firestore } from '../../firebase/firebase.utils';
import firebase from "../../firebase/firebase.utils";
import {getFileName} from '../../util-functions/getFileName';
import {loadPhotosSuccess} from './photo.actions';

export function* loadPhotos ({payload: {imageTag}}) {
    const imageRef = firestore.collection("images").where("imageTag","==", imageTag);
    const snapShots = yield imageRef.get();
    const images = snapShots.docs.map(doc=>({
        id: doc.id,
        ...doc.data()
    }))
    let fNames = images.map(image => image.fileName.replace(getFileName(image.fileName), getFileName(image.fileName) + '_1980x1980'));
    const storageRefs = fNames.map(fName => firebase.storage().ref('images/' + fName));
    const urls = yield Promise.all(storageRefs.map(storageRef => storageRef.getDownloadURL()));
    yield put(loadPhotosSuccess(urls));
}

export function* onLoadPhotosStart() {
    yield takeLatest(PhotoActionTypes.LOAD_PHOTOS_START, loadPhotos);
}

export function* photoSagas() {
    yield all([
        call(onLoadPhotosStart),
       
    ]);
}