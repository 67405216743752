import { ProjectActionTypes } from './project.types';
import { CommissionActionTypes } from '../commission/commission.types';
import {firestore} from '../../firebase/firebase.utils';
import {convertProjectsSnapshotToMap} from './project.utils';
// import {addNumberOfProjects} from '../parameter/parameter.actions';

export const addProject = project => {
    return (dispatch, getState) => {
        const collectionRef = firestore.collection('projects')
        collectionRef.add(project)
        .then(()=>{
            const oldProjectArray = getState().project.projects;
            let newProjectArray = [...oldProjectArray];
            newProjectArray.push(project);
            console.log('New Project Added into Firestore~!')
            dispatch({
                type: ProjectActionTypes.ADD_PROJECT_SUCCESS,
                payload: newProjectArray,
            })
            // dispatch(addNumberOfProjects());
        }).catch(error=>{
            console.log(error)
        })
    }
}

export const addProjectAndCommStructure = (project, commStructure) => {
    return async (dispatch, getState) => {
        let collectionRef = firestore.collection('commStructures');
        let commStructureFid = '';

        try{
                const doc = await collectionRef.add(commStructure);
                commStructureFid = doc.id;
               
                const oldCommStructureArray = getState().commission.commStructures;
                let newCommStructureArray = [...oldCommStructureArray];
                newCommStructureArray.push(commStructure);
                console.log('New Comm Strcuture Added into Firestore~!')
                dispatch({
                    type: CommissionActionTypes.ADD_COMM_STRUCTURE_SUCCESS,
                    payload: newCommStructureArray,
                }) 
        } catch(error) {
            console.log(error);
        }
       
        try{
            collectionRef = firestore.collection('projects')
            await collectionRef.add({...project, commStructureFid: commStructureFid})
               
                const oldProjectArray = getState().project.projects;
                let newProjectArray = [...oldProjectArray];
                newProjectArray.push({...project, commStructureId: commStructure.commStructureId});
                console.log('New Project Added into Firestore~!')
                dispatch({
                    type: ProjectActionTypes.ADD_PROJECT_SUCCESS,
                    payload: newProjectArray,
                })
        } catch(error){
            console.log(error);
        }
    }
}

export const fetchProjectsStartAsync = () =>{
    return (dispatch) => {
        const collectionRef = firestore.collection('projects');
        dispatch({
            type: ProjectActionTypes.FETCH_PROJECTS,
        })
        
        collectionRef
            .get()
            .then(snapShot =>{
                const objectsMap = convertProjectsSnapshotToMap(snapShot)
                dispatch({
                    type: ProjectActionTypes.FETCH_PROJECTS_SUCCESS,
                    payload: objectsMap,

                })
            }).catch(err=>{
                console.log(err.message)
            })
    }
}
