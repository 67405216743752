import { createSelector } from 'reselect';

const selectCommission = state => state.commission;

export const selectCommissionCommissions = createSelector(
  [selectCommission],
  commission => commission.commissions
);

export const selectCommissionIsLoaded = createSelector(
  [selectCommission],
  commission => commission.isCommStructuresLoaded
)

export const selectCommissionCurrentCommStructure = createSelector(
  [selectCommission],
  (commission) => commission.currentCommStructure
)

export const selectCommissionIsFetching = createSelector(
  [selectCommission],
  (commission) => commission.isFetching
)