import { firestore, auth } from "../../firebase/firebase.utils";

export const addUser = (users, userToAdd) => {
  return [...users, userToAdd];
};

export const convertUsersSnapshotToMap = (users) => {
  const transformedUsers = users.docs.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
      numberOfSales: doc.data().personalSalesIndex
        ? doc.data().personalSalesIndex.length
        : 0,
    };
  });
  return transformedUsers.sort((a, b) => b.userId - a.userId);
};

export const deleteUserFromFirestore = (users, userToBeDeleted) => {};

export const updateUserInArray = (newUser, users) => {
  const newUsers = users.map((user) => {
    if (user.id === newUser.id) {
      return newUser;
    } else {
      return user;
    }
  });
  console.log(newUser);

  const { id, ...otherProps } = newUser;
  const userRef = firestore.collection("users").doc(newUser.id);
  userRef
    .get()
    .then((snapShot) => {
      if (snapShot.exists) {
        userRef
          .set({
            ...otherProps,
          })
          .then(() => {
            console.log("updated to Firestore");
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    })
    .catch((err) => {
      console.log(err.message);
    });

  console.log(newUsers);
  return newUsers;
};

export const loadUserProfileDocument = async (userAuth) => {
  if (!userAuth) return;
  const usersRef = firestore
    .collection("users")
    .where("email", "==", userAuth.email);
  const snapShot = await usersRef.get();
  const docs = snapShot.docs;

  if (!docs || !docs.length) {
    console.log("Error loading Firestore. Please refresh!");
    alert("Error loading database. Please refresh~");
    return null;
  }

  if (snapShot.empty) {
    //no user with the email address provided
    console.log("User has no data in Database");
    return null;
  }

  let userId = docs[0].id; //first user with the email
  console.log(docs[0].data());
  const userRef = firestore.collection("users").doc(userId);

  return userRef;
};

export const createUserProfileInFS = async (userAuth, additionalData) => {
  if (!userAuth) return;
  const userRef = firestore.collection("users").doc(userAuth.uid);
  const downlinesRef = firestore.collection("downlines");
  const parametersRef = firestore.collection("parameters").doc("parameters");

  const snapShot = await userRef.get();
  const parametersSnapShot = await parametersRef.get();
  const userIdCount = parametersSnapShot.data().userIdCount + 1;

  if (!snapShot.empty) {
    const { email } = userAuth;

    try {
      await userRef.set({
        ...additionalData,
        email,
        userId: userIdCount,
      });
      console.log("Successfully created user profile in Firestore.");
      await downlinesRef.add({
        downlines: [
          { userFid: userAuth.uid, userName: additionalData.displayName },
        ],
        updatedAt: new Date(),
        createdAt: new Date(),
        userFid: userAuth.uid,
        userName: additionalData.displayName,
      });
      console.log("Successfully added ownself into downline");
      await parametersRef.set({
        ...parametersSnapShot.data(),
        userIdCount: userIdCount + 1,
      });
      console.log("Added userIdCount");
      const userSnapshot = await firestore
        .collection("users")
        .where("email", "==", email)
        .get();
      const user = {
        ...userSnapshot.docs[0].data(),
        id: userSnapshot.docs[0].id,
      };
      return user;
    } catch (error) {
      console.log("error creating user", error.message);
    }
  } else {
    console.log("User with the same UID is in the system! Please check.");
  }
};

export const getCurrentUserFromFirebase = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

export const updateUplines = async (userProfile) => {
  //Get immediateUplineFid
  //Get doc from downlines collection with userId === immediateUplineFid
  //Push userProfile.id and userProfile.displayName into downlines doc
  //Find the next immediateUplineFid for the immediateUpline
  //repeat the same

  const updateDownlines = async (user) => {
    const downlineRef = firestore
      .collection("downlines")
      .where("userFid", "==", user.id);
    const downlineSnapshot = await downlineRef.get();
    if (downlineSnapshot.empty) {
      alert("Error retrieving downlines. Pls contact your admin.");
    } else {
      const downline = {
        id: downlineSnapshot.docs[0].id,
        ...downlineSnapshot.docs[0].data(),
      }; //first doc
      const downlines = [...downline.downlines];
      console.log(downlines);
      const userExist = downlines.find(
        (downline) => downline.userFid === userProfile.id
      );
      if (!userExist) {
        downlines.push({
          userFid: userProfile.id,
          userName: userProfile.displayName,
        });
        firestore
          .collection("downlines")
          .doc(downline.id)
          .set({ ...downline, downlines: downlines, updatedAt: new Date() })
          .then(() => {
            console.log(
              "Updated Downlines in Firestore for " + user.displayName
            );
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log(
          userProfile.displayName + " is inside downlines array already"
        );
      }
    }
  };

  const findUpline = async (user) => {
    console.log("User is " + user.displayName);
    if (!user.immediateUplineFid) {
      console.log(user.displayName + " has no Upline");
      return null;
    }
    const userRef = firestore.collection("users").doc(user.immediateUplineFid);
    if (userRef.empty) {
      console.log("Cannot find upline in Firestore");
      return null;
    } else {
      const userSnapshot = await userRef.get();
      const upline = { id: userSnapshot.id, ...userSnapshot.data() };
      console.log("Upline is " + upline.displayName);
      await updateDownlines(upline);
      await findUpline(upline);
    }
  };

  findUpline(userProfile);
};

export const forgotPassword = async (email) => {
  const userSnapshot = await firestore
    .collection("users")
    .where("email", "==", email)
    .get();
  if (!userSnapshot.empty) {
    try {
      const resetPasswordRef = firestore.collection("resetPassword");
      await resetPasswordRef.add({ email, createdAt: new Date() });
      alert("Reset password link has been sent to " + email + "\nPlease check your mailbox.")
    } catch (err) {
      console.log(err.message);
    }
  } else {
    alert(email + " is not found in the system. Please contact your admin");
  }
};
