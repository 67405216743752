import React, { useEffect, useState } from "react";
import "./trainings-page.styles.scss";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectTrainingTrainings } from "../../Redux/training/training.selectors";
import moment from "moment";
import TrainingCreatePage from "../../components/training-create-page/training-create-page.component";
import {
  loadTrainings,
  updateTraining,
} from "../../Redux/training/training.actions";
import { selectCurrentUser } from "../../Redux/user/user.selector";
import FormInput from "../../components/form-input/form-input.component";
import uuid from "react-uuid";

const TrainingsPage = ({
  trainings,
  loadTrainings,
  currentUser,
  updateTraining,
}) => {
  useEffect(() => {
    console.log(trainings);
  }, [trainings]);

  useEffect(() => {
    loadTrainings();
    // eslint-disable-next-line
  }, []);

  const [open, setOpen] = useState(false);
  const [currentTraining, setCurrentTraining] = useState();
  const [search, setSearch] = useState("");

  const handleClose = () => {
    setOpen(false);
    setCurrentTraining(null);
  };

  const addNewTraining = () => {
    console.log("Click on Add new training");
    setOpen(true);
  };

  const handleEdit = (index) => {
    console.log(trainings[index].description);
    setCurrentTraining(trainings[index]);
    setOpen(true);
  };

  const handleClick = (index) => {
    const updatedTraining = trainings[index];
    const seenObject = { seenBy: currentUser.id, seenAt: new Date() };
    updatedTraining["seen"].push(seenObject);
    updateTraining(updatedTraining);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setSearch(value);
  };

  const filteredTrainings = trainings.filter((training) => {
    return (
      training.description.toLowerCase().includes(search.toLowerCase()) ||
      training.category.toLowerCase().includes(search.toLowerCase()) ||
      training.author.toLowerCase().includes(search.toLowerCase())
    );
  });

  return (
    <div className="tpc-table">
      {open ? (
        <TrainingCreatePage
          currentTraining={currentTraining}
          onClose={handleClose}
        />
      ) : (
        <div></div>
      )}
      <div className="tpc-row-1">
        <div className="tpc-button" onClick={addNewTraining}>
          Add New Training
        </div>
        <div className="tpc-search">
          <FormInput
            type="text"
            name={uuid()}
            id="search"
            value={search}
            onChange={handleChange}
            label="Search"
          />
        </div>{" "}
        {search ? (
          <div className="tpc-item tpc-pointer" onClick={() => setSearch("")}>
            &#10006;
          </div>
        ) : (
          <div className="tpc-item"></div>
        )}
      </div>
      {filteredTrainings.map((training, i) => (
        <div className="tpc-row-1" key={`${training.id}`}>
          <div className="tpc-item tpc-item1">
            <a
              href={training.documentUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              <img
                className="tpc-image"
                src={training.imageUrl}
                alt={training.description}
                onClick={() => handleClick(i)}
              ></img>
            </a>
          </div>
          <div className="tpc-item tpc-item2">
            <div className="tpc-table">
              <div className="tpc-row-0">
                <div
                  className="tpc-author tpc-blue tpc-pointer"
                  onClick={() => {
                    setSearch(training.author);
                  }}
                >
                  {training.author}
                </div>
                <div className="tpc-item4">
                  {moment(new Date(training.createdAt.seconds * 1000)).format(
                    "YYYY-MM-DD"
                  )}{" "}
                </div>
                <div
                  className="tpc-item4 tpc-pointer"
                  onClick={() => handleEdit(i)}
                >
                  {training.createdBy === currentUser.id ? "Edit" : null}
                </div>
              </div>
              <div className="tpc-row-0">
                <div className="tpc-item3">{training.description}</div>
              </div>
              <div className="tpc-row-0 tpc-row-left tpc-row-mt-15">
                <div
                  className="tpc-category tpc-pointer"
                  onClick={() => {
                    setSearch(training.category);
                  }}
                >
                  {training.category}
                </div>
                <div className="tpc-seen">{`Seen ${training.seen.length} times`}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const mapStatesToProps = createStructuredSelector({
  trainings: selectTrainingTrainings,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  loadTrainings: () => dispatch(loadTrainings()),
  updateTraining: (trainingData) => dispatch(updateTraining(trainingData)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(TrainingsPage);
