import { createSelector } from 'reselect';
import { numberWithCommas } from '../../util-functions/numer-formating';

const selectCampaigns = state => state.campaign.campaigns;
const selectProjects = state => state.project.projects;
const selectCampaign = state => state.campaign;

export const selectCampaignCampaigns = createSelector(
  [selectCampaigns, selectProjects],
  (campaigns, projects) => {
    if(!campaigns || !campaigns.length){
      console.log('No campaigns are loaded')
      return null
    }
    const getProjectName = (projectFid) => {
        const projectName = projects.find(project => project.id === projectFid).projectName
        return projectName
    }
    
    let campaignsMap = campaigns.map(campaign => {
        let projectsMap = campaign.projects.map(project=>{
                return {
                    ...project,
                    projectName: getProjectName(project.projectFid)
                }
            })
        return {
          ...campaign,
          salesValue: numberWithCommas(campaign.salesValue),
          projects: projectsMap
        }
    })

    return campaignsMap
  }
);

export const selectCampaignCampaignTotalSales = createSelector(
  [selectCampaign],
  (campaign) => campaign.campaignTotalSales
)

