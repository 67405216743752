export const TrainingActionTypes = {
    LOAD_TRAININGS_START: "LOAD_TRAININGS_START",
    LOAD_TRAININGS_SUCCESS: "LOAD_TRAININGS_SUCCESS",
    CREATE_TRAINING_START: "CREATE_TRAINING_START",
    CREATE_TRAINING_SUCCESS: "CREATE_TRAINING_SUCCESS",
    UPDATE_TRAINING_START: "UPDATE_TRAINING_START",
    UPDATE_TRAINING_SUCCESS: "UPDATE_TRAINING_SUCCESS",
    DELETE_TRAINING_START: "DELETE_TRAINING_START",
    DELETE_TRAINING_SUCCESS: "DELETE_TRAINING_SUCCESS",
}
