import React from 'react';

import FormInput from '../../components/form-input/form-input.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import ItemsDropDown from '../../components/items-dropdown/items-dropdown.component';

import { SignUpContainer, SignUpTitle } from '../../components/sign-up/sign-up.styles';
import { selectUserUsers } from '../../Redux/user/user.selector';
import { fetchUsersStart, signInAs } from '../../Redux/user/user.actions';
// import uuid from  'react-uuid';

class SignInAs extends React.Component {
    INITIAL_STATE = {
       user: '',
       showDropDown: {user: false},
       userFid: '',
    };

    state = this.INITIAL_STATE;

    componentDidMount(){
        const {fetchUsersStart} = this.props;
        fetchUsersStart();
        window.scroll(0,0);
    }
    
    handleSubmit = async event => {
        event.preventDefault();
        const {signInAs} = this.props
        signInAs(this.state.userFid);
        
    };

    toggleDropDown = (name) => {
        this.setState({
            showDropDown: {...this.state.showDropDown,
                         [name]:!this.state.showDropDown[name]
                        }
        })
    }

    handleChange = event => {
        const { id, value } = event.target;

        this.setState({...this.state, [id]: value });
    };

    clickItem = (item, name, key) => {
        console.log(item);
        console.log(name);
        console.log(key);
        this.setState({
            ...this.state,
            [name]: item[key],
            [`${name}Fid`]: item.id,
            showDropDown: {...this.state.showDropDown,
                [name]:!this.state.showDropDown[name]
               }
        },()=>{console.log(this.state)})
    }

  render() {
    const { user} = this.state;
    const { users } = this.props;
    return (
      <SignUpContainer>
        <SignUpTitle>Sign in as your downlines</SignUpTitle>
                <form className='sign-up-form' onSubmit={this.handleSubmit}>
                <FormInput
                    type='text'
                    name='user'
                    id='user'
                    value={user}
                    onChange={this.handleChange}
                    label='Sign In As User'
                    required
                    readOnly
                    onClick={()=>this.toggleDropDown('user')}
                />
                {this.state.showDropDown['user'] ?
                    <ItemsDropDown
                        onChange = {this.handleChange}
                        value={user}
                        name={'user'}
                        type='text'
                        toggleDropDown = {this.toggleDropDown}
                        items = {users}
                        keyToMap = 'displayName'
                        clickItem = {this.clickItem}
                    /> : null
                }
          <CustomButton type='submit'>SIGN IN AS</CustomButton>
        </form>
      </SignUpContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
    users: selectUserUsers,
   
 })
 
const mapDispatchToProps = dispatch => ({
    signInAs: (userFid)=> dispatch(signInAs(userFid)),
    fetchUsersStart: ()=>dispatch(fetchUsersStart()),
});
 
 export default connect(mapStateToProps, mapDispatchToProps)(SignInAs)
