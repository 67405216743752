import React, { useState, useEffect } from 'react';
import './campaigns-page.styles.scss'
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import { loadCampaigns } from '../../Redux/campaign/campaign.actions';
import { selectCampaignCampaigns } from '../../Redux/campaign/campaign.selectors';
import Campaign from '../../components/campaign/campaign.component';


const CampaignsPage = ({campaigns, loadCampaigns}) => {

    const [campaignsToDisplay, setCampaignsToDisplay] = useState([]);

    useEffect(()=>{
        loadCampaigns();
        // eslint-disable-next-line
    },[])
    
    useEffect(()=>{
        if(campaigns){
            let campaignsMap = campaigns.map(campaign=>{
                let projects = ''
                campaign.projects.forEach(project=>{
                    projects = projects + project.projectName + ' | '
                })
                return {...campaign, projects: projects}
            })
            setCampaignsToDisplay(campaignsMap)
        }
    },[campaigns])

    const fieldToDisplay={
        description: 'Description',
        startDate: 'Start Date',
        endDate: 'End Date',
        conversionDate: 'Conversion Date',
        projects: 'Projects',
        salesValue: 'Sales Value'
    }

    const displayCampaigns = (
        campaignsToDisplay.map((campaign,i)=>(
            <Campaign key={uuid()} i={i} campaign={campaign} fieldToDisplay={fieldToDisplay}/>
        ))
    )

    return(
        <div className='ccpc-table'>
            <div className='ccpc-row-0'>
                <div className='ccpc-item'><h2>Campaigns</h2></div>
            </div>
            <div className='ccpc-row-0'>
                {displayCampaigns}
            </div>
        </div>
    )
}    

const mapStatesToProps = createStructuredSelector({
    campaigns: selectCampaignCampaigns,
});
  
const mapDispatchToProps = dispatch => ({
    loadCampaigns: (startDate, endDate)=> dispatch(loadCampaigns(startDate, endDate)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(CampaignsPage);
