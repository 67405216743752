import React, { useEffect } from "react";
import "./homepage.style.scss";
import { Link } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  selectUserUserAuth,
  selectUserIsSigningIn,
  selectCurrentUser,
} from "../../Redux/user/user.selector";
import { loadAllData } from "../../Redux/functions/reducer-functions";
import { selectSaleIsFetching } from "../../Redux/sale/sale.selectors";
import WithSpinner from "../../components/with-spinner/with-spinner.component";

const Homepage = ({ loadAllData, currentUser, isFetching, isSigningIn }) => {
  useEffect(() => {
    window.scroll(0, 0);
    if (currentUser) {
      loadAllData();
    }
    // eslint-disable-next-line
  }, [currentUser]);

  let LoadElement = "";

  if (currentUser) {
    LoadElement = (
      <div className="homepage">
        <div className="hpc-header">Welcome {currentUser.displayName}</div>
        {!currentUser.chatId ? (
          <div className="hpc-sales">
            <Link to="/telegram-signup">
              <div>Register Telegram</div>
            </Link>
            <div className="hpc-subtitle">
              Register your Telegram Chat Id here for sending report
            </div>
          </div>
        ) : null}
        <div className="hpc-sales">
          <Link to="/trainings-page">
            <div>Trainings <span className="hpc-new">New</span></div>
          </Link>
          <div className="hpc-subtitle">
            View Trainings or Create Trainings
          </div>
        </div>
        <div className="hpc-sales">
          <Link to="/sales-page">
            <div>Sales</div>
          </Link>
          <div className="hpc-subtitle">
            View your sales and team sales here
          </div>
        </div>
        <div className="hpc-sales-page">
          <Link to="/users-sales-page">
            <div>Individual Sales</div>
          </Link>
          <div className="hpc-subtitle">View your individual sales here</div>
        </div>
        <div className="hpc-total">
          <Link to="/users-total-sales-page">
            <div>Total Sales Table</div>
          </Link>
          <div className="hpc-subtitle">
            View your total sales by month here
          </div>
        </div>
        <div className="hpc-total">
          <Link to="sales-project-page">
            <div>Project Sales Table</div>
          </Link>
          <div className="hpc-subtitle">View your sales by project here</div>
        </div>
        <div className="hpc-total">
          <Link to="campaigns-page">
            <div>Campaigns Page</div>
          </Link>
          <div className="hpc-subtitle">View your sales by campaigns here</div>
        </div>
        <div className="hpc-create">
          <Link to="/sale-create-page">
            <div>Create Sale</div>
          </Link>
          <div className="hpc-subtitle">
            Congratulations! Create a Sale Report here
          </div>
        </div>
        <div className="hpc-users">
          <Link to="/users">
            <div>Users</div>
          </Link>
          <div className="hpc-subtitle">View your users' profiles</div>
        </div>
        <div className="hpc-leads">
          <Link to="/leads">
            <div>Leads Table</div>
          </Link>
          <div className="hpc-subtitle">View your leads</div>
        </div>
        <div className="hpc-projects">
          <Link to="/projects-page">
            <div>Projects Table</div>
          </Link>
          <div className="hpc-subtitle">View projects</div>
        </div>
        {currentUser.isAdmin ? (
          <div className="hpc-link">
            <Link to="/project-create-page">
              <div>Create Project</div>
            </Link>
          </div>
        ) : null}
        {currentUser.isAdmin ? (
          <div className="hpc-password">
            <Link to="/sign-in-as">
              <div>Sign In as your downline</div>
            </Link>
          </div>
        ) : null}

        <div className="hpc-password">
          <Link to="/user-change-password">Change Password</Link>
        </div>
      </div>
    );
  }

  if (!currentUser && !isSigningIn && !isFetching) {
    LoadElement = (
      <div className="homepage">
        <div className="hpc-sign-in">
          <div>Dear Existing Eliteone Members</div>
          <div>
            Please <b>Sign Up</b> with <strong>existing Email.</strong>
          </div>
          <div>
            <b>Secret code</b> can be obtained from your respective HOT
          </div>
          <Link to="/signup">
            <h1>Sign Up</h1>
          </Link>
          <div>
            <b>For Existing Members</b>
          </div>
        </div>
        <div className="hpc-sign-in">
          <div>
            I am a brand new Eliteone Member. I have not used the previous App
            before.
          </div>
          <Link to="/sign-up-new-user">
            <h1>Sign Up</h1>
          </Link>
          <div>
            <b>For Brand New Members</b>
          </div>
        </div>
        <div className="hpc-sign-in">
          <div>I have already signed into this new App before.</div>
          <Link to="/signin">
            <h1>Sign In</h1>
          </Link>
        </div>
      </div>
    );
  }

  if (isFetching || isSigningIn) {
    LoadElement = (
      <div className="homepage">
        Loading Data...
        <WithSpinner />
      </div>
    );
  }

  return <div>{LoadElement}</div>;
};

const mapStateToProps = createStructuredSelector({
  userAuth: selectUserUserAuth,
  isSigningIn: selectUserIsSigningIn,
  currentUser: selectCurrentUser,
  isFetching: selectSaleIsFetching,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllData: () => {
    dispatch(loadAllData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
