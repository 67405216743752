import React from 'react';
import './lead-list.styles.scss';
import Lead from '../lead/lead.component';

class LeadList extends React.Component {

    render(){
        const {leads, filteredLeads} = this.props;

        if (!(leads instanceof Promise) && filteredLeads) {
            return(
                <div className='leadlist'>
                    <h1>Leads Table</h1>
                    <div className='leads'>
                        {filteredLeads.map((lead, i) =>(
                            <Lead key={lead.id} lead={lead} i={i} />
                        ))}
                    </div>
                </div>
            )    
        } else {
            return(
                <div>Loading</div>
            )
        }
    }
}

export default LeadList

