import React from "react";
import "./sales-total-chart.styles.scss";

import uuid from "react-uuid";
import {numberWithCommas} from "../../util-functions/numer-formating";

class SalesTotalChart extends React.Component {
	render() {
		let {chartFieldToDisplay, handleClick, sale, n, barChartLengths} = this.props;

		return (
			<React.Fragment>
				{!n && (
					<div className="stcc-row-header">
						{Object.keys(chartFieldToDisplay).map((key, i) => {
							return (
								<div key={uuid()} className={`stcc-header stcc-${key}`}>
									{chartFieldToDisplay[key]}
								</div>
							);
						})}
					</div>
				)}
				<div className={"stcc-row"} onClick={() => handleClick(sale)}>
					{Object.keys(chartFieldToDisplay).map((key, i) => {
						return (
							<div key={`${key}${i}`} className={`stcc-${key}`}>
								{(() => {
									switch (key) {
										case "barChart":
											return (
												<div>
													{Array(barChartLengths[n]).fill().map(() => {
														return <React.Fragment key={uuid()}>&#9608;</React.Fragment>;
													})}
												</div>
											);
										case "totalSales":
											return (
												<React.Fragment key={`${key}${i + 20}`}>
													{numberWithCommas(sale[key])}
												</React.Fragment>
											);
										default:
											return <React.Fragment key={`${key}${i + 20}`}>{sale[key]}</React.Fragment>;
									}
								})()}
							</div>
						);
					})}
				</div>
			</React.Fragment>
		);
	}
}

export default SalesTotalChart;
