import { UserActionTypes } from "./user.types";
import { takeLatest, call, put, all, select } from "redux-saga/effects";
import { firestore, auth } from "../../firebase/firebase.utils";
import {
  convertUsersSnapshotToMap,
  getCurrentUserFromFirebase,
  loadUserProfileDocument,
  createUserProfileInFS,
  updateUplines,
} from "./user.utils";
import {
  fetchUsersSuccess,
  fetchUsersFailure,
  deleteUserSuccess,
  signInSuccess,
  setCurrentUser,
} from "./user.actions";
import { push } from "connected-react-router";
import { forceLoadAllData } from "../functions/reducer-functions";
import { getRen } from "../sale/sale.utils";

const getCurrentUser = (state) => state.user.currentUser;
const getUserUsers = (state) => state.user.users;

export function* fetchUsersStartAsync() {
  try {
    console.log("fetch users async!");
    const collectionRef = firestore.collection("users");
    const snapshot = yield collectionRef.get();
    const usersMap = yield call(convertUsersSnapshotToMap, snapshot);
    yield put(fetchUsersSuccess(usersMap));
  } catch (error) {
    console.log(error);
    yield put(fetchUsersFailure(error.message));
  }
}

export function* deleteUserStartAsync({ payload: user }) {
  try {
    const documentRef = firestore.collection("users").doc(user.id);
    //Delete document from Firestore
    yield documentRef.delete();
    console.log("Deleted user");
    yield put(deleteUserSuccess(user));
  } catch (err) {
    console.log(err.message);
  }
}

export function* fetchUserDownlinesStartAsync() {
  try {
    //get Current User
    const currentUser = yield select(getCurrentUser);
    const users = yield select(getUserUsers);
    if (!currentUser) {
      console.log("No Current User");
      return null;
    }
    //get snapshot from Firestore
    const downlinesSnapshot = yield firestore
      .collection("downlines")
      .where("userFid", "==", currentUser.id)
      .get();
    //get docs from snapshot
    const docs = downlinesSnapshot.docs;
    if (!docs) {
      console.log("No Downlines found! Check you have loaded into Firestore");
      return null;
    }
    //iterate over docs with map to downlines array
    let downlinesArray = docs.map((doc, i) => {
      const downlines = doc.data().downlines.map((downline) => ({
        ...downline,
        userName: getRen(downline.userFid, users).displayName
          ? getRen(downline.userFid, users).displayName
          : downline.userName,
      }));
      return { ...doc.data(), id: doc.id, downlines: downlines };
    });
    yield put({
      type: UserActionTypes.FETCH_USER_DOWNLINES_SUCCESS,
      payload: downlinesArray,
    });
  } catch (err) {
    console.log(err.message);
  }
}

//update and personal sales index
export function* updatePersonalSalesIndex({ payload: sale }) {
  let personalSales = [];
  const currentUser = yield select(getCurrentUser);

  const usersDocs = yield all(
    sale.rens.map((ren) => {
      return firestore.collection("users").doc(ren.userFid).get();
    })
  );
  let usersFromFS = usersDocs.map((userDoc) => ({
    ...userDoc.data(),
    id: userDoc.id,
  }));

  sale.rens.forEach((ren) => {
    if (ren.userFid) {
      let userFromFS = usersFromFS.find((user) => user.id === ren.userFid);
      if (
        userFromFS.personalSalesIndex &&
        userFromFS.personalSalesIndex.length
      ) {
        personalSales[ren.userFid] = [
          ...userFromFS.personalSalesIndex,
          sale.id,
        ];
      } else {
        personalSales[ren.userFid] = [sale.id];
      }
    }
  });

  const collectionRef = firestore.collection("users");

  console.log("Start updating Personal Sales Index.");
  yield all(
    Object.keys(personalSales).map(async (userFid) => {
      let foundUser = usersFromFS.find((user) => user.id === userFid);
      console.log(foundUser.displayName + " personal sale index is updated");
      return await collectionRef
        .doc(userFid)
        .set({
          ...foundUser,
          personalSalesIndex: personalSales[userFid],
        })
        .then(() => {
          console.log("Updated personalSalesIndex into Firestore");
        })
        .catch((err) => {
          console.log(err.message);
        });
    })
  );
  console.log("Completed updating firestore for Personal Sales Index");

  const newCurrentUser = {
    ...currentUser,
    personalSalesIndex: personalSales[currentUser.id],
  };
  //dispatch redux action to the reducer using 'put'
  yield put({
    type: UserActionTypes.UPDATE_PERSONAL_SALES_INDEX_SUCCESS,
    payload: newCurrentUser,
  });
}

export function* getSnapshotFromUserAuth(userAuth) {
  try {
    const userRef = yield call(loadUserProfileDocument, userAuth);
    const userSnapshot = yield userRef.get();
    yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
    yield put(push("/"));
  } catch (error) {
    console.log(error.message);
    alert(error.message);
  }
}

export function* signInWithEmail({ payload: { email, password } }) {
  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user);
  } catch (error) {
    alert(error.message);
    console.log(error.message);
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUserFromFirebase();
    if (!userAuth) {
      yield put(setCurrentUser(null));
    }
  } catch (error) {
    console.log(error.message);
  }
}

export function* signUpNewUserStartAsync({ payload: userInformation }) {
  try {
    const {
      email,
      password,
      confirmPassword,
      secretCode,
      showDropDown,
      ...others
    } = userInformation;
    console.log(userInformation);
    //find if the email has already been in use
    const usersSnapshot = yield firestore
      .collection("users")
      .where("email", "==", email)
      .get();
    if (!usersSnapshot.empty) {
      alert(
        "User email is already in the system, please sign in or sign up using the fisrt option"
      );
      return null;
    }
    //create Firebase Auth User
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    const userProfile = yield createUserProfileInFS(user, others);
    yield updateUplines(userProfile);
    yield put({ type: UserActionTypes.SIGN_UP_NEW_USER_SUCCESS });
    alert("You have successfully signed up.");
    yield getSnapshotFromUserAuth(user);
  } catch (err) {
    console.log(err.message);
    alert(err.message);
  }
}

export function* signUpExistingUserStartAsync({ payload: userData }) {
  try {
    const { email, password } = userData;
    const userRef = firestore.collection("users").where("email", "==", email);
    const userSnapshot = yield userRef.get();
    if (userSnapshot.empty) {
      alert(
        "You have no existing email in the system. Please sign up using the second option."
      );
      return null;
    }
    //create Firebase Auth User
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    yield put({ type: UserActionTypes.SIGN_UP_EXISTING_USER_SUCCESS });
    alert("Sign Up is successful! Welcome back.");
    yield getSnapshotFromUserAuth(user);
  } catch (err) {
    console.log(err.message);
    alert(err.message);
  }
}

export function* signInAs({ payload: userFid }) {
  const users = yield select(getUserUsers);
  const user = users.find((user) => user.id === userFid);
  yield put(signInSuccess(user));
  yield put(forceLoadAllData());
  yield put(push("/"));
}

export function* onFetchUsersStart() {
  yield takeLatest(UserActionTypes.FETCH_USERS_START, fetchUsersStartAsync);
}

export function* onFetchUserDownlinesStart() {
  yield takeLatest(
    UserActionTypes.FETCH_USER_DOWNLINES_START,
    fetchUserDownlinesStartAsync
  );
}

export function* onDeleteUserStart() {
  yield takeLatest(UserActionTypes.DELETE_USER_START, deleteUserStartAsync);
}

export function* onUpdatePersonalSalesIndexStart() {
  yield takeLatest(
    UserActionTypes.UPDATE_PERSONAL_SALES_INDEX_START,
    updatePersonalSalesIndex
  );
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignUpNewUser() {
  yield takeLatest(
    UserActionTypes.SIGN_UP_NEW_USER_START,
    signUpNewUserStartAsync
  );
}

export function* onSignUpExistingUser() {
  yield takeLatest(
    UserActionTypes.SIGN_UP_EXISTING_USER_START,
    signUpExistingUserStartAsync
  );
}

export function* onSignInAs() {
  yield takeLatest(UserActionTypes.SIGN_IN_AS, signInAs);
}

export function* onSetCurrentUser() {
  yield takeLatest(
    UserActionTypes.SET_CURRENT_USER,
    fetchUserDownlinesStartAsync
  );
}

export function* userSagas() {
  yield all([
    call(onFetchUsersStart),
    call(onDeleteUserStart),
    call(onFetchUserDownlinesStart),
    call(onUpdatePersonalSalesIndexStart),
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignUpNewUser),
    call(onSignUpExistingUser),
    call(onSignInAs),
    call(onSetCurrentUser),
  ]);
}
