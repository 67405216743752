import {combineReducers} from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import leadReducer from './lead/lead.reducer';
import saleReducer from './sale/sale.reducer';
import projectReducer from './project/project.reducer';
import parameterReducer from './parameter/parameter.reducer';
import commissionReducer from './commission/commission.reducer';
import photoReducer from './photo/photo.reducer';
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import campaignReducer from './campaign/campaign.reducer';
import trainingReducer from './training/training.reducer';


const persistConfig = {
    key: 'root',
    blacklist: ['sale.isFetching','sale.teamSales', 'sale.flattenedSales'],
    storage,
  };

export const history = createBrowserHistory()
  
const appReducer = combineReducers({
    user: userReducer,
    lead: leadReducer,
    sale: saleReducer,
    project: projectReducer,
    parameter: parameterReducer,
    commission: commissionReducer,
    campaign: campaignReducer,
    photo: photoReducer,
    training: trainingReducer,
    router: connectRouter(history),
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
    localStorage.clear();
  }

  return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducer);