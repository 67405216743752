import React, { useEffect } from 'react';
import '../campaigns-page/campaigns-page.styles.scss'
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import { loadCampaigns, loadCampaignSales } from '../../Redux/campaign/campaign.actions';
import { selectCampaignCampaigns, selectCampaignCampaignTotalSales } from '../../Redux/campaign/campaign.selectors';
import SaleFlattened from '../../components/sale/sale-flattened.component';
import { stringToNumber, numberWithCommas } from '../../util-functions/numer-formating';


const CampaignPage = ({campaigns, campaignTotalSales, loadCampaigns, loadCampaignSales, match}) => {

    useEffect(()=>{
        loadCampaigns();
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        const {id} = match.params;
        loadCampaignSales(id);
        // eslint-disable-next-line
    },[campaigns])

    const fieldToDisplay = {
        renName: 'REN',
        Booked: 'Booked',
        Converted: 'Converted'
    }

    const campaign = campaigns.find(doc => doc.id === match.params.id)
    const {description, startDate, endDate, conversionDate, salesValue} = campaign
    
    let totalSalesBooked = 0
    let totalSalesConverted = 0
    if(campaignTotalSales){
        totalSalesBooked = campaignTotalSales.reduce((acc, sale)=>{
            return acc + stringToNumber(sale.Booked)
        },0)

        totalSalesConverted = campaignTotalSales.reduce((acc, sale)=>{
            return acc + stringToNumber(sale.Converted)
        },0)
    }

    campaignTotalSales = campaignTotalSales.filter(sale=>sale.renName !== 'Hayley')
    const salesToDisplay =(
        campaignTotalSales.map((sale, i)=>(
            <React.Fragment key={uuid()}><SaleFlattened fieldToDisplay={fieldToDisplay} sale={sale} i={i} /></React.Fragment>
        ))
    )

    return(
        <div className='ccpc-table'>
            <div className='ccpc-row-0'>
                <div className='ccpc-item'><h3>{description} | {startDate} | {endDate} | {conversionDate} | RM{salesValue}</h3></div>
            </div>
            <div className='ccpc-row-0'>
                <div className='ccpc-item'>Sales: RM{numberWithCommas(totalSalesBooked)} | Converted: RM{numberWithCommas(totalSalesConverted)} </div>
            </div>
            <div className='ccpc-row-0'>
                {salesToDisplay}
            </div>
        </div>
    )
}    

const mapStatesToProps = createStructuredSelector({
    campaigns: selectCampaignCampaigns,
    campaignTotalSales: selectCampaignCampaignTotalSales,
});
  
const mapDispatchToProps = dispatch => ({
    loadCampaigns: (startDate, endDate)=> dispatch(loadCampaigns(startDate, endDate)),
    loadCampaignSales: (campaign) => dispatch(loadCampaignSales(campaign))
});

export default connect(mapStatesToProps, mapDispatchToProps)(CampaignPage);
