import { createSelector } from 'reselect';

const selectParameter = state => state.parameter;

export const selectParameterNumberOfSales = createSelector(
  [selectParameter],
  parameter => parameter.numberOfSales
);

export const selectParameterNumberOfProjects = createSelector(
  [selectParameter],
  parameter => parameter.numberOfProjects
)

export const selectParameterNumberOfCommStructures = createSelector(
  [selectParameter],
  parameter => parameter.numberOfCommStructures
)