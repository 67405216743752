import React from 'react';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import {addCollectionAndDocuments, getUplinesFid, getUplinesArray, findDownlinesArray, listUsers, listDownlines} from './seeding.utils';
import CustomButton from '../components/custom-button/custom-button.component';
import {connect} from 'react-redux';
import { selectUserUsers } from '../Redux/user/user.selector';
import { createStructuredSelector } from 'reselect';
import {updateUplines} from '../Redux/user/user.utils';
import { Link } from 'react-router-dom';

class SeedUsers extends React.Component {
    constructor(){
        super()

        this.state = {
            cols: [],
            rows: [],
            obj: [],
        }
    }

    render(){
        const {users} = this.props;

        const getUplineFid = (immediateUpline) => {
          const foundImmediateUpline = users.find(user => user.displayName === immediateUpline);
          if(!foundImmediateUpline){
            console.log(immediateUpline + ' Fid is not in database!');
            return '';
          }
          return foundImmediateUpline.id;
        }

        const obj = [];
        const fileHandler = (event) => {
            let fileObj = event.target.files[0];
        
            //just pass the fileObj as parameter
            ExcelRenderer(fileObj, (err, resp) => {
              if(err){
                console.log(err);            
              }
              else{
                this.setState({
                  cols: resp.cols,
                  rows: resp.rows
                },()=>{
                    this.state.rows.forEach((row,i)=>{
                        if(i){
                           const newObj = {
                               userId: row[0],
                               fullName: row[1],
                               displayName: row[2],
                               position: [{positionName: row[3], date: row[4]}],
                               icNumber: row[5] ? row[5] : '',
                               email: row[6],
                               phoneNumber: row[7],
                               birthday: row[8],
                               immediateUpline: row[9] ? row[9] : '',
                               immediateUplineFid: getUplineFid(row[9]),
                               referrer: row[10],
                               location: row[11],
                               createdAt: row[12],
                               updatedAt: row[13],
                           }
                           
                           obj.push(newObj);
                        }
                    })
                    this.setState({...this.state, obj: obj})
                    console.log(obj);
                });
              }
            });               
          }

        const handleClick = ()=>{
            
            console.log("CLICKED!")
            console.log(this.state.obj);
            let objectsArray = [];
            let x = Math.floor(this.state.obj.length / 500);
            for(let n = 0; n < x + 1; n++){
              let object = [];
              for(let i = n*500; i < (n+1)*500; i++){
                if(this.state.obj[i]){
                  console.log('I am ' + i + ' in group' + n)
                  object.push(this.state.obj[i])
                }
              }
              objectsArray.push(object)
            }
            console.log(objectsArray);

            objectsArray.forEach((object, i)=>{
              addCollectionAndDocuments('users', object, 'email')
            })
        }

        return(
            <div>
            <h1>Seed Users</h1>
                <input type="file" onChange={fileHandler.bind(this)} style={{"padding":"10px"}} />
                <div>
                  <CustomButton onClick={handleClick}>Upload to Firestore</CustomButton>
                  <p></p>
                  <CustomButton onClick={()=>{getUplinesFid(users)}}>Fill Upline Fid</CustomButton>
                  <p></p>
                  <CustomButton onClick={()=>{getUplinesArray(users)}}>Fill in uplines array</CustomButton>
                  <p></p>
                  <CustomButton onClick={()=>{listUsers()}}>List Users</CustomButton>
                  <p></p>
                  <CustomButton onClick={()=>{listDownlines()}}>List Downlines</CustomButton>
                  <p></p>
                  <CustomButton onClick={()=>{updateUplines(users[0])}}>Update Uplines' downlines</CustomButton>
                  <p></p>
                  <Link to={{ pathname: "https://leads.djcsystem.com" }} target="blank"  >Link</Link>
                  <p></p>
                  <CustomButton onClick={()=>{findDownlinesArray()}}>Fill in downlines array</CustomButton>
                  <p></p>
                  <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                </div>
            </div>
           
        )
    }
}
const mapStateToProps = createStructuredSelector({
    users: selectUserUsers,
  });
  
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(SeedUsers) 