import React from "react";
import "./user.styles.scss";
import { connect } from "react-redux";
import { deleteUserStart } from "../../Redux/user/user.actions";
import { Link } from "react-router-dom";
import { selectCurrentUser } from "../../Redux/user/user.selector";
import { createStructuredSelector } from "reselect";

const User = ({ user, deleteUserStart, currentUser, fieldToDisplay, i }) => {
  const { displayName } = user;

  const handleDelete = (user) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      deleteUserStart(user);
    }
  };

  return (
    <React.Fragment>
      {!i && (
        <div className="uc-row-header">
          {Object.keys(fieldToDisplay).map((key, j) => {
            return (
              <div
                key={`${key}${i}`}
                className={`uc-${key} uc-item  uc-item-header`}
              >
                {(() => {
                  switch (key) {
                    case "delete":
                      return <div>{currentUser.isAdmin ? "Delete" : null}</div>;
                    default:
                      return (
                        <React.Fragment>{fieldToDisplay[key]}</React.Fragment>
                      );
                  }
                })()}
              </div>
            );
          })}
        </div>
      )}
      <div className="uc-row">
        {Object.keys(fieldToDisplay).map((key, j) => {
          return (
            <div key={`${key}1${i}`} className={`uc-${key} uc-item`}>
              {(() => {
                switch (key) {
                  case "displayName":
                    return <Link to={`/user/${user.id}`}>{displayName}</Link>;
                  case "delete":
                    return (
                      <div onClick={() => handleDelete(user)}>
                        {" "}
                        {currentUser.isAdmin ? <div>&#10006;</div> : null}
                      </div>
                    );
                  default:
                    return <React.Fragment>{user[key]}</React.Fragment>;
                }
              })()}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  deleteUserStart: (user) => dispatch(deleteUserStart(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
