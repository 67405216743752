import React from "react";
import "./sale-create-page.scss";
import {selectUserUsers, selectUserIsLoaded} from "../../Redux/user/user.selector";
import {selectProjectProjects} from "../../Redux/project/project.selector";
import {createStructuredSelector} from "reselect";
import {connect} from "react-redux";
import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import ItemsDropDown from "../../components/items-dropdown/items-dropdown.component";
import uuid from "react-uuid";
import {dateFormat} from "../../util-functions/date-format";
import {fetchUsersStart} from "../../Redux/user/user.actions";
import {checkIsLoaded} from "../../util-functions/checkIsLoaded";
import {addNewSale, updateSale, deleteSale} from "../../Redux/sale/sale.actions";
import {fetchParametersStartAsync} from "../../Redux/parameter/parameter.actions";
import {selectSaleSales} from "../../Redux/sale/sale.selectors";
import {getRen, getProject} from "../../Redux/sale/sale.utils";
import {fetchProjectsStartAsync} from "../../Redux/project/project.actions";
import NumberFormat from "react-number-format";
import atlas from "../../assets/atlas.jpg";

class SaleCreatePage extends React.Component {
	constructor() {
		super();

		this.state = {
			date: dateFormat(new Date()),
			spaPrice: "",
			netPrice: "",
			buyerName: "",
			package: "",
			remark: "",
			projectName: "",
			projectFid: "",
			unitNumber: "",
			unitSize: "",
			buyerSource: "",
			manualCommission: "",
			status: "",
			showDropDown: {project: false, ren1: false},
			numberOfRens: 1,
			rens: [{userId: "", userFid: "", renName: "", renPercentage: ""}],
		};
	}

	componentDidMount() {
		const {
			fetchUsersStart,
			fetchProjectsStartAsync,
			isLoaded,
			fetchParametersStartAsync,
			match,
			sales,
			users,
			projects,
		} = this.props;
		const paramsId = match.params.id;
		const sale = sales.find((sale) => sale.id === paramsId);
		window.scroll(0, 0);

		if (!checkIsLoaded(isLoaded)) {
			fetchUsersStart();
		}
		fetchParametersStartAsync();
		fetchProjectsStartAsync();

		//populate state if this is for updating
		if (paramsId) {
			const {
				id,
				date,
				spaPrice,
				netPrice,
				buyerName,
				remark,
				rens,
				projectFid,
				unitNumber,
				unitSize,
				buyerSource,
				status,
				manualCommission,
			} = sale;
			const projectName = getProject(projectFid, projects).projectName;

			let rensArray = [...rens];

			rens.forEach((ren, i) => {
				rensArray[i]["renName"] = getRen(ren.userFid, users).displayName
					? getRen(ren.userFid, users).displayName
					: rensArray[i]["renName"];
			});

			this.setState(
				{
					id: id,
					saleId: sale.saleId,
					date: dateFormat(date),
					spaPrice: spaPrice,
					netPrice: netPrice,
					buyerName: buyerName,
					package: sale.package ? sale.package : "",
					remark: remark ? remark : "",
					unitNumber: unitNumber,
					unitSize: unitSize,
					buyerSource: buyerSource,
					status: status,
					rens: rens,
					manualCommission: manualCommission ? manualCommission : "",
					projectFid: projectFid,
					projectName: projectName,
					numberOfRens: rens.length,
				},
				() => console.log(this.state)
			);
		}
	}

	handleChange = (event) => {
		const {id, value} = event.target;
		this.setState({
			[id]: value,
		});
	};

	handleSubmit = (event) => {
		const {addNewSale, match, updateSale, sales, history} = this.props;
		event.preventDefault();
		console.log(this.state);

		const {
			date,
			projectFid,
			unitNumber,
			spaPrice,
			netPrice,
			buyerName,
			remark,
			rens,
			unitSize,
			buyerSource,
			status,
			projectName,
			manualCommission,
		} = this.state;

		let newSale = {
			date: new Date(date),
			projectName: projectName,
			projectFid: projectFid,
			unitNumber: unitNumber,
			unitSize: unitSize,
			buyerSource: buyerSource,
			status: "Booked",
			manualCommission: manualCommission,
			spaPrice: spaPrice,
			netPrice: netPrice,
			buyerName: buyerName,
			package: this.state.package,
			remark: remark,
			rens: rens,
		};

		const paramsId = match.params.id;
		console.log(newSale);

		if (!paramsId) {
			addNewSale(newSale);
		} else {
			const sale = sales.find((sale) => sale.id === paramsId);
			updateSale({
				...newSale,
				saleId: sale.saleId,
				id: match.params.id,
				status: status,
			});
		}

		this.setState({
			date: dateFormat(new Date()),
			spaPrice: "",
			netPrice: "",
			buyerName: "",
			buyerSource: "",
			unitSize: "",
			status: "",
			package: "",
			remark: "",
			manualCommission: "",
			projectName: "",
			projectFid: "",
			unitNumber: "",
			showDropDown: {project: false, ren1: false},
			numberOfRens: 1,
			rens: [{id: "", renName: "", renPercentage: ""}],
		});

		// window.confirm('Sale Submitted Successfully')
		if (!paramsId) {
			history.push("/sale-report");
		} else {
			history.push(`/sale-report/${paramsId}`);
		}
	};

	toggleDropDown = (name) => {
		this.setState({
			showDropDown: {
				...this.state.showDropDown,
				[name]: !this.state.showDropDown[name],
			},
		});
	};

	clickProjectItem = (item, name, key) => {
		this.setState({
			...this.state,
			[name]: item[key],
			projectFid: item.id,
			showDropDown: {
				...this.state.showDropDown,
				[name]: !this.state.showDropDown[name],
			},
		});
	};

	clickUserItem = (item, name, key) => {
		const {rens} = this.state;
		const i = parseInt(name.substring(3, 4));
		let newRens = [...rens]; //copy state rens
		newRens[i - 1] = {
			...newRens[i - 1],
			userFid: item.id,
			renName: item[key],
		};
		this.setState({
			...this.state,
			rens: [...newRens],
			showDropDown: {
				...this.state.showDropDown,
				[name]: !this.state.showDropDown[name],
			},
		});
	};

	handleAddRen = () => {
		const newRens = [...this.state.rens, {renName: "", renPercentage: ""}];
		const newShowDropDown = {
			...this.state.showDropDown,
			[`ren${this.state.numberOfRens + 1}`]: "",
		};
		this.setState({
			...this.state,
			numberOfRens: this.state.numberOfRens + 1,
			rens: newRens,
			showDropDown: newShowDropDown,
		});
	};

	handleAddRenFromOtherTeam = () => {
		let enteredName = prompt("Please enter REN name from other team");
		console.log(enteredName);
		if (!enteredName) {
			enteredName = "";
		} else {
			const newRens = [...this.state.rens];
			newRens[this.state.numberOfRens - 1] = {
				...newRens[this.state.numberOfRens - 1],
				userId: "0",
				userFid: "0",
				renName: enteredName,
			};
			this.setState({...this.state, rens: newRens}, console.log(this.state));
		}
	};

	handleRemoveRen = () => {
		let newRens = [...this.state.rens];
		newRens.pop();
		if (this.state.numberOfRens !== 1) {
			this.setState({
				...this.state,
				numberOfRens: this.state.numberOfRens - 1,
				rens: newRens,
			});
		}
	};

	handleRenPercChange = (event) => {
		const {id, value} = event.target;
		const i = parseInt(id.substring(3, 4));
		let newRens = [...this.state.rens];
		newRens[i - 1] = {...newRens[i - 1], renPercentage: value};
		this.setState({
			...this.state,
			rens: [...newRens],
		});
	};

	handleDelete = () => {
		const {match, deleteSale, history} = this.props;
		const paramsId = match.params.id;

		if (window.confirm("Are you sure you want to delete?")) {
			deleteSale(paramsId);
			history.goBack(); //go back to the previous page
		}
	};

	render() {
		const projects = this.props.projects;
		const users = this.props.users;
		const {rens} = this.state;
		const {match} = this.props;
		const paramsId = match.params.id;

		return paramsId ? (
			<div className="scp-table">
				<div className="scp-row">{paramsId ? <h1>Update Sale</h1> : <h1>Create Sale</h1>}</div>
				<div className="scp-row">
					<div className="scp-form">
						<form onSubmit={this.handleSubmit}>
							<div>
								<FormInput
									className="scp-date"
									type="date"
									name="date"
									id="date"
									value={this.state.date}
									onChange={this.handleChange}
									label="Date"
									required
								/>
							</div>
							{/*Form Input for Project*/}
							<FormInput
								className="scp-project"
								type="text"
								name={uuid()}
								id="projectName"
								value={this.state.projectName}
								onClick={() => this.toggleDropDown("projectName")}
								label="Project"
								required
							/>
							{this.state.showDropDown["projectName"] ? (
								<ItemsDropDown
									onChange={this.handleChange}
									value={this.state.projectName}
									name="projectName"
									type="text"
									toggleDropDown={this.toggleDropDown}
									items={projects} //Items to pass in
									keyToMap="projectName" //List out the values corresposing to the key
									clickItem={this.clickProjectItem} //list state up function
								/>
							) : null}
							<FormInput
								type="text"
								name={uuid()}
								id="unitNumber"
								value={this.state.unitNumber}
								onChange={this.handleChange}
								label="Unit Number"
								required
							/>
							{/* REN FORM */}
							{rens.map((ren, i) => {
								return (
									<div key={`ren${i + 1}`} className="scp-ren-row">
										<div className="scp-ren-item">
											<FormInput
												type="text"
												name={uuid()}
												id={`ren${i + 1}`}
												value={this.state.rens[i].renName}
												onChange={this.handleChange}
												onClick={() => this.toggleDropDown(`ren${i + 1}`)}
												label={`REN${i + 1}`}
												required
											/>
											{this.state.showDropDown[`ren${i + 1}`] ? (
												<ItemsDropDown
													onChange={this.handleChange}
													value={this.state.project}
													name={`ren${i + 1}`}
													type="text"
													toggleDropDown={this.toggleDropDown}
													items={users}
													keyToMap="displayName"
													clickItem={this.clickUserItem}
												/>
											) : null}
										</div>
										{/* REN PERCENTAGE FORM */}
										<div className="scp-ren-percentage">
											<FormInput
												type="number"
												name={uuid()}
												id={`ren${i + 1}Percentage`}
												value={this.state.rens[i].renPercentage}
												onChange={this.handleRenPercChange}
												label="REN %"
												min="1"
												max="100"
												step="0.01"
												required
											/>
										</div>
									</div>
								);
							})}{" "}
							{/* END OF MAPPING RENS */}
							<div className="scp-ren-row-button">
								<div className="scp-ren-button" onClick={this.handleAddRenFromOtherTeam}>
									ADD REN from other team
								</div>
								<div className="scp-ren-button" onClick={this.handleAddRen}>
									ADD
								</div>
								<div className="scp-ren-button" onClick={this.handleRemoveRen}>
									REMOVE
								</div>
							</div>
							{/* END OF REN FORM */}
							<NumberFormat
								customInput={FormInput}
								type="text"
								name={uuid()}
								id="spaPrice"
								value={this.state.spaPrice}
								onValueChange={(values) => {
									this.setState({...this.state, spaPrice: values.value});
								}}
								label="SPA Price"
								required
								thousandSeparator={true}
							/>
							<NumberFormat
								customInput={FormInput}
								type="text"
								name={uuid()}
								id="netPrice"
								value={this.state.netPrice}
								onValueChange={(values) => {
									this.setState({...this.state, netPrice: values.value});
								}}
								label="Net Price"
								required
								thousandSeparator={true}
							/>
							<FormInput
								type="number"
								name={uuid()}
								id="unitSize"
								value={this.state.unitSize}
								onChange={this.handleChange}
								label="Unit Size (sf)"
								step="0.01"
								required
							/>
							<FormInput
								type="text"
								name={uuid()}
								id="buyerName"
								value={this.state.buyerName}
								onChange={this.handleChange}
								label="Buyer's Name"
								required
							/>
							<FormInput
								type="text"
								name={uuid()}
								id="buyerSource"
								value={this.state.buyerSource}
								onChange={this.handleChange}
								label="Buyer's Source"
								required
							/>
							<FormInput
								type="text"
								name={uuid()}
								id="package"
								value={this.state.package}
								onChange={this.handleChange}
								label="Package"
								required
							/>
							<FormInput
								type="text"
								name={uuid()}
								id="remark"
								value={this.state.remark}
								onChange={this.handleChange}
								label="Remark"
							/>
							<NumberFormat
								customInput={FormInput}
								type="text"
								name="manualCommission"
								id="manualCommission"
								value={this.state.manualCommission}
								onValueChange={(values) => {
									this.setState({...this.state, manualCommission: values.value});
								}}
								label="Manual Total Commission"
								thousandSeparator={true}
							/>
							<div style={{fontSize: "12px", marginBottom: "20px"}}>
								(Leave Manual Commission blank if there is no adjustment)
							</div>
							<CustomButton type="submit">{!paramsId ? "Create Sale" : "Update Sale"}</CustomButton>
						</form>
						<div className="scp-row">
							{paramsId ? (
								<div className="scp-delete" onClick={this.handleDelete}>
									Delete
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		) : (
			<div>
				<h4 style={{margin: "15px"}}>Eliteone Sales System --- Atlas</h4>
				<div style={{margin: "15px"}}>
					We are pleased to announce that Eliteone Sales System has been connected to Atlas.{" "}
				</div>
				<div style={{margin: "15px"}}>
					Sales from Atlas will be automatically imported into Eliteone Sales System Database.
				</div>
				<div style={{margin: "15px"}}>You can enter your sales at:</div>{" "}
				<div style={{margin: "15px"}}>
					<a
						href="https://atlas.iqiglobal.com/#/dashboard/personal/report-new-sales"
						target="blank"
						style={{textDecoration: "underline"}}
					>
						Atlas Link
					</a>
				</div>
				<div style={{margin: "15px", fontSize: "12px"}}>
					If you do not see your sales in the system after you have entered into Atlas, please contact Dave @
					0126098229
				</div>
				<div>
					<img src={atlas} style={{width: "380px"}} />
				</div>
        <div style={{margin: "15px", fontSize: "16px"}}>Dave Chong</div>
			</div>
		);
	}
}

const mapStateToProps = createStructuredSelector({
	users: selectUserUsers,
	projects: selectProjectProjects,
	isLoaded: selectUserIsLoaded,
	sales: selectSaleSales,
});

const mapDispatchToProps = (dispatch) => ({
	fetchUsersStart: () => {
		dispatch(fetchUsersStart());
	},
	fetchParametersStartAsync: () => {
		dispatch(fetchParametersStartAsync());
	},
	fetchProjectsStartAsync: () => {
		dispatch(fetchProjectsStartAsync());
	},
	addNewSale: (sale) => {
		dispatch(addNewSale(sale));
	},
	updateSale: (sale, additionalOptions) => {
		dispatch(updateSale(sale, additionalOptions));
	},
	deleteSale: (saleFid) => {
		dispatch(deleteSale(saleFid));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(SaleCreatePage);
