import React from 'react';
import './lead-actions.styles.scss';
import { selectLeadLeads, selectLeadCurrentLead, selectLeadActionsLogs } from '../../Redux/lead/lead.selector';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../Redux/user/user.selector';
import { setCurrentLead, toggleEditFormShow, deleteLead } from '../../Redux/lead/lead.actions';
import uuid from 'react-uuid';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import {updateLeadAction} from '../../Redux/lead/lead.actions';
import { fetchLeadActionsLogs } from '../../Redux/lead/lead.actions';
import {dateFormat} from '../../util-functions/date-format';
import {selectLeadEditFormShow} from '../../Redux/lead/lead.selector';
import LeadEditForm from '../lead-edit-form/lead-edit-form.component';

class LeadAction extends React.Component{
    constructor(){
        super();

        this.state={
            action: '',
            key: '',
            lead: ''
        }
    }

    componentDidMount(){
        const {leads, setCurrentLead, match, fetchLeadActionsLogs} = this.props;
        const {id} = match.params;
        const lead = leads.find(lead => lead.id === id);
        setCurrentLead(lead);
        fetchLeadActionsLogs(id);
    }

    handleSubmit = (event)=>{
        const {currentLead, updateLeadAction} = this.props;
        event.preventDefault();
        const leadAction = {
                leadId: currentLead.id,
                date: dateFormat(new Date()),
                remark: this.state.action
            }
        console.log(leadAction);
        updateLeadAction(leadAction);
        this.setState({action:''});
        
    }

    handleChange = (event)=>{
        const {id, value} = event.target
        this.setState({...this.state, [id]: value });
    }

    handleClick = (event)=>{
        const key = event.currentTarget.getAttribute('data-index');
        const {currentLead} = this.props;
        const {toggleEditFormShow} = this.props;
        toggleEditFormShow(currentLead);
        this.setState({
            key: key
        })
    }

    handleDelete = () => {
        const {currentLead, history, deleteLead} = this.props;
        const confirmDelete = window.confirm('Are you sure you want to delete?');
        if(confirmDelete){
            deleteLead(currentLead);
            history.push('/leads');
        }
    }
    
    render(){
        const {match, leads, leadActionsLogs, editFormShow} = this.props;
        const {id} = match.params;
        const lead = leads.find(lead => lead.id === id);

        const fieldToDisplay = {
            date: '',
            eventTitle: '',
            name: '',
            phone: '',
            email: '',
            affiliateId: '',
            remark: ''
        }

        const toDisplay = (key) => {
            return (key === this.state.key && editFormShow)
        }

        const divCursor = {
            cursor: 'pointer'
        }
           
        if(lead){
        return(
            <div className='lac-table'>
                <div className='lac-row-header'>
                {Object.keys(fieldToDisplay).map((key,i)=>{
                    return(
                        <div key={uuid()} className={`lac-${key}`}>{key}</div>
                    )
                })}
                </div>
                <div className='lac-row'>
                {Object.keys(fieldToDisplay).map((key,i)=>{
                    return(
                        <div key={uuid()}>
                        <div key={uuid()} className={`lac-${key}`} data-index={key} style={divCursor} onClick={this.handleClick}>{lead[key] ? lead[key] : <div>{key}</div>}</div>
                        {toDisplay(key) ? <LeadEditForm lead={lead} para={key} /> : null}
                        </div>
                    )
                })}
                        <div className='lac-delete' onClick={this.handleDelete}>Delete</div>
                </div>
                <div className='lac-row-header'>
                    <div className='lac-action-label'>No</div>
                    <div className='lac-action-date'>Date</div>
                    <div className='lac-action'>Action</div>
                </div> 
                {leadActionsLogs.map((action,i)=>{
            return(
                <div className='lac-row' key={uuid()}>
                    <div className='lac-action-label'>{i+1}</div>
                    <div className='lac-action-date'>{action.date.toString()}</div>
                    <div className='lac-action'>{action.remark}</div>
                </div>  
                )
                })}
                <div className='lac-row'>
                    <form onSubmit={this.handleSubmit}>
                        <div className='lac-form'>
                        <FormInput 
                            handleChange={this.handleChange} 
                            name={uuid()}
                            id='action'
                            type='text'
                            value={this.state.action}
                            label='Put a remark'
                        />
                        </div>
                        <div className='lac-button'><CustomButton>Add</CustomButton></div>
                    </form>
                </div>
            </div>
        )
        
        }else{return <div className='lac-table'><div className='lac-name'>No Lead</div></div>}     
    }}

const mapStateToProps = createStructuredSelector({
    leads: selectLeadLeads,
    currentUser: selectCurrentUser,
    currentLead: selectLeadCurrentLead,
    leadActionsLogs: selectLeadActionsLogs,
    editFormShow: selectLeadEditFormShow
})

const mapDispatchToProps = dispatch => ({
    setCurrentLead: lead => dispatch(setCurrentLead(lead)),
    updateLeadAction: leadAction => dispatch(updateLeadAction(leadAction)),
    fetchLeadActionsLogs: (leadId) => dispatch(fetchLeadActionsLogs(leadId)),
    toggleEditFormShow: (lead) => dispatch(toggleEditFormShow(lead)),
    deleteLead: (lead) => dispatch(deleteLead(lead))
  });

export default connect(mapStateToProps, mapDispatchToProps)(LeadAction)


