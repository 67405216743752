
import React, {useEffect} from "react";
import { createStructuredSelector } from "reselect";
import { connect } from 'react-redux';
import {loadPhotos} from '../../Redux/photo/photo.actions';
import "./list-photos.styles.scss";
import { selectPhotoPhotos } from "../../Redux/photo/photo.selectors.js";

const ListPhotos = ({imageTag, loadPhotos, photos}) => {

  useEffect(()=>{
    console.log(imageTag);
    loadPhotos(imageTag);
     // eslint-disable-next-line
  }, [imageTag])

  return (
            <div className="row justify-content-center">
              {photos && photos.map(imageURL =>{
                return(
                <div className="col-md-4 remove-padding" key={imageURL}>
                    <a href={imageURL}><img alt={'Names'} className="my-image" src={imageURL} width="300px"></img></a>
                </div>
                )
              })}
            </div>   
       
    )
}

const mapStatesToProps = createStructuredSelector({
    photos: selectPhotoPhotos,
});
  
const mapDispatchToProps = dispatch => ({
  loadPhotos: (imageTag)=>dispatch(loadPhotos(imageTag)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ListPhotos);
