import {CampaignActionTypes} from './campaign.types';

export const createCampaign = (campaign) => ({
        type: CampaignActionTypes.CREATE_CAMPAIGN,
        payload: campaign
})

export const loadCampaigns = (startDate, endDate) => ({
        type: CampaignActionTypes.LOAD_CAMPAIGNS,
        payload: {startDate, endDate}
})

export const loadCampaignSales = (campaignFid) => ({
        type: CampaignActionTypes.LOAD_CAMPAIGN_SALES,
        payload: campaignFid
})