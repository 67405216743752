import React from 'react';
import './users-sales-list.styles.scss';
import uuid from 'react-uuid';

import SaleTeamSale from '../sale-teamsale/sale-teamsale.component';
import { withRouter } from "react-router-dom";

class UsersSalesList extends React.Component {
    
    handleClick = (sale) => {
        const {history} = this.props;
        history.push(`/sale-create-page/${sale.id}`)

    }

    render(){

        let fieldToDisplay = {
            saleId: 'Id',
            date: 'Date',
            projectName: 'Project',
            unitNumber: 'Unit No.',
            netPrice: 'Net Price',
            buyerName: 'Buyer',
            renName: 'REN Name',
            renPercentage: 'REN %',
            commForRen: 'Comm',
            status: 'Status',
        };
        
        let { sales } = this.props;
        sales.forEach((sale, i)=>{
            sales[i]['order'] = 0
        })

            return(
                <React.Fragment>
                {sales.map((sale,i)=>{
                    return(
                        <React.Fragment key={`sale${i}`}>
                            <SaleTeamSale key={uuid()} sale={sale} i={i} isFirstLine={true} fieldToDisplay={fieldToDisplay} handleClick={this.handleClick}/>
                        </React.Fragment>
                    )
                })}
                </React.Fragment>
            )   
    }
}

export default withRouter(UsersSalesList);

