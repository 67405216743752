export const UserActionTypes = {
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    SET_USER_AUTH: 'SET_USER_AUTH',
    ADD_USER: 'ADD_USER',
    UPDATE_USER: 'UPDATE_USER',
    DELETE_USER_START: 'DELETE_USER_START',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    FETCH_USERS_START: 'FETCH_USERS_START',
    FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
    FETCH_USERS_FAILURE: 'FETCH_USERS_FAILURE',
    TOGGLE_EDIT_FORM_SHOW: 'TOGGLE_EDIT_FORM_SHOW',
    TOGGLE_IS_LOADED: 'TOGGLE_IS_LOADED',
    EMAIL_SIGN_IN_START: 'EMAIL_SIGN_IN_START',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_AS: 'SIGN_IN_AS',
    SIGN_UP_NEW_USER_START: 'SIGN_UP_NEW_USER_START',
    SIGN_UP_NEW_USER_SUCCESS: 'SIGN_UP_NEW_USER_SUCCESS',
    SIGN_UP_EXISTING_USER_START: 'SIGN_UP_EXISTING_USER_START',
    SIGN_UP_EXISTING_USER_SUCCESS: 'SIGN_UP_EXISTING_USER_SUCCESS',
    CHECK_USER_SESSION: 'CHECK_USER_SESSION',
    FETCH_USER_DOWNLINES_START: 'FETCH_USER_DOWNLINES_START',
    FETCH_USER_DOWNLINES_SUCCESS: 'FETCH_USER_DOWNLINES_SUCCESS',
    USER_SIGN_OUT: 'USER_SIGN_OUT',
    UPDATE_PERSONAL_SALES_INDEX_START: 'UPDATE_PERSONAL_SALES_INDEX_START',
    UPDATE_PERSONAL_SALES_INDEX_SUCCESS: 'UPDATE_PERSONAL_SALES_INDEX_SUCCESS',
};