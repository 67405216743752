import React, { useEffect } from 'react';
import { GlobalStyle } from './global.styles';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Homepage from './pages/homepage/homepage.component'
import LeadsPage from './pages/leads-page/leads-page.component';
import UsersPage from './pages/users-page/users-page.component';
import SignUp from './components/sign-up/sign-up.component';
import SignIn from './components/sign-in/sign-in.component';
import Header from './components/header/header.component';
import UserEditPage from './pages/user-page/user-page.component';

import { Switch, Route } from 'react-router-dom';

import { setCurrentUser, checkUserSession } from './Redux/user/user.actions';
import { selectCurrentUser } from './Redux/user/user.selector';
import SeedLeads from './seed/seeding-leads.component';
import SeedUsers from './seed/seeding-users.component';
import SeedProjects from './seed/seeding-projects.component';
import SeedSales from './seed/seeding-sales.components';

import LeadAction from './components/lead-actions/lead-actions.component';
import UserChangePassword from './pages/user-change-password/user-change-password.component';
import SaleCreatePage from './pages/sale-create-page/sale-create-page.component';
import SalesPage from './pages/sales-page/sales-page.component';
import ProjectsSalesPage from './pages/sales-project-page/sales-project-page-component';
import ProjectCreatePage from './pages/project-create-page/project-create-page.component';
import ProjectsPage from './pages/projects-page/projects-page.component';
import CommStructure from './components/comm-structure/comm-structure.component';
import usersSalesPage from './pages/users-sales-page/users-sales-page.component';
import UsersTotalSales from './pages/users-total-sales-page/users-total-sales-page.component';
import SaleReport from './pages/sale-report/sale-report.component';
import SignUpNewUser from './components/sign-up-new-user/sign-up-new-user.component';
import SignInAs from './pages/sign-in-as-page.component.jsx/sign-in-as-page.component';
import SeedAffiliatesId from './seed/seeding-affiliates-id.component';
import CampaignCreatePage from './pages/campaign-create-page/campaign-create-page.component';
import CampaignsPage from './pages/campaigns-page/campaigns-page.component';
import CampaignPage from './pages/campaign-page/campaign-page.component';
import UploadPhotos from './components/file-upload/file-upload.component';
import userDownlinesPage from './pages/user-downlines-page/user-downlines-page.component';
import TelegramSignUp from './pages/telegram-sign-up/telegram-sign-up.component';
import TrainingsPage from './pages/trainings-page/trainings-page.component';

const App = () => {
  useEffect(() => {
   
  }, []);

  return (
      <div className='main-app'>
        <GlobalStyle />
        <Header />
        <Switch>
          <Route exact path='/' component={Homepage} />
          <Route path='/leads' component={LeadsPage} />
          <Route path='/users' component={UsersPage} />  
          <Route exact path='/signup' component={SignUp} /> 
          <Route exact path='/signin' component={SignIn} /> 
          <Route path='/user/:id' component={UserEditPage} />
          <Route exact path='/seed-leads' component ={SeedLeads} />
          <Route exact path='/seed-users' component ={SeedUsers} />
          <Route exact path='/seed-projects' component = {SeedProjects}/>
          <Route exact path='/seed-sales' component = {SeedSales}/>
          <Route exact path='/seed-affiliates-id' component= {SeedAffiliatesId}/>
          <Route path='/lead/:id' component ={LeadAction} />
          <Route exact path='/user-change-password' component={UserChangePassword} />
          <Route exact path='/sales-page' component={SalesPage}/>
          <Route path='/sale-create-page/:id' component={SaleCreatePage}/>
          <Route exact path='/sale-create-page' component={SaleCreatePage}/>
          <Route exact path='/project-create-page' component={ProjectCreatePage}/>
          <Route exact path='/projects-page' component={ProjectsPage}/>
          <Route path = '/comm-structure/:id' component = {CommStructure}/>
          <Route exact path = '/users-sales-page' component = {usersSalesPage} />
          <Route exact path = '/sales-project-page' component = {ProjectsSalesPage}/>
          <Route exact path = '/users-total-sales-page' component = {UsersTotalSales}/>
          <Route exact path = '/sale-report' component = {SaleReport}/>
          <Route path = '/sale-report/:id' component = {SaleReport}/>
          <Route exact path = '/sign-up-new-user' component = {SignUpNewUser}/>
          <Route exact path = '/sign-in-as' component = {SignInAs}/>
          <Route exact path = '/campaign-create-page' component = {CampaignCreatePage}/>
          <Route exact path = '/campaigns-page' component = {CampaignsPage} />
          <Route path = '/campaign-sale-page/:id' component ={CampaignPage} />
          <Route path="/uploadphotos" exact component={UploadPhotos} />
          <Route path='/user-downlines-page' exact component={userDownlinesPage} />
          <Route path='/telegram-signup' exact component={TelegramSignUp} />
          <Route path='/trainings-page' exact component={TrainingsPage} />

        </Switch>
      </div>
    );
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user)),
  checkUserSession: () => dispatch(checkUserSession()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
