import React from "react";

import "./emails-chips-input.styles.scss";

class EmailsChipsInput extends React.Component {
  state = {
    items: [],
    value: "",
    error: null
  };

  handleKeyDown = evt => {
    const {handleEnter} = this.props;
    if (["Enter", "Tab", ","].includes(evt.key)) {
        evt.preventDefault();

        var value = this.state.value.trim();

        if (value && this.isValid(value)) {
            this.setState({
            items: [...this.state.items, this.state.value],
            value: ""
            },()=>handleEnter(this.state.items));
      }
    }
  };

  handleChange = evt => {
    this.setState({
      value: evt.target.value,
      error: null
    });
  };

  handleDelete = item => {
    const {handleEnter} = this.props;
    this.setState({
      items: this.state.items.filter(i => i !== item)
    },()=>handleEnter(this.state.items));
  };

  handlePaste = evt => {
    const {handleEnter} = this.props;
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      var toBeAdded = emails.filter(email => !this.isInList(email));

      this.setState({
        items: [...this.state.items, ...toBeAdded]
      },()=>handleEnter(this.state.items));
    }
  };

  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  isEmail(email) {
    return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
  }

  render() {
    return (
        <React.Fragment>
            {this.state.items.map(item => (
                <div className="tag-item" key={item}>
                {item}
                <button
                    type="button"
                    className="button"
                    onClick={() => this.handleDelete(item)}
                >
                    &times;
                </button>
                </div>
            ))}

            <input
                className={"input " + (this.state.error && " has-error")}
                value={this.state.value}
                placeholder="Type or paste email addresses and press `Enter`..."
                onKeyDown={this.handleKeyDown}
                onChange={this.handleChange}
                onPaste={this.handlePaste}
            />
            {this.state.error && <p className="error">{this.state.error}</p>}
        </React.Fragment>
        )
    }
}
  
export default EmailsChipsInput
