import {CampaignActionTypes} from './campaign.types';
import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { default as firebase, firestore } from '../../firebase/firebase.utils';
import { dateFormat } from '../../util-functions/date-format';
import { loadTeamSalesStart } from '../sale/sale.actions';
import { numberWithCommas, stringToNumber } from '../../util-functions/numer-formating';

const getSaleTeamSales = (state) => state.sale.teamSales
const getCampaignCampaigns = state => state.campaign.campaigns
// const getUserUsers = state => state.user.user

export function* createCampaignStart({payload: campaign}){
    console.log(campaign)
    const collectRef = firestore.collection('campaigns');
    campaign = {
        ...campaign,
        startDate: firebase.firestore.Timestamp.fromDate(new Date(campaign.startDate)),
        endDate: firebase.firestore.Timestamp.fromDate(new Date(campaign.endDate)),
        conversionDate: firebase.firestore.Timestamp.fromDate(new Date(campaign.conversionDate)),
        salesValue: parseFloat(campaign.salesValue)
    }
    try{
        yield collectRef.add(campaign)
        yield put({type: CampaignActionTypes.CREATE_CAMPAGIN_SUCCESS})
        console.log('Campaign added into Firestore')
    }catch(err){
        alert(err.message)
        console.log(err.message)
        return null
    }
}

export function* loadCampaignsStart({payload: {startDate, endDate}}){
    const collectionRef = firestore.collection('campaigns')
    try{
        const snapshot = yield collectionRef.get()
        const campaignsMap = snapshot.docs.map(doc=> ({
            ...doc.data(),
            id: doc.id,
            startDate: dateFormat(doc.data().startDate.toDate()),
            endDate: dateFormat(doc.data().endDate.toDate()),
            conversionDate: dateFormat(doc.data().conversionDate.toDate()),
        }))
        yield put({type: CampaignActionTypes.LOAD_CAMPAIGNS_SUCCESS, payload: campaignsMap})
    }catch(err){
        alert(err.message)
        console.log(err.message)
    }
}

export function* loadCampaignSalesStart({payload: campaignFid}){
    const campaigns = yield select(getCampaignCampaigns)
    const campaign = campaigns.find(doc => doc.id === campaignFid)
    const {startDate, endDate, projects} = campaign
    yield put(loadTeamSalesStart(startDate, endDate))
    let teamSales = yield select(getSaleTeamSales)

    let projectSales = [];
    projects.forEach(project=>{
        teamSales.forEach(sale=>{
            if(sale.projectFid === project.projectFid){
                projectSales.push(sale)
            }
        })
    })
    
    let downlinesTotalSalesBooked = [];
    let downlinesTotalSalesConverted = [];
    projectSales.forEach(sale=>{
        if(sale.status !== 'Cancelled'){
            downlinesTotalSalesBooked[sale.renName] = downlinesTotalSalesBooked[sale.renName] ? downlinesTotalSalesBooked[sale.renName] + sale.netPriceForRen : sale.netPriceForRen
        }
        if(sale.status==='Converted'){
            downlinesTotalSalesConverted[sale.renName] = downlinesTotalSalesConverted[sale.renName] ? downlinesTotalSalesConverted[sale.renName] + sale.netPriceForRen : sale.netPriceForRen
        }
    })

    let campaignTotalSales = [];
    Object.keys(downlinesTotalSalesBooked).forEach(key=>{
        campaignTotalSales.push({renName: key, Booked: numberWithCommas(downlinesTotalSalesBooked[key]), Converted: downlinesTotalSalesConverted[key] ? numberWithCommas(downlinesTotalSalesConverted[key]) : 0 });
    })

    campaignTotalSales.sort((a,b)=> stringToNumber(b.Booked) - stringToNumber(a.Booked))
    yield put({type: CampaignActionTypes.LOAD_CAMPAIGN_SALES_SUCCESS, payload: campaignTotalSales})
}

//SAGAS WATCHERS
export function* onCreateCampaign(){
    yield takeLatest(CampaignActionTypes.CREATE_CAMPAIGN, createCampaignStart)
}

export function* onLoadCampaigns(){
    yield takeLatest(CampaignActionTypes.LOAD_CAMPAIGNS, loadCampaignsStart)
}

export function* onLoadCampaignSales(){
    yield takeLatest(CampaignActionTypes.LOAD_CAMPAIGN_SALES, loadCampaignSalesStart)
}

export function* campaignSagas() {
    yield all([
        call(onCreateCampaign),
        call(onLoadCampaigns),
        call(onLoadCampaignSales)
    ]);
}
