//user-edit.component.jsx

import React from "react";
import "./user-edit.styles.scss";
import UserEditForm from "../user-edit-form/user-edit-form.component";
import {
  selectUserEditFormShow,
  selectCurrentUser,
} from "../../Redux/user/user.selector";
import { connect } from "react-redux";
import { toggleEditFormShow } from "../../Redux/user/user.actions";
import { createStructuredSelector } from "reselect";

class UserEdit extends React.Component {
  constructor() {
    super();

    this.state = {
      key: "",
      userId: "",
      id: "",
      displayName: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      immediateUpline: "",
      immediateUplineFid: "",
      location: "",
      icNumber: "",
      isAdmin: "",
    };
  }

  handleClick = (event) => {
    const key = event.currentTarget.getAttribute("data-index");
    const { toggleEditFormShow } = this.props;
    toggleEditFormShow();
    this.setState({
      key: key,
    });
  };

  render() {
    let { isAdmin } = this.props.user;
    const { user, currentUser } = this.props;
    const { editFormShow } = this.props;

    //turn boolean isAdmin into string
    if (isAdmin !== null && isAdmin !== undefined) {
      isAdmin = isAdmin.toString();
    }
    //create an object to display in Table
    const fieldToDisplay = {
      userId: "",
      id: "",
      displayName: "",
      fullName: "",
      email: "",
      phoneNumber: "",
      immediateUpline: "",
      immediateUplineFid: "",
      location: "",
      icNumber: "",
      affiliateId: "",
      isAdmin: "",
    };

    const toDisplayForm = (key) => {
      return key === this.state.key && editFormShow;
    };

    const toDisplay = (key) => {
      if (currentUser.isAdmin) {
        return true;
      } else {
        if (
          key === "isAdmin" ||
          key === "userId" ||
          key === "affiliateId" ||
          key === "email" ||
          key === "immediateUpline" ||
          key === "immediateUplineFid" ||
          key === "id"
        ) {
          return false;
        } else {
          return true;
        }
      }
    };

    return (
      <div className="ue-table">
        <h1>User Table</h1>
        {Object.keys(fieldToDisplay).map((key, i) => {
          return (
            <div key={`${key}${i}`} className="ue-row">
              <div className="ue-label">{key}</div>
              <div className="ue-value">{user[key] ? user[key] : null}</div>
              <React.Fragment>
                {(() => {
                  switch (toDisplay(key)) {
                    case false:
                      return <div className="ue-icon"></div>;
                    default:
                      return (
                        <div
                          className="ue-icon"
                          data-index={key}
                          onClick={this.handleClick}
                        >
                          &#9776;
                        </div>
                      );
                  }
                })()}
              </React.Fragment>
              {toDisplayForm(key) ? (
                <UserEditForm user={user} para={key} initialValue={user[key]} />
              ) : (
                <div></div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  editFormShow: selectUserEditFormShow,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  toggleEditFormShow: () => dispatch(toggleEditFormShow()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
