import { trainings } from './training.datas';
import { TrainingActionTypes } from './training.types';

const INITIAL_STATE = {
   trainings: trainings,
};

const trainingReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case TrainingActionTypes.LOAD_TRAININGS_START:
            return{
                ...state,
            }
        case TrainingActionTypes.CREATE_TRAINING_SUCCESS:
            return{
                ...state,
                trainings: [...state.trainings, action.payload],
            }
        case TrainingActionTypes.LOAD_TRAININGS_SUCCESS:
            return{
                ...state,
                trainings: action.payload,
            }
        case TrainingActionTypes.UPDATE_TRAINING_SUCCESS:
            return{
                ...state,
                trainings: action.payload
            }
        case TrainingActionTypes.DELETE_TRAINING_SUCCESS:
            return{
                ...state,
                trainings: action.payload
            }
        default:
            return state
    }
}

export default trainingReducer