import { TrainingActionTypes } from "./training.types";
import { takeLatest, call, all, put, select } from "redux-saga/effects";
import { firestore } from "../../firebase/firebase.utils";

const getTrainingTrainings = (state) => state.training.trainings;

export function* loadTrainingsStart() {
  try {
    const trainingsCollection = firestore.collection("trainings");
    const trainingsSnapshot = yield trainingsCollection.get();
    const trainingDocs = trainingsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    yield put({
      type: TrainingActionTypes.LOAD_TRAININGS_SUCCESS,
      payload: trainingDocs,
    });
  } catch (err) {
    console.log(err.message);
    alert(err.message + "\nPlease inform your admin");
  }
}

export function* createTrainingStart({ payload: trainingData }) {
  try {
    const trainingsCollection = firestore.collection("trainings");
    const { id } = yield trainingsCollection.add(trainingData);
    console.log(id);
    console.log("Training added into Firestore. Id: " + id);
    alert("Training uploaded to Database.");
    yield put({
      type: TrainingActionTypes.CREATE_TRAINING_SUCCESS,
      payload: { ...trainingData, id: id },
    });
  } catch (err) {
    console.log(err.message);
    alert(err.message + " \nPlease inform your admin");
  }
}

export function* updateTrainingStart({ payload: trainingData }) {
  try {
    const trainingDoc = firestore.collection("trainings").doc(trainingData.id);
    yield trainingDoc.set(trainingData);
    console.log("Training updated in Firestore Id: " + trainingData.id);
    const trainings = yield select(getTrainingTrainings);
    const index = trainings.findIndex(
      (training) => training.id === trainingData.id
    );
    trainings[index] = trainingData;
    yield put({
      type: TrainingActionTypes.UPDATE_TRAINING_SUCCESS,
      payload: trainings,
    });
  } catch (err) {
    console.log(err.message);
    alert(err.message + "\nPlease inform your admin.");
  }
}

export function* deleteTrainingStart({ payload: training }) {
  try {
    const trainingDocRef = firestore.collection("trainings").doc(training.id);
    yield trainingDocRef.delete();
    console.log(training.id + " deleted from Firestore.");
    const trainings = yield select(getTrainingTrainings);
    const index = trainings.findIndex(doc => doc.id === training.id);
    trainings.splice(index, 1);
    yield put({type: TrainingActionTypes.DELETE_TRAINING_SUCCESS, payload: trainings});
  } catch (err) {
    console.log(err.message);
  }
}

//Watchers Sages

export function* onLoadTrainings() {
  yield takeLatest(
    TrainingActionTypes.LOAD_TRAININGS_START,
    loadTrainingsStart
  );
}

export function* onCreateTraining() {
  yield takeLatest(
    TrainingActionTypes.CREATE_TRAINING_START,
    createTrainingStart
  );
}

export function* onUpdateTraining() {
  yield takeLatest(
    TrainingActionTypes.UPDATE_TRAINING_START,
    updateTrainingStart
  );
}

export function* onDeleteTraining() {
  yield takeLatest(
    TrainingActionTypes.DELETE_TRAINING_START,
    deleteTrainingStart
  );
}

export function* trainingSagas() {
  yield all([
    call(onLoadTrainings),
    call(onCreateTraining),
    call(onUpdateTraining),
    call(onDeleteTraining),
  ]);
}
