import React from "react";

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import ItemsDropDown from "../items-dropdown/items-dropdown.component";

import { SignUpContainer, SignUpTitle } from "../sign-up/sign-up.styles";
import { selectUserUsers } from "../../Redux/user/user.selector";
import { signUpNewUser, fetchUsersStart } from "../../Redux/user/user.actions";
import { toTitleCase } from "../../util-functions/toTitleCase";
import { sendTelegramMessage } from "../../telegram/sendTelegramMessage";
// import uuid from  'react-uuid';

class SignUpNewUser extends React.Component {
  INITIAL_STATE = {
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    secretCode: "",
    verificationCode: "",
    displayName: "",
    fullName: "",
    birthday: "",
    icNumber: "",
    immediateUpline: "",
    immediateUplineFid: "",
    location: "",
    locationFid: "",
    personalSalesIndex: [],
    teamSalesIndex: [],
    position: [{ date: new Date(), positionName: "ren" }],
    referrer: "",
    referrerFid: "",
    chatId: "",
    createdAt: new Date(),
    showDropDown: { immediateUpline: false, referrer: false, location: false },
  };

  state = this.INITIAL_STATE;

  componentDidMount() {
    const { fetchUsersStart } = this.props;
    fetchUsersStart();
    window.scroll(0, 0);
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { password, confirmPassword } = this.state;
    const { signUpNewUser } = this.props;

    if (password !== confirmPassword) {
      alert("passwords don't match");
      return;
    }

    if (this.state.secretCode !== "eliteone123") {
      alert("Opps! Secret Code is not correct.");
      return;
    }

    if (
      parseInt(this.state.chatId) * 2 !==
      parseInt(this.state.verificationCode)
    ) {
      alert("Opps! Wrong verification code");
      return;
    }

    try {
      await signUpNewUser({
        ...this.state,
        displayName: toTitleCase(this.state.displayName.trim()), //trim and make displayName toTitleCase
      });
      this.setState(this.INITIAL_STATE);
    } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };

  toggleDropDown = (name) => {
    this.setState({
      showDropDown: {
        ...this.state.showDropDown,
        [name]: !this.state.showDropDown[name],
      },
    });
  };

  handleChange = (event) => {
    const { id, value } = event.target;

    this.setState({ ...this.state, [id]: value });
  };

  clickItem = (item, name, key) => {
    console.log(item);
    console.log(name);
    console.log(key);
    this.setState(
      {
        ...this.state,
        [name]: item[key],
        [`${name}Fid`]: item.id,
        showDropDown: {
          ...this.state.showDropDown,
          [name]: !this.state.showDropDown[name],
        },
      },
      () => {
        console.log(this.state);
      }
    );
  };

  handleClickVerification = async () => {
    await sendTelegramMessage(
      this.state.chatId,
      `Hi ${this.state.displayName}. Your verification code is ${
        parseInt(this.state.chatId) * 2
      }`,
      this.state.displayName
    );
    alert("Check your Telegram for the verification code.");
  };

  render() {
    const {
      email,
      phoneNumber,
      password,
      confirmPassword,
      displayName,
      fullName,
      icNumber,
      immediateUpline,
      referrer,
      location,
      chatId,
      verificationCode,
    } = this.state;
    const { users } = this.props;
    return (
      <SignUpContainer>
        <SignUpTitle>I do not have an account</SignUpTitle>
        <span>
          I know it is a long form, take your time to fill in the below.{" "}
          <span role="img" aria-label="smile">
            &#x1f604;
          </span>
        </span>
        <form className="sign-up-form" onSubmit={this.handleSubmit}>
          <FormInput
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={this.handleChange}
            label="Email"
            required
          />
          <FormInput
            type="text"
            name="phoneNumber"
            id="phoneNumber"
            value={phoneNumber}
            onChange={this.handleChange}
            label="Phone Number"
            required
          />
          <FormInput
            type="text"
            name="displayName"
            id="displayName"
            value={displayName}
            onChange={this.handleChange}
            label="Display Name"
            required
          />
          <FormInput
            type="text"
            name="fullName"
            id="fullName"
            value={fullName}
            onChange={this.handleChange}
            label="Full Name as per IC"
            required
          />
          <FormInput
            type="text"
            name="icNumber"
            id="icNumber"
            value={icNumber}
            onChange={this.handleChange}
            label="IC Number"
            required
          />
          <FormInput
            type="text"
            name="immediateUpline"
            id="immediateUpline"
            value={immediateUpline}
            onChange={this.handleChange}
            label="Immediate Upline"
            required
            readOnly
            onClick={() => this.toggleDropDown("immediateUpline")}
          />
          {this.state.showDropDown["immediateUpline"] ? (
            <ItemsDropDown
              onChange={this.handleChange}
              value={immediateUpline}
              name={"immediateUpline"}
              type="text"
              toggleDropDown={this.toggleDropDown}
              items={users}
              keyToMap="displayName"
              clickItem={this.clickItem}
            />
          ) : null}
          <FormInput
            type="text"
            name="referrer"
            id="referrer"
            value={referrer}
            onChange={this.handleChange}
            label="Referrer"
            required
            readOnly
            onClick={() => this.toggleDropDown("referrer")}
          />
          {this.state.showDropDown["referrer"] ? (
            <ItemsDropDown
              onChange={this.handleChange}
              value={referrer}
              name={"referrer"}
              type="text"
              toggleDropDown={this.toggleDropDown}
              items={users}
              keyToMap="displayName"
              clickItem={this.clickItem}
            />
          ) : null}
          <FormInput
            type="text"
            name="location"
            id="location"
            value={location}
            onChange={this.handleChange}
            label="Location"
            required
            readOnly
            onClick={() => this.toggleDropDown("location")}
          />
          {this.state.showDropDown["location"] ? (
            <ItemsDropDown
              onChange={this.handleChange}
              value={location}
              name={"location"}
              type="text"
              toggleDropDown={this.toggleDropDown}
              items={[
                { location: "KL", id: "KL" },
                { location: "Penang", id: "Penang" },
                { location: "Johor", id: "Johor" },
                { location: "KK", id: "KK" },
                { location: "Kuching", id: "Kuching" },
                { location: "Melaka", id: "Melaka" },
              ]}
              keyToMap="location"
              clickItem={this.clickItem}
            />
          ) : null}
          <div
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "gray",
              padding: "3px",
            }}
          >
            <a
              href="https://t.me/eliteonebot"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here and send "Register Me" to DJC System
            </a>
          </div>

          <div style={{ margin: "10px" }}>
            Click on the Button above. Type "Register Me" and send to DJC
            System. You will get the Chat Id. Type the Chat Id into the form
            below and click send verification code. You will receive your
            Verification code in Telegram. Enter the verification code into form
            below.
          </div>
          <FormInput
            type="number"
            name="chatId"
            id="chatId"
            value={chatId}
            onChange={this.handleChange}
            label="Telegram Chat Id"
            required
          />
          <FormInput
            type="number"
            name="verificationCode"
            id="verificationCode"
            value={verificationCode}
            onChange={this.handleChange}
            label="Telegram Verification Code"
            required
          />
          <div
            onClick={this.handleClickVerification}
            style={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "gray",
              padding: "3px",
            }}
          >
            Click here and to receive verification code
          </div>
          <FormInput
            type="password"
            name="password"
            id="password"
            value={password}
            onChange={this.handleChange}
            label="Password"
            required
          />
          <FormInput
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            value={confirmPassword}
            onChange={this.handleChange}
            label="Confirm Password"
            required
          />
          <FormInput
            type="text"
            name="secretCode"
            id="secretCode"
            value={this.state.secretCode}
            onChange={this.handleChange}
            label="Secret Code"
            required
          />
          <CustomButton type="submit">SIGN UP</CustomButton>
        </form>
      </SignUpContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  users: selectUserUsers,
});

const mapDispatchToProps = (dispatch) => ({
  signUpNewUser: (data) => dispatch(signUpNewUser(data)),
  fetchUsersStart: () => dispatch(fetchUsersStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpNewUser);
