import { firestore, auth } from "../firebase/firebase.utils";
import firebase from "../firebase/firebase.utils";
import { sendTelegramMessage } from "../telegram/sendTelegramMessage";

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd,
  parameterToCheck
) => {
  const collectionRef = firestore.collection(collectionKey);

  const snapShot = await collectionRef.get();
  const docs = snapShot.docs;

  let newObjectsToAdd = [];
  let objExist = false;
  objectsToAdd.forEach((object) => {
    docs.forEach((doc) => {
      if (doc.data()[parameterToCheck] === object[parameterToCheck]) {
        objExist = true;
      }
    });
    if (!objExist) {
      newObjectsToAdd.push(object);
    }
    objExist = false;
  });
  console.log(newObjectsToAdd);

  const batch = firestore.batch();
  newObjectsToAdd.forEach((obj) => {
    const newDocRef = collectionRef.doc();
    batch.set(newDocRef, obj);
  });

  return await batch
    .commit()
    .then(() => {
      console.log("Batch upload done");
    })
    .catch((err) => {
      alert(err.message);
    });
};

export const createUserInGoogleAuth = async (obj) => {
  const { email, password, ...otherProps } = obj;

  try {
    const { user } = await auth.createUserWithEmailAndPassword(email, password);
    console.log(user);

    await addUserIntoFirestore(user, { ...otherProps });
  } catch (error) {
    console.error(error);
  }
};

export const addUserIntoFirestore = async (userAuth, additionalData) => {
  if (!userAuth) return;
  const userRef = firestore.collection("users").doc(userAuth.uid);
  console.log("userRef", userRef);
  const snapShot = await userRef.get();
  const { email } = userAuth;
  if (!snapShot.exists) {
    userRef
      .set({
        email: email,
        ...additionalData,
      })
      .then(() => console.log(additionalData.displayName, " user created!"))
      .catch((error) => {
        console.log("error creating user", error.message);
      });
  }
  return userRef;
};

export const fillInSalesProjectId = async () => {
  const projectsRef = firestore.collection("projects");
  const salesRef = firestore.collection("sales");

  //use Promise.all to wait for all Promises to return value
  let [projectsSnapShot, salesSnapShot] = await Promise.all([
    projectsRef.get(),
    salesRef.get(),
  ]);
  let [salesDocs, projectDocs] = [salesSnapShot.docs, projectsSnapShot.docs];

  salesDocs.forEach((saleDoc) => {
    projectDocs.forEach((projectDoc) => {
      if (saleDoc.data().projectName === projectDoc.data().projectName) {
        console.log(
          projectDoc.data().projectName +
            " " +
            saleDoc.data().unitNumber +
            " updated"
        );
        salesRef
          .doc(saleDoc.id)
          .set({
            ...saleDoc.data(),
            projectFid: projectDoc.id,
            projectId: projectDoc.data().projectId,
          })
          .then(() => {
            console.log("projectId inserted");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  });
};

export const fillInProjectsWithCommStructureFid = async () => {
  const projectsRef = firestore.collection("projects");
  const commStructureRef = firestore.collection("commStructures");

  //use Promise.all to wait for all Promises to return value
  let [projectsSnapShot, commStructuresSnapShot] = await Promise.all([
    projectsRef.get(),
    commStructureRef.get(),
  ]);
  let [projectDocs, commStructureDocs] = [
    projectsSnapShot.docs,
    commStructuresSnapShot.docs,
  ];

  //Fill in commStructureFid in projects
  projectDocs.forEach((projectDoc) => {
    commStructureDocs.forEach((commStructureDoc) => {
      if (
        projectDoc.data().commStructureId ===
        commStructureDoc.data().commStructureId
      ) {
        console.log(projectDoc.data().commStructureId);
        projectsRef
          .doc(projectDoc.id)
          .set({
            ...projectDoc.data(),
            commStructureFid: commStructureDoc.id,
          })
          .then(() => {
            console.log(
              commStructureDoc.data().commStructureId + " updated in projects"
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  });
};

export const updateSalesWithUserFid = async () => {
  //get all sales and match sale.rens.renName with userFid and userId
  const usersRef = firestore.collection("users");
  const salesRef = firestore.collection("sales");

  let [usersSnapshot, salesSnapshot] = await Promise.all([
    usersRef.get(),
    salesRef.get(),
  ]);
  let [userDocs, saleDocs] = [usersSnapshot.docs, salesSnapshot.docs];
  let rensArray = [];

  let isFound = false;
  saleDocs.forEach((saleDoc) => {
    rensArray = [...saleDoc.data().rens];
    saleDoc.data().rens.forEach((ren, i) => {
      userDocs.forEach((userDoc) => {
        if (
          ren.renName.toLowerCase() === userDoc.data().displayName.toLowerCase()
        ) {
          isFound = true;
          rensArray[i] = {
            ...ren,
            userId: userDoc.data().userId,
            userFid: userDoc.id,
          };
        }
      });
    });
    salesRef
      .doc(saleDoc.id)
      .set({
        ...saleDoc.data(),
        rens: rensArray ? rensArray : saleDoc.data().rens,
      })
      .then(() => {
        console.log(rensArray);
        console.log("Updated into Firestore!");
      })
      .catch((err) => {
        console.log(err);
        console.log(rensArray);
      });
    if (!isFound) {
      console.log(saleDoc.data().unitNumber + "error with users names");
    }
    isFound = false;
  });
};

export const getUplinesFid = async (users) => {
  let cannotFindUpline = [];
  const findUpline = (user) => {
    if (!user.immediateUpline) {
      console.log(user.displayName + " has no upline");
      return null;
    }
    let immediateUpline = users.find(
      (doc) =>
        doc.displayName.toLowerCase() === user.immediateUpline.toLowerCase()
    );
    if (!immediateUpline) {
      cannotFindUpline.push(user.displayName);
      console.log("Cannot find upline for " + user.displayName);
      firestore
        .collection("users")
        .doc(user.id)
        .set({
          ...user,
          immediateUplineFid: "dWWjhcyzPt9VO1RR0zib",
          immediateUpline: "Dave Chong",
        })
        .then(() => {
          console.log("Firestore updated!");
        });
      return null;
    }
    console.log(
      user.displayName + "'s upline is " + immediateUpline.displayName
    );
    return immediateUpline;
  };

  users.forEach((user, i) => {
    const immediateUpline = findUpline(user);
    if (immediateUpline) {
      firestore
        .collection("users")
        .doc(user.id)
        .set({
          ...user,
          immediateUplineFid: immediateUpline.id,
          immediateUpline: immediateUpline.displayName,
        })
        .then(() => {
          console.log("Firestore updated!");
        });
    }
  });

  console.log("Users that cannot find Immediate Upline:");
  console.log(cannotFindUpline);
};

export const getUplinesArray = async (users) => {
  let uplines = [];
  let notFound = [];

  //findUpline function Start here
  const findUpline = (user) => {
    if (!user.immediateUpline) {
      return null;
    }
    let immediateUpline = users.find(
      (doc) =>
        doc.displayName.toLowerCase() === user.immediateUpline.toLowerCase()
    );
    if (!immediateUpline) {
      console.log(user.immediateUpline + " is not found in system");
      notFound.push(user.immediateUpline);
      uplines.push({
        uplineFid: "dWWjhcyzPt9VO1RR0zib",
        uplineName: "Dave Chong",
      });
      return null;
    }
    console.log(
      "immediateUpline of " +
        user.displayName +
        " is " +
        immediateUpline.displayName
    );
    uplines.push({
      uplineFid: immediateUpline.id,
      uplineName: immediateUpline.displayName,
    });
    findUpline(immediateUpline);
  };
  //end of findUpline

  const filteredUsers = users;
  console.log(filteredUsers);

  filteredUsers.forEach((user) => {
    console.log("user is " + user.displayName);
    findUpline(user);
    console.log(uplines);

    firestore
      .collection("uplines")
      .add({
        userName: user.displayName,
        userFid: user.id,
        date: "2014-03-01",
        uplines: uplines,
      })
      .then(() => {
        console.log("Added in to Firestore");
      })
      .catch((err) => {
        console.log(err.message);
      });

    uplines = [];
  });

  console.log("Not Found:");
  console.log(notFound);
};

//Find Downlines Array function
export const findDownlinesArray = async () => {
  let downlinesArray = [];
  let userDownlinesArray = [];
  const usersRef = firestore.collection("users");
  const downlinesRef = firestore.collection("downlines");

  const [usersSnapshot, snapshots] = await Promise.all([
    usersRef.get(),
    downlinesRef.get(),
  ]);
  const users = usersSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  const downlinesDocs = snapshots.docs;
  console.log(users);

  const updateDownlines = (downlinesDocs, users) => {
    const findDownline = (user) => {
      let downlines = users.filter((doc) => doc.immediateUplineFid === user.id); //user Fid, can use displayname if Fid not available
      if (!downlines) {
        console.log(user.displayName + " does not have a downline");
        return null;
      }

      downlines.forEach((downline) => {
        downlinesArray.push({
          userName: downline.displayName,
          userFid: downline.id,
        });
        findDownline(downline); // search for the downlines for next downline
      });
    };

    const findDownlinesId = (user) => {
      let downlinesId = "";
      downlinesDocs.forEach((doc) => {
        if (doc.data().userFid === user.id) {
          downlinesId = doc.id;
          console.log("match!");
        }
      });
      return downlinesId;
    };

    users.forEach((user) => {
      const downlinesId = findDownlinesId(user);
      downlinesArray.push({ userName: user.displayName, userFid: user.id });
      findDownline(user); //start the loop
      userDownlinesArray.push({
        userName: user.displayName,
        userFid: user.id,
        downlines: downlinesArray,
      });

      if (!downlinesId) {
        console.log(user.displayName + "(" + user.id + ") cannot find downlines Id");
        //create new doc
        firestore
          .collection("downlines")
          .add({
            userName: user.displayName ? user.displayName : 0,
            userFid: user.id,
            downlines: downlinesArray,
            updatedAt: new Date(),
          })
          .then(() => {
            console.log("Added to Firestore");
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        //update firestore
        firestore
          .collection("downlines")
          .doc(downlinesId)
          .set({
            userName: user.displayName,
            userFid: user.id,
            downlines: downlinesArray,
            updatedAt: new Date(),
          })
          .then(() => {
            console.log("Updated to Firestore");
          })
          .catch((err) => {
            console.log(err.message);
          });
      }

      downlinesArray = []; //reset array
    });
  };

  updateDownlines(downlinesDocs, users);

  console.log("User DownlinesArrary: ");
  console.log(userDownlinesArray);
};

export const dateToFirestoreTimestamp = async (
  originalObjs,
  collectionName,
  nameOfDateField
) => {
  const collectionRef = firestore.collection(collectionName);
  console.log("HERE!");

  originalObjs.forEach((obj) => {
    if (typeof obj[nameOfDateField] === "string") {
      const timestamp = firebase.firestore.Timestamp.fromDate(
        new Date(obj[nameOfDateField])
      );
      const newObj = {
        ...obj,
        date: timestamp,
      };

      //Update Firestore
      collectionRef
        .doc(obj.id)
        .set(newObj)
        .then(() => {
          console.log("Updated into Firestore");
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  });
};

export const loadSalesIdIntoUplines = async (sales, users) => {
  let teamSales = [];
  let personalSales = [];
  const filteredSales = sales;
  console.log(filteredSales);

  const findUpline = (ren, sale) => {
    let foundUser = users.find((user) => ren.id === user.id);
    if (!foundUser) {
      console.log("Cannot find " + ren.displayName + " in database");
      return null;
    }
    console.log("User is " + foundUser.displayName);

    let foundUpline = users.find(
      (user) => user.displayName === foundUser.immediateUpline
    );
    if (!foundUpline) {
      console.log("Cannot find " + ren.displayName + " upline in database");
      return null;
    }
    console.log("Found upline is " + foundUpline.displayName);
    console.log(foundUpline.id);
    if (!teamSales[foundUpline.id]) {
      teamSales[foundUpline.id] = [];
    }
    teamSales[foundUpline.id] = [...teamSales[foundUpline.id], sale.id];
    console.log(teamSales);
    findUpline(foundUpline, sale);
  };

  filteredSales.forEach((sale) => {
    sale.rens.forEach((ren) => {
      let foundUser = users.find((user) => user.id === ren.userFid);
      if (ren.userFid) {
        if (!teamSales[ren.userFid]) {
          teamSales[ren.userFid] = [];
        }
        if (!personalSales[ren.userFid]) {
          personalSales[ren.userFid] = [];
        }
        teamSales[ren.userFid] = [...teamSales[ren.userFid], sale.id];
        personalSales[ren.userFid] = [...personalSales[ren.userFid], sale.id];
        findUpline(foundUser, sale);
      }
    });
  });

  //remove duplicates
  Object.keys(teamSales).forEach((key) => {
    teamSales[key] = teamSales[key].filter(
      (v, i) => teamSales[key].findIndex((index) => index === v) === i
    );
  });

  console.log(teamSales);
  console.log(personalSales);

  const collectionRef = firestore.collection("users");

  // Object.keys(teamSales).forEach(userFid => {
  //   let foundUser = users.find(user => user.id === userFid)

  //   collectionRef.doc(userFid).set({
  //     ...foundUser,
  //     teamSalesIndex: teamSales[userFid]
  //   }).then(()=>{
  //     console.log('Updated teamSalesIndex into Firestore')
  //   }).catch(err => {
  //     console.log(err.message)
  //   })
  // })

  Object.keys(personalSales).forEach((userFid) => {
    personalSales[userFid] = personalSales[userFid].filter(
      (v, i) => personalSales[userFid].findIndex((index) => index === v) === i
    );
    let foundUser = users.find((user) => user.id === userFid);

    collectionRef
      .doc(userFid)
      .set({
        ...foundUser,
        personalSalesIndex: personalSales[userFid],
      })
      .then(() => {
        console.log("Updated personalSalesIndex into Firestore");
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
};

export const seedAffiliatesId = async (objs) => {
  const collectionRef = firestore.collection("users");
  const usersSnapshot = await collectionRef.get();

  const users = usersSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  objs.map(async (obj) => {
    let foundUser = users.find(
      (user) => user.email.toLowerCase() === obj.email.toLowerCase()
    );
    if (!foundUser) {
      console.log(obj.displayName + " email is not in database");
      return null;
    }
    foundUser["affiliateId"] = obj.affiliateId.toLowerCase();
    console.log(foundUser);
    return await collectionRef
      .doc(foundUser.id)
      .set({
        ...foundUser,
      })
      .then(() => {
        console.log("User Updated with AffiliateId in Firestore!");
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
};

export const affiliateIdToLowerCase = async () => {
  const collectionRef = firestore.collection("leads");
  const snapshot = await collectionRef.get();
  let leads = snapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    affiliateId: doc.data().affiliateId.toLowerCase(),
  }));
  leads.forEach((lead) => {
    collectionRef
      .doc(lead.id)
      .set(lead)
      .then(() => {
        console.log("Leads affiliate Id updated in Firestore");
      })
      .catch((err) => {
        console.log(err.message);
      });
  });
};

export const findSaleIdAndReplace = async (objs) => {
  let collectionRef = firestore.collection("sales");
  let snapshot = await collectionRef.get();
  let sales = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  objs.forEach((obj) => {
    try {
      let foundSale = sales.find((sale) => sale.saleId === obj.saleId);
      console.log(foundSale.id);
      foundSale = {
        ...foundSale,
        unitNumber: obj.unitNumber,
      };
      console.log(foundSale);
      collectionRef
        .doc(foundSale.id)
        .set(foundSale)
        .then(() => {
          console.log("Updated in Firestore!");
        });
    } catch (err) {
      console.log(err.message);
      console.log(obj);
    }
  });
};

export const turnDoneToConverted = async () => {
  let collectionRef = firestore
    .collection("sales")
    .where("status", "==", "Done");
  let snapShot = await collectionRef.get();
  let docs = snapShot.docs;
  let sales = docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    status: "Converted",
  }));
  // sales = sales.slice(0,2)

  sales.forEach((sale) => {
    firestore
      .collection("sales")
      .doc(sale.id)
      .set(sale)
      .then(() => {
        console.log("Firestore updated with status");
      })
      .catch((err) => {
        console.log(err.message);
      });
  });

  console.log(sales);
};

export const listUsers = async () => {
  let collectionRef = firestore.collection("users");
  let snapShot = await collectionRef.get();
  let docs = snapShot.docs;
  let users = docs.map((doc) => ({ ...doc.data(), id: doc.id }));

  let testUsers = [];
  let usersWithId = [];

  users.forEach((user) => {
    try {
      if (typeof user.userId === "number") {
        // console.log(user.displayName, user.userId)
        usersWithId.push({
          ...user,
          id: user.id,
          displayName: user.displayName,
          userId: user.userId,
        });
      } else {
        console.log(user.displayName, "No ID");
        console.log(user);
        testUsers.push({
          ...user,
          id: user.id,
          displayName: user.displayName,
          createdAt: user.createdAt.toDate(),
        });
      }
    } catch (err) {}
  });

  usersWithId = usersWithId.sort((a, b) => b.userId - a.userId);
  testUsers = testUsers.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  console.log(testUsers);
  console.log(usersWithId);

  // let countId = 1700
  // testUsers.forEach(user=>{
  //   firestore.collection('users').doc(user.id)
  //   .set({
  //     ...user,
  //     userId: countId,
  //   }).then(()=>{
  //     console.log(user.displayName, 'Updated to Firestore')
  //   }).catch(err=>{
  //     console.log(err)
  //   })
  //   countId = countId + 1;
  // })
};

export const listDownlines = async () => {
  let collectionRef = firestore
    .collection("downlines")
    .where("userName", "==", "Dave Chong");
  let snapShot = await collectionRef.get();
  let docs = snapShot.docs;
  let downlines = docs.map((doc) => ({ ...doc.data() }));

  let myDownlines = downlines[0].downlines;
  let missingAgent = myDownlines.find(
    (downline) => downline.userFid === "4lMZBluCzKe83059hZEGQuhLBnj2"
  );
  console.log(missingAgent);

  const usersRef = firestore
    .collection("users")
    .where("displayName", "==", "Apple Chay");
  const usersSnapshot = await usersRef.get();
  const users = usersSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  console.log(users);

  sendTelegramMessage("81400808", "Hello World!");
};

export const removeDuplicatedSales = async () => {
  const usersRef = firestore.collection("users");
  const usersSnapshot = await usersRef.get();
  const users = usersSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  console.log(users);

  users.forEach((user) => {
    if (!user.personalSalesIndex) {
      console.log(user.displayName + " has no personalSalesIndex");
    } else {
      //remove duplicated saleIds
      user.personalSalesIndex = user.personalSalesIndex.filter(
        (v, i) =>
          user.personalSalesIndex.findIndex((index) => index === v) === i
      );
    }
  });

  // const testUsers = users.slice(100,102);
  // console.log(testUsers);

  users.forEach((user) => {
    usersRef
      .doc(user.id)
      .set({
        ...user,
      })
      .then(() => {
        console.log("Updated Firestore");
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const testTelegram = async (sales) => {
  sendTelegramMessage("96022078", "You are awesome!!!", "Dave Chong")
  console.log("Telegram sent")
};
