//Format date to yyyy-mm-dd format
export const dateFormat = date => {
    let currentDateTime = new Date(date);
    let month = (currentDateTime.getMonth() + 1).toString()
    let day = currentDateTime.getDate().toString();

    if(month.length===1){
        month = '0'+ month;
    }

    if(day.length===1){
        day = '0'+day;
    }

    let formattedDate = currentDateTime.getFullYear() + "-" +  month + "-" +  day;

    return formattedDate;
}

export const getStartDateEndDateForYear = (year) => {
    const startYear = year - 1;
    let startDate = startYear + '-12-16';
    let endDate = year + '-12-16';
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    
    return {startDate, endDate};
  }

export const getStartDateEndDateForQuarter = () => {
    const thisDate = new Date();
    let year = thisDate.getFullYear();
    let month = thisDate.getMonth() + 1;
    let day = thisDate.getDate();
    let startDate = '';
    let endDate = '';

    if(month === 1 || month === 2 || month === 3){
        startDate = (year - 1) + '-12-16';
        endDate = year + '-03-31';
    }
    if(month === 4 || month === 5 || month === 6){
        startDate = year + '-04-01';
        endDate = year + '-06-30';
    }
    if(month === 7|| month === 8 || month === 9){
        startDate = year + '-07-01';
        endDate = year + '-09-30';
    }
    if(month === 10|| month === 11 || month === 12){
        startDate = year + '-10-01';
        endDate = year + '-12-16';
    }
    if(month === 12 && day >= 16){
        startDate = year + '-12-16';
        endDate = (year + 1) + '-03-31';
    }
    
    console.log(startDate, endDate)
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    
    return {startDate, endDate};
}

export const month2String = (month) => {
    if(month<10){
        month = `0${month}`
    } else {
        month = month.toString();
    }

    return month;
}
    