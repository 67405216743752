import React from 'react';
import {OutTable, ExcelRenderer} from 'react-excel-renderer';
import {addCollectionAndDocuments} from './seeding.utils';
import CustomButton from '../components/custom-button/custom-button.component';

class SeedLeads extends React.Component {
    constructor(){
        super()

        this.state = {
            cols: [],
            rows: [],
            obj: [],
        }
    }

    
    handleClick = ()=>{
        addCollectionAndDocuments('leads', this.state.obj)
    }

    render(){
        const obj = [];
        const fileHandler = (event) => {
            let fileObj = event.target.files[0];
        
            //just pass the fileObj as parameter
            ExcelRenderer(fileObj, (err, resp) => {
              if(err){
                console.log(err);            
              }
              else{
                this.setState({
                  cols: resp.cols,
                  rows: resp.rows
                },()=>{
                    this.state.rows.forEach((row,i)=>{
                        if(i){
                           const newObj = {
                               date: row[0],
                               eventTitle: row[1],
                               name: row[2],
                               email: row[3],
                               phone: row[4],
                               affiliateId: row[5]
                           }
                           obj.push(newObj);
                           
                        }
                    })
                    
                    console.log(obj)
                    this.setState({...this.state, obj: obj})
                });
              }
            });               
        
          }


        return(
            <div>
            <h1>Seed Leads</h1>
                <input type="file" onChange={fileHandler.bind(this)} style={{"padding":"10px"}} />
                <p></p>
                <CustomButton onClick={this.handleClick}>Seed Leads</CustomButton>
                <div>
                    <OutTable data={this.state.rows} columns={this.state.cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" />
                </div>
            </div>
           
        )
    }
}

export default SeedLeads