import React from "react";
import ReactExport from "react-export-excel";
import './export-to-excel.styles.scss';
import downloadImg from '../../assets/download.png';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExportToExcel extends React.Component {
    render() {
        const {data, fieldToDisplay, sheetName} = this.props
        return (
            <ExcelFile filename='Eliteone Sales' element={<img alt='download' src={downloadImg} style={{width: '30px', height:'30px', cursor: 'pointer'}} />}>
                <ExcelSheet data={data} name={sheetName}>
                {Object.keys(fieldToDisplay).map((key, i) =>
                        <ExcelColumn key={`${key}${i}`} label={key} value={key}/>
                    )
                }
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export default ExportToExcel