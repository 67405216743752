import React, { useState, useEffect } from "react";

import { createStructuredSelector } from "reselect";
import {
  selectUserUsers,
  selectCurrentUser,
  selectUserDownlines,
} from "../../Redux/user/user.selector";
import { connect } from "react-redux";
import UsersSalesList from "../../components/users-sales-list/users-sales-list.component";
import uuid from "react-uuid";

import "./users-sales-page.styles.scss";
import {
  selectSaleFlattenedSales,
  selectSaleTeamSales,
  selectSaleIsFetching,
  selectSaleSales,
} from "../../Redux/sale/sale.selectors";
import { numberWithCommas } from "../../util-functions/numer-formating";
import ItemsDropDown from "../../components/items-dropdown/items-dropdown.component";
import FormInput from "../../components/form-input/form-input.component";
import WithSpinner from "../../components/with-spinner/with-spinner.component";
import {
  loadAllData,
  forceLoadAllData,
} from "../../Redux/functions/reducer-functions";
import { getStartDateEndDateForYear } from "../../util-functions/date-format";
import { loadTeamSalesStart } from "../../Redux/sale/sale.actions";
import back from "../../assets/back-button.png";
import forward from "../../assets/forward-button.png";
import { selectProjectProjects } from "../../Redux/project/project.selector";
import ExportToExcel from "../../components/export-to-excel/export-to-excel.component";

const UsersSalesPage = ({
  currentUser,
  loadAllData,
  loadTeamSalesStart,
  isFetching,
  teamSales,
  downlines,
  forceLoadAllData,
  sales,
  projects,
}) => {
  const INITIAL_STATE = {
    showDropDown: { filteredRen: false },
    userFid: "",
    filteredRen: "",
    teamSalesDisplay: false,
    displayFilter: "personal",
    year: new Date().getFullYear(),
    startDate: new Date("2020-12-16"),
    endDate: new Date("2021-12-16"),
    status: "all",
    all: true,
    booked: false,
    converted: false,
    project: "",
    projectFid: "",
  };

  let fieldToDisplay = {
    saleId: "",
    date: "",
    projectName: "",
    unitNumber: "",
    unitSize: "",
    spaPrice: "",
    netPrice: "",
    buyerName: "",
    renName: "",
    renPercentage: "",
    netPriceForRen: "",
    commissionRate: "",
    commForRen: "",
    status: "",
    remark: "",
  };

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    let year = today.getFullYear();
    if (month === 12 && day >= 16) {
      year = year + 1;
    }
    const { startDate, endDate } = getStartDateEndDateForYear(year);
    loadTeamSalesStart(startDate, endDate);

    loadAllData();

    setState({
      ...state,
      startDate: startDate,
      endDate: endDate,
      year: year,
      filteredRen: currentUser.displayName,
      userFid: currentUser.id,
    });
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadTeamSalesStart(state.startDate, state.endDate);
    // eslint-disable-next-line
  }, [sales, state.startDate, state.endDate]);

  // useEffect(()=>{
  //     console.log(state)
  // },[state])

  const loadSales = () => {
    forceLoadAllData();
  };

  const toggleDropDown = (name) => {
    setState({
      ...state,
      showDropDown: {
        [name]: !state.showDropDown[name],
      },
    });
  };

  const clickUserItem = (item, name, key) => {
    setState({
      ...state,
      [name]: item[key],
      userFid: item.userFid,
      showDropDown: {
        [name]: !state.showDropDown[name],
      },
    });
  };

  const clickProjectItem = (item, name, key) => {
    setState({
      ...state,
      [name]: item[key],
      projectFid: item.id,
      showDropDown: {
        [name]: !state.showDropDown[name],
      },
    });
  };

  const addYear = () => {
    const { startDate, endDate } = getStartDateEndDateForYear(state.year + 1);
    setState({
      ...state,
      year: state.year + 1,
      startDate: startDate,
      endDate: endDate,
    });
  };

  const substractYear = () => {
    const { startDate, endDate } = getStartDateEndDateForYear(state.year - 1);
    setState({
      ...state,
      year: state.year - 1,
      startDate: startDate,
      endDate: endDate,
    });
  };

  const handleClickStatus = (e) => {
    const status = e.target.id;
    setState({
      ...state,
      status: status,
      all: false,
      booked: false,
      converted: false,
      [status.toLowerCase()]: true,
    });
  };

  let filteredSales = teamSales.filter(
    (sale) =>
      new Date(sale.date) >= state.startDate &&
      new Date(sale.date) <= state.endDate
  );

  if (teamSales) {
    //If Team Sales Display is true then activate downlines sales
    if (!state.teamSalesDisplay) {
      filteredSales = filteredSales.filter((sale) => {
        return sale.renId === state.userFid;
      });
    }
  }

  if (filteredSales) {
    switch (state.status) {
      case "booked":
        filteredSales = filteredSales.filter(
          (sale) =>
            sale.status.toLowerCase() === "booked" ||
            sale.status.toLowerCase() === "converted"
        );
        break;
      case "converted":
        filteredSales = filteredSales.filter(
          (sale) =>
            sale.status.toLowerCase() === "converted" ||
            sale.status.toLowerCase() === "done"
        );
        break;
      default:
    }

    if (state.projectFid) {
      filteredSales = filteredSales.filter(
        (sale) => sale.projectFid === state.projectFid
      );
    }

    filteredSales.sort((a, b) => b.saleId - a.saleId);

    let totalSales = filteredSales.reduce((accumulativeTotal, sale) => {
      return accumulativeTotal + parseFloat(sale.netPriceForRen);
    }, 0);
    let totalComm = filteredSales.reduce((accumulativeTotal, sale) => {
      return accumulativeTotal + sale.commForRen;
    }, 0);

    let numberOfSales = filteredSales.filter(
      (v, i) =>
        filteredSales.findIndex((sale) => sale.saleId === v.saleId) === i
    ).length;

    if (isFetching) {
      console.log("I am fetching data!");
      return (
        <div className="spc-table">
          <WithSpinner />
        </div>
      );
    }

    return (
      <div className="spc-table">
        <div className="uspc-row">
          <div className="utspc-item" onClick={substractYear}>
            <img width="25px" src={back} alt="back" />
          </div>
          <div className="utspc-date">{state.year}</div>
          <div className="utspc-item" onClick={addYear}>
            <img width="25px" src={forward} alt="forward" />
          </div>
        </div>
        <div className="uspc-row">
          <div
            className="uspc-status"
            style={{ color: state.all ? "green" : "black" }}
            id="all"
            onClick={handleClickStatus}
          >
            All
          </div>
          <div
            className="uspc-status"
            style={{ color: state.booked ? "green" : "black" }}
            id="booked"
            onClick={handleClickStatus}
          >
            Booked
          </div>
          <div
            className="uspc-status"
            style={{ color: state.converted ? "green" : "black" }}
            id="converted"
            onClick={handleClickStatus}
          >
            Converted
          </div>
        </div>
        <div className="uspc-row" style={{ height: "90px" }}>
          <div className={"uspc-ren"}>
            <FormInput
              className="scp-project"
              type="text"
              name={uuid()}
              id="project"
              value={state.project}
              onClick={() => toggleDropDown("project")}
              label="Project"
              readOnly
              required
            />
            {state.showDropDown["project"] ? (
              <ItemsDropDown
                name="project"
                type="text"
                toggleDropDown={toggleDropDown}
                items={
                  projects
                    ? [{ projectName: "All", projectFid: null }, ...projects]
                    : []
                } //Items to pass in
                keyToMap="projectName" //List out the values corresposing to the key
                clickItem={clickProjectItem} //list state up function
              />
            ) : null}
          </div>
        </div>
        <div className="uspc-row">
          <div className="uspc-icon">
            <ExportToExcel
              data={filteredSales}
              fieldToDisplay={fieldToDisplay}
              sheetName="sales"
            />
          </div>
          <div className="uspc-icon" onClick={loadSales}>
            <div>&#8635;</div>
          </div>
          <div
            className="uspc-team"
            onClick={() => {
              setState({ ...state, teamSalesDisplay: !state.teamSalesDisplay });
            }}
          >
            <div>
              {state.teamSalesDisplay
                ? "Change to Personal Sales"
                : "Change to Team Sales"}
            </div>
          </div>
          <div className="uspc-teamOrPersonal">
            <h2>{state.teamSalesDisplay ? "Team Sales" : "Personal Sales"}</h2>
          </div>
          <div
            className={state.teamSalesDisplay ? "uspc-ren-hidden" : "uspc-ren"}
          >
            <FormInput
              className="scp-project"
              type="text"
              name={uuid()}
              id="filteredRen"
              value={state.filteredRen}
              onClick={() => toggleDropDown("filteredRen")}
              label="REN"
              readOnly
              required
            />
            {state.showDropDown["filteredRen"] ? (
              <ItemsDropDown
                name="filteredRen"
                type="text"
                toggleDropDown={toggleDropDown}
                items={downlines.length ? downlines[0].downlines : []} //Items to pass in
                keyToMap="userName" //List out the values corresposing to the key
                clickItem={clickUserItem} //list state up function
              />
            ) : null}
          </div>
        </div>
        <div className="uspc-row-header">
          <div className="uspc-sum-header">Number of Sales</div>
          <div className="uspc-sum-header">Total Sales</div>
          <div className="uspc-sum-header">Total Commission</div>
        </div>
        <div className="uspc-row">
          <div className="uspc-sum">{numberOfSales}</div>
          <div className="uspc-sum">{numberWithCommas(totalSales)}</div>
          <div className="uspc-sum">{numberWithCommas(totalComm)}</div>
        </div>

        <UsersSalesList displayStyle="flattened" sales={filteredSales} />
      </div>
    );
  } else {
    return <div>No sales</div>;
  }
};

const mapStatesToProps = createStructuredSelector({
  flattenedSales: selectSaleFlattenedSales,
  users: selectUserUsers,
  currentUser: selectCurrentUser,
  isFetching: selectSaleIsFetching,
  downlines: selectUserDownlines,
  teamSales: selectSaleTeamSales,
  sales: selectSaleSales,
  projects: selectProjectProjects,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllData: () => dispatch(loadAllData()),
  forceLoadAllData: () => dispatch(forceLoadAllData()),
  loadTeamSalesStart: (dateStart, dateEnd) =>
    dispatch(loadTeamSalesStart(dateStart, dateEnd)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(UsersSalesPage);
