import React, { useState } from 'react';
import './campaign-create-page.styless.scss'
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import FormInput from '../../components/form-input/form-input.component';
import {dateFormat} from '../../util-functions/date-format';
import CustomButton from '../../components/custom-button/custom-button.component';
import { createCampaign } from '../../Redux/campaign/campaign.actions';
import NumberFormat from 'react-number-format';
import ItemsDropDown from '../../components/items-dropdown/items-dropdown.component';
import { selectProjectProjects } from '../../Redux/project/project.selector';


const CampaignCreatePage = ({createCampaign, projects}) => {
    const INITIAL_STATE = {
        description: '',
        projects: [],
        startDate: dateFormat(new Date()),
        endDate: dateFormat(new Date()),
        conversionDate: dateFormat(new Date()),
        salesValue: '',
        showDropDown: {project: false}
    }

    const [state, setState] = useState(INITIAL_STATE);

    const handleChange = (event) => {
        const {id, value} = event.target;
        setState({
            ...state,
            [id]: value,
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(!state.projects.length){
            alert('Projects cannot be empty');
            return null;
        }
        setState(INITIAL_STATE);
        const {showDropDown, ...others} = state;
        createCampaign(others);
    }

    const showProjects = (
            state.projects.map(project=>
                <div className='ccpc-projects' key={uuid()} onClick={()=>{deleteProject(project)}}>{project.projectName} &times;</div>
    ))

    const deleteProject = (project) => {
        const projects = state.projects.filter(doc => doc.id !== project.id)
        setState({...state, projects: projects})
    }

    const toggleDropDown = (name) => {
        setState({
            ...state,
            showDropDown: {...state.showDropDown,
                         [name]: !state.showDropDown[name]
                        }
        })
    }

    const clickProjectItem = (item, name, key) => {
        setState({
            ...state,
            projects: [...state.projects, {projectName: item.projectName, projectFid: item.id}],
            showDropDown: {...state.showDropDown, [name]: !state.showDropDown[name]}
        })
    }
    
    return(
        <div className='ccpc-table'>
            <div className='ccpc-row-0'>
                <div className='ccpc-item'><h2>Campaign Create</h2></div>
            </div>
            <form className='ccpc-form' onSubmit={handleSubmit}>
            <div className='ccpc-row-1'>
                <FormInput
                    type='text'
                    name={uuid()}
                    id='description'
                    value={state.description}
                    onChange={handleChange}
                    label='Description'
                    required
                />
            </div>
            <div className='ccpc-row-1'>
                {showProjects}
            </div>
            <div className='ccpc-row-1'>
                <div className='ccpc-add-project' onClick={()=>{toggleDropDown('project')}}>Add projects</div>
                {state.showDropDown['project'] ?
                    <ItemsDropDown
                        name='project'
                        type='text'
                        toggleDropDown = {toggleDropDown}
                        items = {projects} //Items to pass in
                        keyToMap = 'projectName' //List out the values corresposing to the key
                        clickItem = {clickProjectItem} //list state up function
                    /> : null
                }
            </div>
            <div className='ccpc-row-1'>
                <FormInput
                    type='date'
                    name={uuid()}
                    id='startDate'
                    value={state.startDate}
                    onChange={handleChange}
                    label='Start Date'
                    required
                />
            </div>
            <div className='ccpc-row-1'>
                <FormInput
                    type='date'
                    name={uuid()}
                    id='endDate'
                    value={state.endDate}
                    onChange={handleChange}
                    label='End Date'
                    required
                />
            </div>
            <div className='ccpc-row-1'>
                <FormInput
                    type='date'
                    name={uuid()}
                    id='conversionDate'
                    value={state.conversionDate}
                    onChange={handleChange}
                    label='Conversion Date'
                    required
                />
            </div>
            <div className='ccpc-row-1'>
                <NumberFormat
                    customInput={FormInput}
                    type='text'
                    name={uuid()}
                    id='salesValue'
                    value={state.salesValue}
                    label='Sales Value'
                    required
                    thousandSeparator={true}
                    onValueChange={(values)=>{setState({...state, salesValue: values.value})}}
                />
            </div>
            <div className='ccpc-row-1'>
                <CustomButton>Create Campaign</CustomButton>
            </div>
            </form>
        </div>
    )
}    

const mapStatesToProps = createStructuredSelector({
    projects: selectProjectProjects,
});
  
const mapDispatchToProps = dispatch => ({
    createCampaign: (campaign) => dispatch(createCampaign(campaign)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(CampaignCreatePage);
