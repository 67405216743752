import React, { useState } from "react";
import "./training-create-page.styles.scss";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectTrainingTrainings } from "../../Redux/training/training.selectors";
import FormInput from "../form-input/form-input.component";
import firebase from "../../firebase/firebase.utils";
import CustomButton from "../custom-button/custom-button.component";
import { selectCurrentUser } from "../../Redux/user/user.selector";
import {
  createTraining,
  deleteTraining,
  loadTrainings,
  updateTraining,
} from "../../Redux/training/training.actions";

const TrainingCreatePage = ({
  onClose,
  currentUser,
  createTraining,
  currentTraining,
  updateTraining,
  deleteTraining,
}) => {
  const INITIAL_STATE = {
    createdBy: currentUser.id,
    trainingId: "",
    id: "",
    createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
    description: "",
    author: "",
    tag: [],
    category: "",
    language: "",
    videoUrl: "",
    imageUrl: "",
    documentUrl: "",
    seen: [],
    liked: [],
  };

  const [formData, setFormData] = useState(
    currentTraining ? currentTraining : INITIAL_STATE
  );

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
    if (currentTraining) {
      updateTraining(formData);
    } else {
      createTraining(formData);
    }
    setFormData(INITIAL_STATE);
    onClose();
  };

  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      deleteTraining(currentTraining);
      setFormData(INITIAL_STATE);
    }
  };

  return (
    <div className="tcpc-table">
      <div className="tcpc-row-1 tcpc-row-border">
        <div className="tcpc-button" onClick={onClose}>
          &#10006;
        </div>
        <div className="tcpc-item" onClick={onClose}>
          Create Training Post
        </div>
      </div>
      <div className="tcpc-row-1">
        <form className="tcpc-form" onSubmit={handleSubmit}>
          <div className="tcpc-form-input">
            <FormInput
              type="text"
              name="description"
              id="description"
              value={formData.description}
              onChange={handleChange}
              label="Description"
              required
            />
          </div>
          <div className="tcpc-form-input">
            <FormInput
              type="text"
              name="author"
              id="author"
              value={formData.author}
              onChange={handleChange}
              label="Author"
              required
            />
          </div>
          <div className="tcpc-form-input">
            <FormInput
              type="text"
              name="category"
              id="category"
              value={formData.category}
              onChange={handleChange}
              label="Category"
              required
            />
          </div>
          <div className="tcpc-form-input">
            <FormInput
              type="text"
              name="documentUrl"
              id="documentUrl"
              value={formData.documentUrl}
              onChange={handleChange}
              label="Document Url / Video Url"
              required
            />
          </div>
          <div className="tcpc-form-input">
            <FormInput
              type="text"
              name="imageUrl"
              id="imageUrl"
              value={formData.imageUrl}
              onChange={handleChange}
              label="Image Url"
              required
            />
          </div>
          <div className="tcpc-form-input">
            <FormInput
              type="text"
              name="language"
              id="language"
              value={formData.language}
              onChange={handleChange}
              label="Language"
              required
            />
          </div>
          <CustomButton>{currentTraining ? "Update" : "Create"}</CustomButton>
          {currentTraining ? (
            <div className="tpc-delete" onClick={handleDelete}>
              Delete
            </div>
          ) : null}
        </form>
      </div>
    </div>
  );
};

const mapStatesToProps = createStructuredSelector({
  trainings: selectTrainingTrainings,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  createTraining: (trainingData) => dispatch(createTraining(trainingData)),
  loadTraining: () => dispatch(loadTrainings()),
  updateTraining: (trainingData) => dispatch(updateTraining(trainingData)),
  deleteTraining: (training) => dispatch(deleteTraining(training)),
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(TrainingCreatePage);
