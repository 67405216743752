import React from 'react';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import {createStructuredSelector} from 'reselect';
import { connect } from 'react-redux';

import { SignUpContainer, SignUpTitle } from './sign-up.styles';
import { signUpExistingUser } from '../../Redux/user/user.actions';
// import uuid from  'react-uuid';

class SignUp extends React.Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      secretCode: ''
    };
  }

  handleSubmit = async event => {
    event.preventDefault();

    const { email, password, confirmPassword } = this.state;
    const {signUpExistingUser} = this.props;

    if (password !== confirmPassword) {
      alert("passwords don't match");
      return;
    }

    if (this.state.secretCode !== 'eliteone123'){
      alert("Opps! Secret Code is not correct.");
      return;
    }

    try {
      signUpExistingUser({email, password});

      this.setState({
        displayName: '',
        email: '',
        password: '',
        confirmPassword: ''
      });
    } catch (error) {
      console.error(error);
      alert(error.message)
    }
  };

  handleChange = event => {
    const { id, value } = event.target;

    this.setState({...this.state, [id]: value });
  };

  render() {
    const { email, password, confirmPassword } = this.state;
    return (
      <SignUpContainer>
        <SignUpTitle>Welcome back.</SignUpTitle>
        <span>Sign Up with your existing email.</span>
        <form className='sign-up-form' onSubmit={this.handleSubmit}>
          <FormInput
            type='email'
            name='email'
            id='email'
            value={email}
            onChange={this.handleChange}
            label='Email'
            required
          />
          <FormInput
            type='password'
            name='password'
            id='password'
            value={password}
            onChange={this.handleChange}
            label='Password'
            required
          />
          <FormInput
            type='password'
            name='confirmPassword'
            id='confirmPassword'
            value={confirmPassword}
            onChange={this.handleChange}
            label='Confirm Password'
            required
          />
          <FormInput
            type='text'
            name='secretCode'
            id='secretCode'
            value={this.state.secretCode}
            onChange={this.handleChange}
            label='Secret Code'
            required
          />
          <CustomButton type='submit'>SIGN UP</CustomButton>
        </form>
      </SignUpContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
    
 })
 
const mapDispatchToProps = dispatch => ({
    signUpExistingUser: (userData) => dispatch(signUpExistingUser(userData))
});
 
 export default connect(mapStateToProps, mapDispatchToProps)(SignUp)