import { ParameterActionTypes } from './parameter.types';

const INITIAL_STATE = {
    numberOfSales: 1,
    numberOfCommStructures: 1,
    numberOfProjects: 2,
};

const parameterReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case ParameterActionTypes.ADD_PARAMETER_SALE:
            return{
                ...state,
                numberOfSales: action.payload,
            }
        case ParameterActionTypes.ADD_PARAMETER_COMMISSION_STRUCTURE:
            return{
                ...state,
                numberOfCommStructures: action.payload,
            }
        case ParameterActionTypes.ADD_PARAMETER_PROJECT:
            return{
                ...state,
                numberOfProjects: action.payload,
            }
        case ParameterActionTypes.ADD_PARAMETER_PROJECT_AND_CS:
            return{
                ...state,
                numberOfProjects: action.payload.numberOfProjects,
                numberOfCommStructures: action.payload.numberOfCommStructures,
            }
        case ParameterActionTypes.FETCH_PARAMETERS_SUCCESS:
            return{
                ...state,
                ...action.payload[0],
            }
        default:
            return state
        }
    }

export default parameterReducer