import { UserActionTypes } from './user.types';
import { addUser } from './user.utils';

const INITIAL_STATE = {
    currentUser: null,
    users: [],
    isFetching: false,
    isDownlinesFetching: false,
    editFormShow : false,
    isUsersLoaded: {
        isLoaded: false,
        lastLoaded: '2020-05-05',
        },
    isSigningIn: false,
    userAuth: null,
    downlines: '',
    isDownlinesLoaded: {
        isLoaded: false,
        lastLoaded: '2020-05-05',
        },
};

const userReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case UserActionTypes.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload,
                isSigningIn: false,
            };
        case UserActionTypes.SET_USER_AUTH:
            return{
                ...state,
                userAuth: action.payload,
            }
        case UserActionTypes.ADD_USER:
            return {
                ...state,
                users: addUser(state.users, action.payload)
            }
        case UserActionTypes.DELETE_USER_START:
            return{
                ...state,
            }
        case UserActionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.filter(user => user.id !== action.payload.id)
            }
        case UserActionTypes.FETCH_USERS_START:
            return{
                ...state,
                isFetching: true
            };
        case UserActionTypes.FETCH_USERS_SUCCESS:
            return{
                ...state,
                isFetching: false,
                users: action.payload,
                isUsersLoaded: {isLoaded: true, lastLoaded:new Date()},
            };
        case UserActionTypes.FETCH_USERS_FAILURE:
            return{
                ...state,
                isFetching: false,
                errorMessage: action.payload
            };
        case UserActionTypes.FETCH_USER_DOWNLINES_START:
            return{
                ...state,
                isDownlinesFetching: true,
            }
        case UserActionTypes.FETCH_USER_DOWNLINES_SUCCESS:
            return {
                ...state,
                downlines: action.payload,
                isDownlinesLoaded: {isLoaded: true, lastLoaded:new Date()},
                isDownlinesFetching: false,
            }
        case UserActionTypes.UPDATE_USER:
            return{
                ...state,
                users: action.payload
            }
        case UserActionTypes.TOGGLE_EDIT_FORM_SHOW:
            return {
                ...state,
                editFormShow: !state.editFormShow
            }
        case UserActionTypes.TOGGLE_IS_LOADED:
            return{
                ...state,
                isUsersLoaded: false,
            }
        case UserActionTypes.EMAIL_SIGN_IN_START:
            return{
                ...state,
                isSigningIn: true,
            }
        case UserActionTypes.SIGN_IN_SUCCESS:
            return{
                ...state,
                currentUser: action.payload,
                isSigningIn: false,
            }
        case UserActionTypes.UPDATE_PERSONAL_SALES_INDEX_START:
            return{
                ...state,
            }
        case UserActionTypes.UPDATE_PERSONAL_SALES_INDEX_SUCCESS:
            return{
                ...state,
                currentUser: action.payload
            }
        case UserActionTypes.USER_SIGN_OUT:
            return INITIAL_STATE
        default:
            return state;
    }
};

export default userReducer;

