import React from 'react';
import './leads-page.styles.scss';
import { ReactComponent as Refresh } from '../../assets/refresh.svg';

import {connect} from 'react-redux';

import {fetchLeadsStartAsync, updateLeadsFilterCondition} from '../../Redux/lead/lead.actions'
import { createStructuredSelector } from 'reselect';

import LeadsList from '../../components/lead-list/lead-list.component'
import { selectLeadisFetching, selectLeadisLoaded, selectLeadFilteredLeads } from '../../Redux/lead/lead.selector';
import { selectCurrentUser } from '../../Redux/user/user.selector';

class LeadsPage extends React.Component {
    componentDidMount(){
        const { isLoaded} = this.props
        if(!isLoaded){this.fetchLeadsAndResetFilter()};
        window.scroll(0,0)
    }

    fetchLeadsAndResetFilter = () =>{
        const {updateLeadsFilterCondition, fetchLeadsStartAsync}=this.props;
        updateLeadsFilterCondition('affiliateId');
        fetchLeadsStartAsync();
    }

    render(){
        const {isFetching, filteredLeads, updateLeadsFilterCondition, currentUser, fetchLeadsStartAsync}=this.props;
        if(!filteredLeads){
            return(
                <div className='leads-page'>
                    <div className='lp-filter'>
                        <div className='lp-icon-container' onClick={this.fetchLeadsAndResetFilter}><p><Refresh className='lp-icon' /></p></div>
                    </div>
                    <div className='lp-not-sign-in'>You have no leads</div>
                </div>
            )
        }

                return(
                    <div className='leads-page'>
                        <div className='lp-filter'>
    {(currentUser.isAdmin) ?    <div className='lp-all' onClick={()=>updateLeadsFilterCondition('all')}>Show All</div> : null}
                                <div className='lp-referral'onClick={()=>updateLeadsFilterCondition('affiliateId')}>My Own</div>
                                <div className='lp-icon-container' onClick={fetchLeadsStartAsync}><p><Refresh className='lp-icon' /></p></div>
                        </div>
                            {isFetching ? 'Loading...' : <LeadsList filteredLeads={filteredLeads} /> }
                        </div>
                )
    }
}

const mapStateToProps = createStructuredSelector({
    isFetching: selectLeadisFetching,
    isLoaded: selectLeadisLoaded,
    currentUser: selectCurrentUser,
    filteredLeads: selectLeadFilteredLeads
  });

const mapDispatchToProps = dispatch => ({
    fetchLeadsStartAsync: ()=> dispatch(fetchLeadsStartAsync()),
    updateLeadsFilterCondition: (filter)=> dispatch(updateLeadsFilterCondition(filter))
});

export default  connect(mapStateToProps, mapDispatchToProps)(LeadsPage)