import { LeadActionTypes } from './lead.types';
import {firestore} from '../../firebase/firebase.utils';
import {convertLeadsSnapshotToMap, updateLeadInArray} from './lead.utils';
import {store} from '../store';

export const editLead = lead => ({
    type: LeadActionTypes.EDIT_LEAD,
    payload: lead
});

export const fetchLeadsStart = () => ({
    type: LeadActionTypes.FETCH_LEADS_START,
    
});

export const fetchLeadsSuccess = (leadsMap) => ({
    type: LeadActionTypes.FETCH_LEADS_SUCCESS,
    payload: leadsMap
    
});

export const fetchLeadsFailure = (errorMessage) => ({
    type: LeadActionTypes.FETCH_LEADS_FAILURE,
    payload: errorMessage
    
});

export const fetchLeadsStartAsync = () => {
    return (dispatch, getState) => {
        const currentUser = getState().user.currentUser;
        if(!currentUser){
            console.log('No current user~');
            return null
        }

        if(!currentUser.affiliateId){
            console.log('No affiliateId setup');
            return null;
        }
        let collectionRef = [];
        if(currentUser.isAdmin){collectionRef = firestore.collection('leads')}
        else {collectionRef = firestore.collection('leads').where('affiliateId', '==', currentUser.affiliateId.toLowerCase());}
            
        dispatch(fetchLeadsStart());

        collectionRef
            .get()
            .then(snapshot => {
                const leadsMap = convertLeadsSnapshotToMap(snapshot);
                dispatch(fetchLeadsSuccess(leadsMap));
            })
            .catch(error => dispatch(fetchLeadsFailure(error.message)));
    };
};

export const toggleLeadFormHidden = (id) => ({
    type: LeadActionTypes.TOGGLE_LEAD_FORM_HIDDEN,
    payload: id
}   
);

export const changeLeadFormId = (id) => ({
    type: LeadActionTypes.CHANGE_LEAD_FORM_ID,
    payload: id
})

export const updateLead = (newLead, oldLeads)=>({
    type: LeadActionTypes.UPDATE_LEAD,
    payload: updateLeadInArray(newLead, oldLeads)
})

export const updateLeadsFilterCondition = (filter) => ({
    type: LeadActionTypes.UPDATE_LEADS_FILTER_CONDITION,
    payload: filter
})

export const setCurrentLead = (lead) => ({
    type: LeadActionTypes.SET_CURRENT_LEAD,
    payload: lead
})

export const updateLeadAction = (leadAction) => ({
    type: LeadActionTypes.UPDATE_LEAD_ACTION,
    payload: updateLeadActionInStore(leadAction)
})

export const toggleEditFormShow = () => ({
    type: LeadActionTypes.TOGGLE_EDIT_FORM_SHOW,
    
})

export const updateLeadActionInStore = (leadAction) => {
    console.log(leadAction);
    let leadActionsLogs = store.getState().lead.leadActionsLogs;
    leadActionsLogs.push(leadAction);
    console.log(leadActionsLogs);

    firestore.collection('leadActionsLogs').add(leadAction)
    .then(()=>{
        console.log('leadActionLog added into Firestore')
    }).catch(err=>{
        console.log(err.message)
    })

    return leadActionsLogs;
}

export const fetchLeadActionsLogs = (leadId) => {
    return dispatch => {
        let obj = [];
        const collectionRef = firestore.collection("leadActionsLogs");
        const queryRef = collectionRef.where('leadId','==',leadId);
        queryRef.get()
        .then((querySnapShot)=>{
            querySnapShot.forEach(doc=>{
                obj.push({
                    id: doc.id,
                    ...doc.data()
                }) 
            })
            console.log(obj);
            dispatch ({
                type: LeadActionTypes.FETCH_LEAD_ACTION_SUCCESS,
                payload: obj
            })
        }
        ).catch(err=>{
            console.log(err);
            dispatch ({
                type: LeadActionTypes.FETCH_LEAD_ACTION_FAILURE   
            })
        })
    }
}

export const deleteLead = (lead)=>{
    //redux thunk
    return (dispatch, getState) => {
        const docRef = firestore.collection('leads').doc(lead.id);
        docRef.delete().then(()=>{
            console.log('Lead Deleted from Firestore')
            const oldLeadsArray = getState().lead.leads;
            const newLeadsArray = oldLeadsArray.filter((doc)=> doc.id !==lead.id);
            dispatch({
                type:  LeadActionTypes.DELETE_LEAD_SUCCESS,
                payload: newLeadsArray,
            })
        }).catch(err=>{
            console.log('Delete Lead Failure')
            dispatch({
                type: LeadActionTypes.DELETE_LEAD_FAILURE,
            })
        })
    }
}