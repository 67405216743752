import React from 'react';
import './sales-list.styles.scss';
import uuid from 'react-uuid';

import SaleFlattened from '../sale/sale-flattened.component';
import SaleCombinedRens from '../sale/sale-combined-ren.component';
import { withRouter } from "react-router-dom";

class SalesList extends React.Component {

    handleClick = (sale) => {
        const {history} = this.props;
        history.push(`/sale-create-page/${sale.id}`)

    }

    render(){
        const {sales, displayStyle, maxNumberOfRens} = this.props;
        let fieldToDisplay = '';
        let previousSaleId = '';

        const isFirstLine = (saleId) => {
            if(saleId === previousSaleId){
                previousSaleId = saleId;
                return false
            }else{
                previousSaleId = saleId;
                return true
            }
        }

        const isLastLine = (saleId, i)=>{
            if(sales[i+1]){
                if(saleId !== sales[i+1].saleId){
                    return true
                }else{return false}
            }else{
                return true
            }
        }

        if(displayStyle === 'flattened'){
            //edit fieldToDisplay manually to add field into table
            fieldToDisplay = {
                saleId: 'Id',
                date: 'Date',
                projectName: 'Project',
                unitNumber: 'Unit No.',
                netPrice: 'Net Price',
                buyerName: 'Buyer',
                renName: 'REN Name',
                renPercentage: 'REN %',
                commForRen: 'Comm',
                status: 'Status'
                
            };
        }else{
            fieldToDisplay = {
                saleId: '',
                date: '',
                projectName: '',
                unitNumber: '',
                netPrice: '',
                buyerName: '',
            }
        };

        if (displayStyle === 'flattened'){
            return(
                <React.Fragment>
                {sales.map((sale,i)=>{
                    return(
                        <React.Fragment key={`sale${i}`}>
                            <SaleFlattened key={uuid()}
                                sale={sale} i={i} 
                                isFirstLine={isFirstLine(sale.saleId)}
                                isLastLine = {isLastLine(sale.saleId, i)}
                                fieldToDisplay={fieldToDisplay}  
                                handleClick={this.handleClick}/>
                        </React.Fragment>
                        
                    )
                })}
                </React.Fragment>
            )   
        } else {
            return(
                <React.Fragment>
                {sales.map((sale,i)=>{
                    return(
                        <div key={`sale${i}`} className='slc-row'>
                            <SaleCombinedRens key={uuid()} maxNumberOfRens={maxNumberOfRens} fieldToDisplay = {fieldToDisplay} sale={sale} i={i} handleClick={this.handleClick}/>
                        </div>
                        
                    )
                })}
                </React.Fragment>
            )
        }
    }
}

export default withRouter(SalesList);

