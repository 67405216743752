import React from 'react';
import './projects-list.styles.scss';
import Project from '../project/project.component';

class ProjectsList extends React.Component {

    render(){
        const {projects} = this.props;
        const projectsToDisplay = projects.sort((a,b)=> new Date(b.createdAt) - new Date(a.createdAt));
        
        let fieldToDisplay = ({
            projectId: 'Id',
            createdAt: 'Date',
            projectName: 'Name',
            location: 'Location',
            developer: 'Developer',
            commStructureId: 'Comm Structure Id',
        });

            return(
                <React.Fragment>
                {projectsToDisplay.map((project,i)=>{
                    return(
                        <Project key={`project${i}`} fieldToDisplay={fieldToDisplay} project={project} i={i}/>
                    )
                })}
                </React.Fragment>
            )   
        
    }
}
// new Date(b.createdAt.slice(0,10)) - new Date(a.createdAt.slice(0,10))
export default ProjectsList

