import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import CustomButton from "../../components/custom-button/custom-button.component";
import FormInput from "../../components/form-input/form-input.component";
import { loadTeamSalesAndSendTelegram } from "../../Redux/sale/sale.actions";
import { setCurrentUser, updateUser } from "../../Redux/user/user.actions";
import {
  selectCurrentUser,
  selectUserUsers,
} from "../../Redux/user/user.selector";
import { sendTelegramMessage } from "../../telegram/sendTelegramMessage";
import "./telegram-sign-up.styles.scss";

const TelegramSignUp = ({
  currentUser,
  users,
  updateUser,
  setCurrentUser,
  loadTeamSalesAndSendTelegram,
  history,
}) => {
  const [state, setState] = useState({ chatId: "", verificationCode: "" });

  const handleChange = (event) => {
    const { id, value } = event.target;
    setState({
      ...state,
      [id]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(state.verificationCode);
    console.log(state.chatId);
    if (parseInt(state.verificationCode) === parseInt(state.chatId) * 2) {
      const user = { ...currentUser, chatId: state.chatId };
      try {
        await updateUser(user, users);
        alert("Register successfully");

        await sendTelegramMessage(
          state.chatId,
          `Hi ${currentUser.displayName}. Thank you for Registering with DJC System`,
          currentUser.displayName
        );
        await sendTelegramMessage(
          81400808,
          `${currentUser.displayName} has registered with DJC System \nChatId: ${state.chatId}`,
          currentUser.displayName
        );
        const today = new Date();
        let year = today.getFullYear();
        const dateStart = `${year - 1}-12-15`;
        const dateEnd = `${year}-12-16`;
        loadTeamSalesAndSendTelegram(dateStart, dateEnd, state.chatId);
        await setCurrentUser(user);
        history.push("/");
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("Wrong verification code");
    }
  };

  const handleClickVerificationCode = async () => {
    await sendTelegramMessage(
      state.chatId,
      `Hi ${currentUser.displayName}. Your verification code is ${
        parseInt(state.chatId) * 2
      }`,
      currentUser.displayName
    );
    alert("Check your Telegram for the verification code.");
  };

  return (
    <div className="tsu-table">
      <div className="tsu-row">
        <a
          href="https://t.me/eliteonebot"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CustomButton>Send "Register Me" to DJC System</CustomButton>
        </a>
      </div>
      <div className="tsu-row">
        <div className="tsu-content">
          Click on the Button above. Type "Register Me" and send to DJC System.
          You will get the Chat Id. Type the Chat Id into the form below and
          click "Send Verification Code".
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="tsu-row">
          <div className="tsu-form">
            <FormInput
              type="number"
              name="chatId"
              id="chatId"
              value={state.chatId}
              onChange={handleChange}
              label="Chat Id"
              required
            />
          </div>
          <div onClick={handleClickVerificationCode} style={{cursor: "pointer", borderStyle:"solid", borderColor:"grey", height:"40px", padding:"8px" }}>
            Send verification code
          </div>
        </div>
        <div className="tsu-row">
          <div className="tsu-form">
            <FormInput
              type="number"
              name="verificationCode"
              id="verificationCode"
              value={state.verificationCode}
              onChange={handleChange}
              label="Verification Code"
              required
            />
          </div>
        </div>
        <div className="tsu-row">
          <CustomButton type="submit">Register Telegram Chat Id</CustomButton>
        </div>
      </form>
    </div>
  );
};

const mapStatesToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  users: selectUserUsers,
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (user, users) => dispatch(updateUser(user, users)),
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  loadTeamSalesAndSendTelegram: (dateStart, dateEnd, chatId) =>
    dispatch(loadTeamSalesAndSendTelegram(dateStart, dateEnd, chatId)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(TelegramSignUp);
