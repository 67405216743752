export const checkIsLoaded = (isLoaded) =>{
    const todayDate = new Date()
    const timePassedInHours = (todayDate - new Date(isLoaded.lastLoaded)) / 1000 / 60 / 60 ;
    if(isLoaded.isLoaded){
        
        if(timePassedInHours * 60 > 30){
            return false
        } else {
            return true
        }
    } else {
        return false
    }
    
}