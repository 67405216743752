import React from 'react';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';

import { setCurrentUser, emailSignInStart } from '../../Redux/user/user.actions'
import { connect } from 'react-redux'

import {
  SignInContainer,
  SignInTitle,
  ButtonsBarContainer
} from './sign-in.styles';
import { forgotPassword } from '../../Redux/user/user.utils';

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    };
  }

  handleSubmit = async event => {
    event.preventDefault();

    const { email, password } = this.state
    const { emailSignInStart } = this.props

    try {
      emailSignInStart({email, password});
      this.setState({ email: '', password: '' });
    } catch (error) {
      console.log(error);
      alert(error.message)
    }
  };

  handleChange = event => {
    const { value, name } = event.target;

    this.setState({...this.state, [name]: value });
  };

  handleForgotPassword = e => {
    const email = prompt("What is your email?");
    if(email){
      forgotPassword(email);
    }
  }

  render() {
    return (
      <SignInContainer>
        <SignInTitle>I already have an account</SignInTitle>
        <span>Sign in with your email and password</span>

        <form onSubmit={this.handleSubmit}>
          <FormInput
            name='email'
            type='email'
            handleChange={this.handleChange}
            value={this.state.email}
            label='email'
            required
          />
          <FormInput
            name='password'
            type='password'
            value={this.state.password}
            handleChange={this.handleChange}
            label='password'
            required
          />
          <ButtonsBarContainer>
            <CustomButton type='submit'> Sign in </CustomButton>
          </ButtonsBarContainer>
        </form>
        <div onClick={this.handleForgotPassword} style={{cursor: "pointer", margin: "10px"}}>Forgot Password</div>
      </SignInContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setCurrentUser: user => dispatch(setCurrentUser(user)),
  emailSignInStart: emailAndPassword => dispatch(emailSignInStart(emailAndPassword)),
});

export default connect(null, mapDispatchToProps)(SignIn)
