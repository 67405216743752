import { createSelector } from 'reselect';

const selectProject = state => state.project;

export const selectProjectProjects = createSelector(
  [selectProject],
  project => project.projects.sort((a,b) => b.projectId - a.projectId)
);

export const selectProjectIsLoaded =  createSelector(
  [selectProject],
  (project) => project.isProjectsLoaded
)