import { createSelector } from 'reselect';

const selectUser = state => state.user;

export const selectUserUsers = createSelector(
  [selectUser],
  user => user.users
);

export const selectUserisFetching = createSelector(
  [selectUser],
  user => user.isFetching
);

export const selectCurrentUser = createSelector(
  [selectUser],
  user => user.currentUser
);

export const selectUserUserAuth = createSelector(
  [selectUser],
  user => user.userAuth
);

export const selectUserEditFormShow = createSelector(
  [selectUser],
  user => user.editFormShow
)

export const selectUserIsLoaded = createSelector(
  [selectUser],
  user => user.isUsersLoaded,
)

export const selectUserIsSigningIn = createSelector(
  [selectUser],
  user => user.isSigningIn,
)

export const selectUserDownlines = createSelector(
  [selectUser],
  user => user.downlines,
)

export const selectUserIsDownlinesLoaded = createSelector(
  [selectUser],
  user => user.isDownlinesLoaded,
)

export const selectUserDownlineUsers = createSelector(
  [selectUser],
  user => {
    if(!user.downlines || !user.downlines.length){
      console.log('Downlines not loaded yet!')
      console.log(user.downlines)
      return [];
    }

    const downlines = user.downlines[0].downlines;
    const users = user.users;

    let downlinesProfiles = [];
    const findDownlineProfile = (downline) => {
      return users.find(user => user.id === downline.userFid);
    }

    downlines.forEach(downline =>{
      let downlineProfile = findDownlineProfile(downline);
      if(!downlineProfile){
        console.log(downline.userName + ' has no profile in database')
        return null
      }
      downlinesProfiles.push(downlineProfile);
    })

    return downlinesProfiles;
  }
)