import React from "react";
import "./comm-structure.styles.scss";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  fetchCommStructure,
  fetchCommStructuresStartAsync,
  updateCommStructureCommRate,
} from "../../Redux/commission/commission.actions";
import {
  selectCommissionCurrentCommStructure,
  selectCommissionIsFetching,
  selectCommissionIsLoaded,
} from "../../Redux/commission/commission.selectors";
import CommRate from "../comm-rate/comm-rate.component";
import { checkIsLoaded } from "../../util-functions/checkIsLoaded";
import FormInput from "../../components/form-input/form-input.component";
// import {dateFormat} from '../../util-functions/date-format';
import CustomButton from "../custom-button/custom-button.component";
import uuid from "react-uuid";
import { selectCurrentUser } from "../../Redux/user/user.selector";

class CommStructure extends React.Component {
  constructor() {
    super();

    this.state = {
      date: "",
      position: "",
      rate: "",
    };
  }

  componentDidMount() {
    const {
      match,
      fetchCommStructuresStartAsync,
      isCommStructuresLoaded,
      fetchCommStructure,
    } = this.props;
    const commStructureFid = match.params.id;
    if (!checkIsLoaded(isCommStructuresLoaded)) {
      fetchCommStructuresStartAsync();
    }
    fetchCommStructure(commStructureFid);
  }

  handleDeleteRate = (i) => {
    const { currentCommStructure, updateCommStructureCommRate } = this.props;
    const newCommRate = currentCommStructure.commRate.filter(
      (rate, index) => index !== i
    );
    const newCommStructure = { ...currentCommStructure, commRate: newCommRate };
    if (window.confirm("Are you sure you want to delete the rate")) {
      updateCommStructureCommRate(newCommRate, newCommStructure);
    }
  };

  handleChange = (event) => {
    const { id, value } = event.target;
    this.setState({
      ...this.state,
      [id]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { currentCommStructure, updateCommStructureCommRate } = this.props;
    const newCommRate = [
      ...currentCommStructure.commRate,
      {
        date: new Date(this.state.date),
        [this.state.position]: parseFloat(this.state.rate),
      },
    ];
    const newCommStructure = { ...currentCommStructure, commRate: newCommRate };
    if (window.confirm("Are you sure you want to add the rate")) {
      updateCommStructureCommRate(newCommRate, newCommStructure);
      this.setState({ date: "", position: "", rate: "" });
    }
  };

  render() {
    const { currentCommStructure, isFetching, currentUser } = this.props;
    console.log(currentCommStructure);
    
    if (isFetching) {
      return <div>Loading...</div>;
    } else {
      return (
        <div className="csc-table">
          <div className="csc-row-0">
            <h1>Commission Structure</h1>
          </div>
          {currentCommStructure &&
            currentCommStructure.commRate.map((rate, i) => {
              return (
                <div key={`${rate}${i}`}>
                  {currentUser.isProjectAdmin ? (
                    <CommRate
                      rate={rate}
                      i={i}
                      handleDeleteRate={this.handleDeleteRate}
                    />
                  ) : null}
                </div>
              );
            })}
          <div className="csc-row-0">
            <div className="csc-form">
              {currentUser.isProjectAdmin ? (
                <form onSubmit={this.handleSubmit}>
                  <div className="csc-date">
                    <FormInput
                      type="date"
                      name="date"
                      id="date"
                      value={this.state.date}
                      onChange={this.handleChange}
                      label="Date"
                      required
                    />
                  </div>
                  {/*Form Input for Project*/}
                  <div className="csc-position">
                    <FormInput
                      type="text"
                      name={uuid()}
                      id="position"
                      value={this.state.position}
                      onChange={this.handleChange}
                      label="Position"
                      required
                    />
                  </div>
                  <div className="csc-rate">
                    <FormInput
                      type="number"
                      name={uuid()}
                      id="rate"
                      value={this.state.rate}
                      onChange={this.handleChange}
                      label="Rate"
                      min="0"
                      max="100"
                      step="0.01"
                      required
                    />
                  </div>
                  <CustomButton type="submit">Add Commission Rate</CustomButton>
                </form>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStatesToProps = createStructuredSelector({
  currentCommStructure: selectCommissionCurrentCommStructure,
  isFetching: selectCommissionIsFetching,
  isCommStructuresLoaded: selectCommissionIsLoaded,
  currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCommStructure: (commStructureFid) =>
    dispatch(fetchCommStructure(commStructureFid)),
  updateCommStructureCommRate: (newRate, currentCommStructure) =>
    dispatch(updateCommStructureCommRate(newRate, currentCommStructure)),
  fetchCommStructuresStartAsync: () =>
    dispatch(fetchCommStructuresStartAsync()),
});

export default connect(mapStatesToProps, mapDispatchToProps)(CommStructure);
