import React from 'react';
import './sale.styless.scss';

import uuid from 'react-uuid';
import {numberWithCommas} from '../../util-functions/numer-formating';


class SaleCombinedRens extends React.Component{

    render(){
        let {sale, i, maxNumberOfRens, handleClick, fieldToDisplay} = this.props;
        //edit fieldToDisplay manually to add field into table

        let fieldToDisplayHeader = {...fieldToDisplay}

        for (let i = 0; i < maxNumberOfRens; i++) {
            fieldToDisplayHeader[`ren${i+1}Name`] = '';
            fieldToDisplayHeader[`ren${i+1}Percentage`] = '';
        }

        for (let i = 0; i < sale.numberOfRen; i++) {
                fieldToDisplay[`ren${i+1}Name`] = '';
                fieldToDisplay[`ren${i+1}Percentage`] = '';
        }
        
        return(
            <React.Fragment>
            {!i &&
            <div className='sc-row-header'>
                {Object.keys(fieldToDisplayHeader).map((key,i) => {
                    return(
                        <div key={uuid()} className={'sc-renName'}>{key}</div>
                    )
                })}
            </div>
            }
            <div className={sale['order'] ? 'sc-row' : 'sc-row-border'} onClick={()=>handleClick(sale)}>
            {Object.keys(fieldToDisplay).map((key,i) => {
                return(
                    <div key={uuid()} className={`sc-renName`}>
                    {(()=>{
                        switch(key){ // only display the first row for all the cases except default
                            case 'netPrice':
                                return <div>{!sale['order'] ? numberWithCommas(sale[key]) : null}</div>
                            case 'saleId':
                                return <div>{!sale['order'] ? sale[key] : null}</div>
                            case 'remark':
                                return <div>{!sale['order'] ? sale[key] : null}</div>
                            case 'date':
                                return <div>{!sale['order'] ? sale[key] : null}</div>
                            case 'projectName':
                                return <div>{!sale['order'] ? sale[key] : null}</div>
                            case 'unitNumber':
                                return <div>{!sale['order'] ? sale[key] : null}</div>
                            case 'buyerName':
                                return <div>{!sale['order'] ? sale[key] : null}</div>
                            default:
                                return <div>{sale[key]}</div>
                        }
                    })()}
                    </div>
                )
            })}
            </div>
            </React.Fragment>
        )
    }
}

export default SaleCombinedRens

