import { CommissionActionTypes } from './commission.types';
import { commStructures } from './commission.data';

const INITIAL_STATE = {
    commissions: [],
    commStructures: commStructures,
    isCommStructuresLoaded: {isLoaded: false, lastLoaded: '2020-05-01'},
    currentCommStructure: '',
    isFetching: false,

};

const commissionReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case CommissionActionTypes.LOAD_COMM:
            return{
                ...state,
            }
        case CommissionActionTypes.LOAD_COMM_SUCCESS:
            return{
                ...state,
                commission: action.payload,
            }
        case CommissionActionTypes.ADD_COMM_STRUCTURE_SUCCESS:
            return{
                ...state,
                commStructures: action.payload,
            }
        case CommissionActionTypes.FETCH_COMM_STRUCTURES_SUCCESS:
            return{
                ...state,
                commStructures: action.payload,
                isCommStructuresLoaded: {isLoaded: true, lastLoaded: new Date()},
            }
        case CommissionActionTypes.FETCH_COMM_STRUCTURE_SUCCESS:
            return{
                ...state,
                currentCommStructure: action.payload,
                isFetching: false,
            }
        case CommissionActionTypes.FETCHING_COMM_STRUCTURE:
            return{
                ...state,
                isFetching: true,
            }
        case CommissionActionTypes.UPDATE_COMM_RATE_SUCCESS:
            return {
                ...state,
                currentCommStructure: action.payload.currentCommStructure,
                commStructures: action.payload.newCommStructures,
            }
        default:
            return state;
    }
};

export default commissionReducer;

