import React from 'react';
import './lead-edit-form.styles.scss';
import {connect} from 'react-redux';
import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import {createStructuredSelector} from 'reselect';
import {updateLead, toggleEditFormShow} from '../../Redux/lead/lead.actions'
import {selectLeadLeads, selectLeadEditFormShow} from '../../Redux/lead/lead.selector'
import uuid from 'react-uuid';

class LeadEditForm extends React.Component {
    constructor(){
        super()

        this.state={
            value: ''
        }
    }

    componentDidMount(){
        const {lead, para} = this.props;
        this.setState({
            ...this.state,
            value : lead[para] ? lead[para] : ''
        })
    }

    handleChange = (event) => {
        const {id, value} = event.target;
        this.setState({
            ...this.state,
            [id]: value
        });
    }

    handleClick = () => {
        const {toggleEditFormShow} = this.props
        toggleEditFormShow();

    }

    handleSubmit = (event) => {
        event.preventDefault();
        let {lead, leads, updateLead, para, toggleEditFormShow} = this.props;

        //if para = isAdmin to it into a boolean
        let valueToBeSubmitted = '';
        if(para==='isAdmin'){
            if(this.state.value === 'true'){
                valueToBeSubmitted = true
            } else {valueToBeSubmitted = false}
        }else{valueToBeSubmitted = this.state.value}
            
        lead = ({
            ...lead,
            [para]: valueToBeSubmitted
        })
        updateLead(lead, leads)
        toggleEditFormShow(lead);
    }

    render(){
        const {lead, para} = this.props
        return(
            <div className='lef-table'>
                <div className='lef-row'>
                    <div className='lef-close' onClick={this.handleClick}>&#10006;</div>
                    <div className='lef-label'>{para}</div>
                    <div className='lef-value'>{lead[para]}</div>
                    <div className='lef-form'>
                        <form onSubmit={this.handleSubmit}>
                            <FormInput 
                                handleChange={this.handleChange} 
                                name={uuid()}
                                id='value'
                                type='text'
                                value={this.state.value}
                                label='edit'
                            />
                        <CustomButton type='submit'> Update </CustomButton>
                    </form>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = createStructuredSelector({
    leads: selectLeadLeads,
    editFormShow: selectLeadEditFormShow
  });

const mapDispatchToProps = dispatch => ({
    updateLead: (lead, leads) => dispatch(updateLead(lead, leads)),
    toggleEditFormShow: ()=> dispatch(toggleEditFormShow())
});

export default connect(mapStateToProps, mapDispatchToProps)(LeadEditForm)