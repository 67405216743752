import React from 'react';
import './projects-page.styles.scss'
import { ReactComponent as Refresh } from '../../assets/refresh.svg';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectProjectProjects, selectProjectIsLoaded } from '../../Redux/project/project.selector';
import ProjectList from '../../components/projects-list/projects-list.component';
import {fetchProjectsStartAsync} from '../../Redux/project/project.actions';
import { checkIsLoaded } from '../../util-functions/checkIsLoaded';
import { fetchCommStructuresStartAsync } from '../../Redux/commission/commission.actions';
import { selectCommissionIsLoaded } from '../../Redux/commission/commission.selectors';
import uuid from 'react-uuid';
import FormInput from '../../components/form-input/form-input.component';

class ProjectsPage extends React.Component {
    state = {
        search: '',
    }

    componentDidMount(){
        const {fetchProjectsStartAsync, isProjectsLoaded, fetchCommStructuresStartAsync, isCommStructuresLoaded} = this.props;
        if(!checkIsLoaded(isProjectsLoaded)){fetchProjectsStartAsync();}  
        if(!checkIsLoaded(isCommStructuresLoaded)){fetchCommStructuresStartAsync(); }
        window.scroll(0,0);
    }

    handleChange = (event) => {
        const {id, value} = event.target;
        this.setState({
            [id]: value
        })

    }

    render(){
        const {projects, fetchProjectsStartAsync} = this.props;

        let projectsToDisplay = projects.filter(project=>(
            project.projectName.toLowerCase().includes(this.state.search.toLowerCase())
        ))
        return(
            <div className='ppc-table'>
                <div className='spc-row'>
                    <div className='spc-icon' onClick={fetchProjectsStartAsync}><Refresh /></div>
                    <div className='spc-search'>
                    <FormInput 
                        type="text" 
                        name={uuid()}
                        id='search'
                        value={this.state.search} 
                        onChange={this.handleChange}
                        label="Search projects"
                    />
                    </div>
                </div>
           
                <ProjectList projects={projectsToDisplay} />
            </div>
        )
    }
}
    

const mapStatesToProps = createStructuredSelector({
    projects: selectProjectProjects,
    isProjectsLoaded: selectProjectIsLoaded,
    isCommStructuresLoaded: selectCommissionIsLoaded,

});
  
const mapDispatchToProps = dispatch => ({
    fetchProjectsStartAsync: () => dispatch(fetchProjectsStartAsync()),
    fetchCommStructuresStartAsync: ()=> dispatch(fetchCommStructuresStartAsync()),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ProjectsPage);
