import {TrainingActionTypes} from './training.types';

export const loadTrainings = () => ({
    type: TrainingActionTypes.LOAD_TRAININGS_START,
})

export const createTraining = (trainingData) => ({
    type: TrainingActionTypes.CREATE_TRAINING_START,
    payload: trainingData,
})

export const updateTraining = (trainingData) => ({
    type: TrainingActionTypes.UPDATE_TRAINING_START,
    payload: trainingData,
})

export const deleteTraining = (training) => ({
    type: TrainingActionTypes.DELETE_TRAINING_START,
    payload: training,
})



