import { checkIsLoaded } from "../../util-functions/checkIsLoaded";

import { fetchCommStructuresStartAsync } from "../commission/commission.actions";
import { fetchProjectsStartAsync } from "../project/project.actions";
import { loadDataInSequenceStart } from "../sale/sale.actions";

export const convertSnapshotToMap = (snapshot) => {
  const transformedData = snapshot.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
  return transformedData;
};

export const loadAllData = () => {
  return (dispatch, getState) => {
    const { isSalesLoaded } = getState().sale;
    const { isProjectsLoaded } = getState().project;
    const { isCommStructuresLoaded } = getState().commission;
    if (!checkIsLoaded(isProjectsLoaded)) {
      dispatch(fetchProjectsStartAsync());
    }
    if (!checkIsLoaded(isCommStructuresLoaded)) {
      dispatch(fetchCommStructuresStartAsync());
    }
    if (!checkIsLoaded(isSalesLoaded)) {
      dispatch(loadDataInSequenceStart());
    }
  };
};

export const forceLoadAllData = () => {
  return (dispatch) => {
    dispatch(fetchProjectsStartAsync());
    dispatch(fetchCommStructuresStartAsync());
    dispatch(loadDataInSequenceStart());
  };
};
