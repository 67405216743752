export const CommissionActionTypes = {
    LOAD_COMMS: 'LOAD_COMMISIONS',
    LOAD_COMMS_SUCCESS: 'LOAD_COMMS_SUCCESS',
    ADD_COMM: 'ADD_COMM',
    ADD_COMM_STRUCTURE_SUCCESS: 'ADD_COMM_STRUCTURE_SUCCESS',
    UPDATE_COMM: 'UPDATE_COMM',
    DELETE_COMM: 'DELETE_COMM',
    FETCH_COMM_STRUCTURES_SUCCESS: 'FETCH_COMM_STRUCTURES_SUCCESS',
    FETCHING_COMM_STRUCTURE: 'FETCHING_COMM_STRUCTURE',
    FETCH_COMM_STRUCTURE_SUCCESS: 'FETCH_COMM_STRUCTURE_SUCCESS',
    DELETE_COMM_RATE_SUCCESS: 'DELETE_COMM_RATE_SUCCESS',
    UPDATE_COMM_RATE_SUCCESS: 'UPDATE_COMM_RATE_SUCCESS',
}