import React from 'react';
import './lead-form.scss';
import FormInput from '../form-input/form-input.component';
import {toggleLeadFormHidden, updateLead} from '../../Redux/lead/lead.actions';
import {connect} from 'react-redux';
import CustomButton from '../custom-button/custom-button.component';
import { createStructuredSelector } from 'reselect';
import { selectLeadLeads } from '../../Redux/lead/lead.selector';
import uuid from 'react-uuid';

class LeadForm extends React.Component {
    constructor(){
        super();

        this.state = {
            remark: ''
        }
    }

    handleChange = (event)=>{
        const {id, value} = event.target
        this.setState({ 
            ...this.state,
            [id]: value });
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        const {lead, leads, updateLead, toggleLeadFormHidden} = this.props;
        const newLead = {
            ...lead,
            remark: this.state.remark
        }
        updateLead(newLead, leads);
        toggleLeadFormHidden();

    }

    render(){
        const {lead, toggleLeadFormHidden} = this.props;
        const {date, eventTitle, name, email, phone, remark} = lead;
        return(

            <div className='lead-form-container'>
                <div className='lf-close' onClick={toggleLeadFormHidden}>&#10006;</div>
                <div className='lf-date'>{date}</div>
                <div className='lf-event'>{eventTitle}</div>
                <div className='lf-name'>{name}</div>
                <div className='lf-email'>{email}</div>
                <div className='lf-phone'>{phone}</div>
    {(remark) ? <div className='lf-phone'>{remark}</div> : null}
                <div className='lf-remark'>
                    <form onSubmit={this.handleSubmit}>
                        <FormInput 
                            handleChange={this.handleChange} 
                            name={uuid()}
                            id='remark'
                            type='text'
                            value={this.state.remark}
                            label='remark'
                        />
                        <CustomButton type='submit'> Save </CustomButton>
                    </form>
                    
                </div>
            
            </div>

        )
    }
}

const mapStateToProps = createStructuredSelector({
    leads: selectLeadLeads
});

const mapDispatchToProps = dispatch => ({
    toggleLeadFormHidden: () => dispatch(toggleLeadFormHidden()),
    updateLead: (lead, leads) => dispatch(updateLead(lead, leads))
  });

export default connect(mapStateToProps, mapDispatchToProps)(LeadForm) 
