import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { auth } from "../../firebase/firebase.utils";
import {
  selectCurrentUser,
  selectUserUserAuth,
} from "../../Redux/user/user.selector";
import { withRouter } from "react-router-dom";
import { setCurrentUser, signOut } from "../../Redux/user/user.actions";
import logo from "../../assets/Eliteone-Logo.png";
// import back from '../../assets/back.png'
import "./header.styles.scss";
import { Link } from "react-router-dom";

class Header extends React.Component {
  state = {
    displayDropDown: false,
    dropDownStyle: { display: "none", opacity: "0", height: "0" },
  };

  signOut = () => {
    const { signOut } = this.props;
    auth.signOut();
    signOut();
    this.props.setCurrentUser(null);
    this.props.history.push("/");
    window.location.reload(false);
    this.setState({
      ...this.state,
      displayDropDown: false,
      dropDownStyle: { display: "none", opacity: "0", height: "0" },
    });
  };

  clickLogo = () => {
    const { history } = this.props;
    history.push("/");
    this.setState({
      ...this.state,
      displayDropDown: false,
      dropDownStyle: { display: "none", opacity: "0", height: "0" },
    });
  };

  clickBack = () => {
    const { history, location } = this.props;
    if (location.pathname !== "/") {
      history.goBack();
    }
    this.setState({
      ...this.state,
      displayDropDown: false,
      dropDownStyle: { display: "none", opacity: "0", height: "0" },
    });
  };

  toggleDropdown = () => {
    console.log("Toggle clicked");
    if (this.state.displayDropDown) {
      this.setState({
        ...this.state,
        displayDropDown: !this.state.displayDropDown,
        dropDownStyle: { opacity: "0", height: "0" },
      });
      setTimeout(() => {
        this.setState({
          ...this.state,
          dropDownStyle: { display: "none", opacity: "0", height: "0" },
        });
      }, 500);
    } else {
      this.setState({
        ...this.state,
        displayDropDown: !this.state.displayDropDown,
        dropDownStyle: { display: "flex" },
      });
      setTimeout(() => {
        this.setState({
          ...this.state,
          dropDownStyle: { display: "flex", opacity: "1", height: "370px" },
        });
      }, 50);
    }
    console.log(this.state);
  };

  render() {
    const { currentUser } = this.props;
    return (
      <div className="header-container">
        <div className="logo-container">
          <img width="130px" alt="logo" src={logo} onClick={this.clickLogo} />
        </div>
        <React.Fragment>
          <div className="options-container">
            {currentUser ? (
              <React.Fragment>
                <Link className="option-link" to="/users-total-sales-page">
                  Total Sales by month
                </Link>
                <Link className="option-link" to="/users-sales-page">
                  Sales
                </Link>
               
                <div className="option-link" onClick={() => this.signOut()}>
                  SIGN OUT
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="option-link" onClick={() => this.signOut()}>
                  Home
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="options-container-always">
            <div className="option-link" onClick={this.toggleDropdown}>
              &#9776;
            </div>
          </div>
          <div
            className="options-container-mobile"
            style={this.state.dropDownStyle}
          >
            {currentUser ? (
              <React.Fragment>
                <Link
                  className="option-link-mobile"
                  to="/users-total-sales-page"
                  onClick={this.toggleDropdown}
                >
                  Total Sales by Month
                </Link>
                <Link
                  className="option-link-mobile"
                  to="/sales-page"
                  onClick={this.toggleDropdown}
                >
                  View All Sales
                </Link>
                <Link
                  className="option-link-mobile"
                  to="/users-sales-page"
                  onClick={this.toggleDropdown}
                >
                  Individual Sales
                </Link>
                <Link
                  className="option-link-mobile"
                  to="/sales-project-page"
                  onClick={this.toggleDropdown}
                >
                  Projects Sales
                </Link>
                <Link
                  className="option-link-mobile"
                  to="/campaigns-page"
                  onClick={this.toggleDropdown}
                >
                  Campaigns Sales
                </Link>
                <Link
                  className="option-link-mobile"
                  to="/leads"
                  onClick={this.toggleDropdown}
                >
                  My Leads
                </Link>
                <Link
                  className="option-link-mobile"
                  to="/projects-page"
                  onClick={this.toggleDropdown}
                >
                  Projects
                </Link>
                <Link
                  className="option-link-mobile"
                  to="/users"
                  onClick={this.toggleDropdown}
                >
                  Users
                </Link>
                <hr
                  style={{ borderBottom: "1px solid grey", width: "100%" }}
                ></hr>
                <Link
                  className="option-link-mobile"
                  to="/sale-create-page"
                  onClick={this.toggleDropdown}
                >
                  Create Sale
                </Link>
                <Link
                  to={{ pathname: "https://leads.djcsystem.com" }}
                  target="blank"
                  className="option-link-mobile"
                >
                  DJC Leads System
                </Link>
                <hr
                  style={{ borderBottom: "1px solid grey", width: "100%" }}
                ></hr>
                <div
                  className="option-link-mobile"
                  onClick={() => this.signOut()}
                >
                  <div style={{ color: "red" }}>SIGN OUT</div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div
                  className="option-link-mobile"
                  onClick={() => this.signOut()}
                >
                  Home
                </div>
                <hr
                  style={{ borderBottom: "1px solid grey", width: "100%" }}
                ></hr>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  userAuth: selectUserUserAuth,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
  signOut: () => dispatch(signOut()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
