import React, { useState, useEffect } from "react";
import "./sales-page.styles.scss";

import { connect } from "react-redux";

import SalesList from "../../components/sales-list/sales-list.component";
import { createStructuredSelector } from "reselect";
import {
  selectSaleFlattenedSales,
  selectSaleCombinedRenSales,
  selectSaleIsFetching,
  selectSaleSales,
} from "../../Redux/sale/sale.selectors";
import { selectUserUsers } from "../../Redux/user/user.selector";
import {
  loadAllData,
  forceLoadAllData,
} from "../../Redux/functions/reducer-functions";
import FormInput from "../../components/form-input/form-input.component";
import WithSpinner from "../../components/with-spinner/with-spinner.component";
import { getStartDateEndDateForYear } from "../../util-functions/date-format";
import uuid from "react-uuid";
import ExportToExcel from "../../components/export-to-excel/export-to-excel.component";
import { loadTeamSalesStart } from "../../Redux/sale/sale.actions";
import back from "../../assets/back-button.png";
import forward from "../../assets/forward-button.png";

const SalesPage = ({
  loadAllData,
  loadTeamSalesStart,
  flattenedSales,
  isFetching,
  forceLoadAllData,
  sales,
}) => {
  const INITIAL_STATE = {
    displayStyle: "flattened",
    isFetching: false,
    search: "",
    year: new Date().getFullYear(),
    startDate: "",
    endDate: "",
  };

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    loadAllData();
    window.scroll(0, 0);
    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    let year = today.getFullYear();
    if (month === 12 && day >= 16) {
      year = year + 1;
    }

    const { startDate, endDate } = getStartDateEndDateForYear(year);
    console.log(startDate, endDate);
    setState({ ...state, startDate: startDate, endDate: endDate, year: year });
    loadTeamSalesStart(startDate, endDate);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadTeamSalesStart(state.startDate, state.endDate);
    // eslint-disable-next-line
  }, [state.startDate, state.endDate, sales]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setState({
      ...state,
      [id]: value,
    });
  };

  const addYear = () => {
    let startDate = "";
    let endDate = "";
    startDate = getStartDateEndDateForYear(state.year + 1).startDate;
    endDate = getStartDateEndDateForYear(state.year + 1).endDate;
    setState({
      ...state,
      startDate: startDate,
      endDate: endDate,
      year: state.year + 1,
    });
  };

  const substractYear = () => {
    let startDate = "";
    let endDate = "";
    startDate = getStartDateEndDateForYear(state.year - 1).startDate;
    endDate = getStartDateEndDateForYear(state.year - 1).endDate;
    setState({
      ...state,
      startDate: startDate,
      endDate: endDate,
      year: state.year - 1,
    });
  };

  // const clickQuarter = (event) => {
  //   const id = event.currentTarget.getAttribute("id");
  //   let startDate = "";
  //   let endDate = "";
  //   switch (id) {
  //     case "first":
  //       startDate = state.year - 1 + "-12-16";
  //       endDate = state.year + "-03-31";
  //       break;
  //     case "second":
  //       startDate = state.year + "-04-01";
  //       endDate = state.year + "-07-01";
  //       break;
  //     case "third":
  //       startDate = state.year + "-07-01";
  //       endDate = state.year + "-11-01";
  //       break;
  //     case "fourth":
  //       startDate = state.year + "-11-01";
  //       endDate = state.year + "-12-16";
  //       break;
  //     default:
  //       return null;
  //   }
  //   setState({
  //     ...state,
  //     startDate: new Date(startDate),
  //     endDate: new Date(endDate),
  //   });
  // };

  let fieldToDisplay = {
    saleId: "",
    date: "",
    projectName: "",
    unitNumber: "",
    unitSize: "",
    spaPrice: "",
    netPrice: "",
    buyerName: "",
    renName: "",
    renPercentage: "",
    netPriceForRen: "",
    commissionRate: "",
    commForRen: "",
    status: "",
    remark: "",
  };

  let filteredSales = flattenedSales.filter(
    (sale) =>
      new Date(sale.date) >= state.startDate &&
      new Date(sale.date) <= state.endDate
  );
  filteredSales = filteredSales.filter((sale) => {
    if (sale.projectName && sale.unitNumber) {
      return (
        sale.projectName.toLowerCase().includes(state.search.toLowerCase()) ||
        sale.unitNumber.toLowerCase().includes(state.search.toLowerCase())
      )
    } else {
      return 0;
    }
  });

  if (isFetching) {
    console.log("I am Loading...!");
    return (
      <div className="spc-table">
        <WithSpinner />
      </div>
    );
  }

  return (
    <div className="spc-table">
      <div className="spc-row-top">
        <div className="utspc-item" onClick={substractYear}>
          <img width="25px" src={back} alt="back" />
        </div>
        <div className="utspc-date">{state.year}</div>
        <div className="utspc-item" onClick={addYear}>
          <img width="25px" src={forward} alt="forward" />
        </div>
      </div>
      {/* <div className="spc-row">
        <div className="spc-quarter" id={"first"} onClick={clickQuarter}>
          1 - 3 |&nbsp;{" "}
        </div>
        <div className="spc-quarter" id="second" onClick={clickQuarter}>
          4 - 6 |&nbsp;{" "}
        </div>
        <div className="spc-quarter" id="third" onClick={clickQuarter}>
          7 - 10 |&nbsp;{" "}
        </div>
        <div className="spc-quarter" id="fourth" onClick={clickQuarter}>
          11 - 12
        </div>
      </div> */}
      <div className="spc-row">
        <div className="spc-download">
          <ExportToExcel
            data={flattenedSales}
            fieldToDisplay={fieldToDisplay}
            sheetName="sales"
          />
        </div>
        <div className="spc-icon" onClick={forceLoadAllData}>
          &#8635;
        </div>
        <div className="spc-search">
          <FormInput
            type="text"
            name={uuid()}
            id="search"
            value={state.search}
            onChange={handleChange}
            label="Search Projects or Unit number"
          />
        </div>
      </div>
      {
        {
          flattened: (
            <SalesList
              displayStyle={state.displayStyle}
              sales={filteredSales}
            />
          ),
        }[state.displayStyle]
      }
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  flattenedSales: selectSaleFlattenedSales,
  combinedRenSales: selectSaleCombinedRenSales,
  users: selectUserUsers,
  isFetching: selectSaleIsFetching,
  sales: selectSaleSales,
});

const mapDispatchToProps = (dispatch) => ({
  loadAllData: () => dispatch(loadAllData()),
  forceLoadAllData: () => dispatch(forceLoadAllData()),
  loadTeamSalesStart: (dateStart, dateEnd) =>
    dispatch(loadTeamSalesStart(dateStart, dateEnd)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesPage);
