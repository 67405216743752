import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router'
import { history } from './Redux/root-reducer';

import { store, persistor } from './Redux/store';

import './index.css';
import App from './App';

ReactDOM.render(

  <Provider store={store}>
    <ConnectedRouter history={history}> 
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
    </ConnectedRouter>
  </Provider>
   
  ,document.getElementById('root')
);


