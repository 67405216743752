import { UserActionTypes } from './user.types';
import { updateUserInArray } from './user.utils'

export const setCurrentUser = user => {
return ({
    type: UserActionTypes.SET_CURRENT_USER,
    payload: user
})};

export const addUser = (user) => ({
    type: UserActionTypes.ADD_USER,
    payload: user
})

export const emailSignInStart = emailAndPassword => ({
    type: UserActionTypes.EMAIL_SIGN_IN_START,
    payload: emailAndPassword
});

export const signInSuccess = user => ({
    type: UserActionTypes.SIGN_IN_SUCCESS,
    payload: user
});

export const checkUserSession = () => ({
    type: UserActionTypes.CHECK_USER_SESSION
});

export const updateUser = (user, users) => ({
        type: UserActionTypes.UPDATE_USER,
        payload: updateUserInArray(user, users)
})

export const deleteUserStart = (user) => ({
    type: UserActionTypes.DELETE_USER_START,
    payload: user
})


export const deleteUserSuccess = (user) => ({
    type: UserActionTypes.DELETE_USER_SUCCESS,
    payload: user
})

export const fetchUsersStart = () => ({
    type: UserActionTypes.FETCH_USERS_START,
    
})

export const fetchUserDownlinesStart = () => ({
    type: UserActionTypes.FETCH_USER_DOWNLINES_START,
    
})

export const fetchUsersSuccess = (usersMap) => ({
    type: UserActionTypes.FETCH_USERS_SUCCESS,
    payload: usersMap
    
})

export const fetchUsersFailure = (errorMessage) => ({
    type: UserActionTypes.FETCH_USERS_FAILURE,
    payload: errorMessage
})

export const toggleEditFormShow = () =>({
    type: UserActionTypes.TOGGLE_EDIT_FORM_SHOW,
})

export const toggleIsLoaded = () => ({
    type: UserActionTypes.TOGGLE_IS_LOADED,
})

export const signOut = () => ({
    type: 'USER_LOGOUT',
})

export const updatePersonalSalesIndex = (sale) => ({
    type: UserActionTypes.UPDATE_PERSONAL_SALES_INDEX_START,
    payload: sale
})

export const setUserAuth = (userAuth) => ({
    type: UserActionTypes.SET_USER_AUTH,
    payload: userAuth,
})

export const signUpNewUser = (userInformation) => ({
    type: UserActionTypes.SIGN_UP_NEW_USER_START,
    payload: userInformation,
})

export const signUpExistingUser = (userData) => ({
    type: UserActionTypes.SIGN_UP_EXISTING_USER_START,
    payload: userData
})

export const signInAs = (userFid) => ({
    type: UserActionTypes.SIGN_IN_AS,
    payload: userFid
})