import { dateFormat } from "../../util-functions/date-format";
import { firestore } from "../../firebase/firebase.utils";
import { sendTelegramMessage } from "../../telegram/sendTelegramMessage";
import store from "../store";
import { convertDate } from "../../util-functions/convertDate";

export const convertSalesDocsToMap = (docs) => {
  const transformedSales = docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
      date: dateFormat(convertDate(doc.data().date)),
    };
  });
  return transformedSales;
};

export const getRen = (renId, users) => {
  const returnedUser = users.find((user) => user.id === renId);
  if (returnedUser) {
    return returnedUser;
  } else {
    return "";
  }
};

export const getProject = (id, projects) => {
  const returnedProject = projects.find((project) => project.id === id);
  if (returnedProject) {
    return returnedProject;
  } else {
    return "";
  }
};

export const getCommissionRate = (id, saleDate, commStructures) => {
  const returnedCommStructure = commStructures.find(
    (commStructure) => commStructure.id === id
  );
  if (!returnedCommStructure) {
    return "";
  }
  const commRatesArray = returnedCommStructure.commRate;

  let commRate = {};
  commRatesArray.forEach((commRateItem) => {
    const commRateDate = new Date(commRateItem.date);
    const saleDateConverted = new Date(saleDate);
    if (saleDateConverted >= commRateDate) {
      commRate = commRateItem;
    }
  });

  if (commRate) {
    return commRate.ren;
  } else return "";
};

export const sendSaleToTelegram = async (sale) => {
  //Send Sale Report to REN and all Uplines
  const currentUser = store.getState().user.currentUser;

  let renToDisplay = "";
  sale.rens.forEach((ren, i) => {
    renToDisplay =
      renToDisplay +
      "REN" +
      (i + 1) +
      ": " +
      ren.renName +
      " (" +
      ren.renPercentage +
      "%)\n";
  });

  const saleToDisplay =
    "saleId: " +
    sale.saleId +
    "\nDate: " +
    dateFormat(sale.date) +
    "\n" +
    renToDisplay +
    "Project: " +
    sale.projectName +
    "\nUnit No: " +
    sale.unitNumber +
    "\nUnit Size: " +
    sale.unitSize +
    "\nBuyer: " +
    sale.buyerName +
    "\nSPA Price: RM" +
    parseInt(sale.spaPrice).toLocaleString() +
    "\nNett Price: RM" +
    parseInt(sale.netPrice).toLocaleString() +
    "\nPackage: " +
    sale.package +
    "\nRemark: " +
    sale.remark +
    "\nTeam: Eliteone";

  const usersSnapshot = await firestore.collection("users").get();
  const users = usersSnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));

  const getAllUplines = async () => {
    const uplines = [];
    const findUser = async (userFid) => {
      const user = users.find((user) => user.id === userFid);
      if (user) {
        uplines.push(user);
        if (user.immediateUplineFid) {
          findUser(user.immediateUplineFid); //start self looping
        }
      } else {
        console.log("No User Found");
        return null;
      }
    };

    sale.rens.forEach((ren) => {
      findUser(ren.userFid);
    });

    return uplines;
  };

  let uplines = await getAllUplines();

  uplines = uplines.filter(
    (v, i) => uplines.findIndex((index) => index === v) === i
  ); //remove duplicates

  uplines.forEach((user) => {
    if (user.chatId) {
      sendTelegramMessage(user.chatId, saleToDisplay, currentUser.displayName);
      console.log("Telegram Message Sent to " + user.displayName);
    } else {
      console.log(user.displayName + " has no chatId");
    }
  });
};

// export const sendSaleToTelegramAsync = async (sale) => {
//   //Send Sale Report to REN and all Uplines
//   const currentUser = store.getState().user.currentUser;

//   let renToDisplay = "";
//   sale.rens.forEach((ren, i) => {
//     renToDisplay =
//       renToDisplay +
//       "REN" +
//       (i + 1) +
//       ": " +
//       ren.renName +
//       " (" +
//       ren.renPercentage +
//       "%)\n";
//   });

//   const saleToDisplay =
//     "saleId: " +
//     sale.saleId +
//     "\nDate: " +
//     dateFormat(sale.date) +
//     "\n" +
//     renToDisplay +
//     "Project: " +
//     sale.projectName +
//     "\nUnit No: " +
//     sale.unitNumber +
//     "\nUnit Size: " +
//     sale.unitSize +
//     "\nBuyer: " +
//     sale.buyerName +
//     "\nSPA Price: RM" +
//     parseInt(sale.spaPrice).toLocaleString() +
//     "\nNett Price: RM" +
//     parseInt(sale.netPrice).toLocaleString() +
//     "\nPackage: " +
//     sale.package +
//     "\nRemark: " +
//     sale.remark +
//     "\nTeam: Eliteone";

//   const getAllUplines = async () => {
//     uplines = [];
//     const findUser = async (userFid) => {
//       const userDoc = await firestore.collection("users").doc(userFid).get();
//       if (userDoc.exists) {
//         const user = { ...userDoc.data(), id: userDoc.id };
//         console.log(user);
//         uplines.push(user);
//         if (user.immediateUplineFid) {
//           findUser(user.immediateUplineFid); //self looping
//         }
//       } else {
//         console.log("No User Found");
//         return null;
//       }
//     };

//     sale.rens.forEach(async (ren) => {
//       findUser(ren.userFid);
//     });
//     console.log(uplines);
//     return uplines;
//   };

//   let uplines = await getAllUplines();
//   console.log(uplines);

//   uplines = uplines.filter(
//     (v, i) => uplines.findIndex((index) => index === v) === i
//   ); //remove duplicates

//   uplines.forEach((user) => {
//     if (user.chatId) {
//       sendTelegramMessage(user.chatId, saleToDisplay, currentUser.displayName);
//       console.log("Telegram Message Sent to " + user.displayName);
//     } else {
//       console.log(user.displayName + " has no chatId");
//     }
//   });
// };
