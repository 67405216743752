import React, {useState, useEffect} from 'react';
import '../users-total-sales-page/users-total-sales-page.styles.scss'

import { ReactComponent as Refresh } from '../../assets/refresh.svg';

import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectSaleFlattenedSales, selectSaleTeamSales, selectSaleTotalSalesAndCommForRens, selectSaleIsFetching, selectSaleTotalSalesAndCommForProjects } from '../../Redux/sale/sale.selectors';
import { selectUserUsers, selectCurrentUser, selectUserDownlines } from '../../Redux/user/user.selector';
import { loadTotalSalesAndCommForRens, loadTeamSalesStart, loadTotalSalesAndCommForProjects } from '../../Redux/sale/sale.actions';

import ProjectsTotalSalesList from '../../components/projects-total-sales-list/projects-total-sales-list.component';
import { loadAllData, forceLoadAllData } from '../../Redux/functions/reducer-functions';
import { numberWithCommas } from '../../util-functions/numer-formating';
import WithSpinner from '../../components/with-spinner/with-spinner.component';
import back from '../../assets/back-button.png';
import forward from '../../assets/forward-button.png';
import { month2String } from '../../util-functions/date-format';

const ProjectsSalesPage = ({forceLoadAllData, loadAllData, loadTotalSalesAndCommForRens, isFetching, teamSales, totalSalesAndCommForRens, loadTeamSalesStart, loadTotalSalesAndCommForProjects, totalSalesAndCommForProjects})=> {
    const INITIAL_STATE={
        startDate: '',
        endDate: '',
        month: 5,
        year: 2020,
        displayStyle: 'Table',
        showYearOnly: false,
        status: 'Booked',
        booked: true,
        converted: false,
    }

    const [state, setState] = useState(INITIAL_STATE);
    const [totalSales, setTotalSales] = useState();
    const [salesToDisplay, setSalesToDisplay] = useState([]);

    
    useEffect(()=>{
        loadAllData();
        window.scroll(0,0);

        const today = new Date();
        let month = (today.getMonth()) + 1
        let year = (today.getFullYear())
        const lastDay = lastday(year, month - 1);
        setState({...state, month: month, year: year, startDate: year+'-'+month2String(month)+'-01', endDate: year+'-'+month2String(month)+'-'+lastDay})
        console.log(state)
        // eslint-disable-next-line
    },[])

    useEffect(()=>{
        // eslint-disable-next-line
        loadTeamSalesStart(state.startDate, state.endDate);
        loadTotalSalesAndCommForRens(state.startDate, state.endDate, state.status);
        loadTotalSalesAndCommForProjects(state.startDate, state.endDate, state.status);
        // eslint-disable-next-line
    },[state.startDate, state.endDate, state.status])

    useEffect(()=>{
        let salesToDisplay = totalSalesAndCommForRens
        setSalesToDisplay(salesToDisplay)
        let totalSales = totalSalesAndCommForRens.reduce((acc, ren)=>{
            return acc + ren.totalSales;
        },0)
        setTotalSales(totalSales);
    },[totalSalesAndCommForRens, teamSales])
    
    const loadSales = () =>{
        forceLoadAllData()
    }

    const lastday = (y, m) => {
        const d = new Date(y, m + 1, 0).getDate();
        return d;
    }

    const addMonth = () =>{
        let month = state.month;
        let year = state.year;

        month = month + 1;
        if(month === 13){
            year = year + 1;
            month = 1;
        }
        const lastDay = lastday(year, month - 1);
        setState({...state,
            month: month,
            year: year,
            startDate: year+'-'+month2String(month)+'-01',
            endDate: year+'-'+month2String(month)+'-'+lastDay,
            showYearOnly: false,
        })
    }

    const substractMonth = () =>{
        let month = state.month;
        let year = state.year;

        month = month - 1;
        if(month === 0){
            year = year - 1;
            month = 12;
        }
        const lastDay = lastday(year, month - 1);
        setState({...state,
            month: month,
            year: year,
            startDate: year+'-'+month2String(month)+'-01',
            endDate: year+'-'+month2String(month)+'-'+lastDay,
            showYearOnly: false,
        })
    }

    const showTotalInYear = () =>{
        let startDate = (state.year - 1) + '-12-16';
        let endDate = state.year + '-12-15';
        setState({...state, startDate: startDate, endDate: endDate, showYearOnly: true});

    }
 
    if(isFetching){
        return( <div className='utspc-table'><WithSpinner/></div>)
    }

        return(
            <div className='utspc-table'>
                <div className='utspc-row-0'>
                    <div className='utspc-filter' onClick={()=>setState({...state, displayStyle: 'Table'})}>Table</div>
                    <div className='utspc-separator'> | </div>
                    <div className='utspc-filter' onClick={()=>setState({...state, displayStyle: 'Chart'})}>Chart</div>
                </div>
                <div className='utspc-row-0'>
                    <div className='utspc-filter-status' style={{color: state.booked ? 'green' : 'black'}} onClick={()=>setState({...state, status: 'Booked', booked: true, converted: false})}>Booked</div>
                    <div className='utspc-separator'> | </div>
                    <div className='utspc-filter-status' style={{color: state.converted ? 'green' : 'black'}} onClick={()=>setState({...state, status: 'Converted', booked: false, converted: true})}>Converted</div>
                </div>
            <div className='utspc-row-0'>
                <div className='utspc-total'>Total Sales: RM {numberWithCommas(totalSales)}</div>
            </div>
                <div className='utspc-row-0'>
                    <div className='utspc-item' onClick={loadSales}><Refresh /></div>
                    <div className='utspc-item' onClick={substractMonth}><img width='25px' src={back} alt='back' /></div>
                    <div className='utspc-date' style={{cursor: 'pointer'}} onClick={showTotalInYear}>{ !state.showYearOnly ? state.month +' | '+ state.year : state.year} </div>
                    <div className='utspc-item' onClick={addMonth}><img width='25px' src={forward} alt='forward' /></div>
                </div>
                    
                    <ProjectsTotalSalesList totalSalesAndCommForProjects={totalSalesAndCommForProjects} displayStyle={state.displayStyle}/>
                {!salesToDisplay.length ? 
                    <div className='utspc-row-0'><p>No sales for this month</p></div> : null
                }
            </div>
        )
}

const mapStatesToProps = createStructuredSelector({
    flattenedSales: selectSaleFlattenedSales,
    users: selectUserUsers,
    currentUser: selectCurrentUser,
    isFetching: selectSaleIsFetching,
    downlines: selectUserDownlines,
    teamSales: selectSaleTeamSales,
    totalSalesAndCommForRens: selectSaleTotalSalesAndCommForRens,
    totalSalesAndCommForProjects: selectSaleTotalSalesAndCommForProjects,
});
  
const mapDispatchToProps = dispatch => ({
    loadAllData: ()=>dispatch(loadAllData()),
    forceLoadAllData: ()=>dispatch(forceLoadAllData()),
    loadTotalSalesAndCommForRens: (startDate, endDate, status)=> dispatch(loadTotalSalesAndCommForRens(startDate, endDate, status)),
    loadTeamSalesStart: (dateStart, dateEnd)=> dispatch(loadTeamSalesStart(dateStart, dateEnd)),
    loadTotalSalesAndCommForProjects: (startDate, endDate, status) => dispatch(loadTotalSalesAndCommForProjects(startDate, endDate, status)),
});

export default connect(mapStatesToProps, mapDispatchToProps)(ProjectsSalesPage);
