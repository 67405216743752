import { SaleActionTypes } from "./sale.types";
import { firestore } from "../../firebase/firebase.utils";
import { updatePersonalSalesIndex } from "../user/user.actions";
import { getStartDateEndDateForQuarter } from "../../util-functions/date-format";

export const addNewSale = (sale) => ({
  type: SaleActionTypes.ADD_NEW_SALE,
  payload: sale,
});

export const updateSale = (sale, additionalOptions) => {
  return (dispatch, getState) => {
    console.log(sale);
    console.log(sale.id);
    const docRef = firestore.collection("sales").doc(sale.id);

    docRef
      .update({...sale})
      .then(() => {
        const oldSalesArray = getState().sale.sales;
        const newSalesArray = [...oldSalesArray];
        const elementIndex = newSalesArray.findIndex(
          (obj) => sale.id === obj.id
        );
        newSalesArray[elementIndex] = sale;
        console.log("Sale Updated in Firestore");
        dispatch({
          type: SaleActionTypes.UPDATE_SALE,
          payload: newSalesArray,
        });
        dispatch({
          type: SaleActionTypes.UPDATE_SALE_REPORT,
          payload: sale,
        });
        if (!additionalOptions) {
          dispatch(updatePersonalSalesIndex(sale));
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
};

export const deleteSale = (id) => {
  return (dispatch, getState) => {
    firestore
      .collection("sales")
      .doc(id)
      .delete()
      .then(() => {
        console.log("Sale deleted from firestore");
        const sales = getState().sale.sales;
        const newSalesArray = sales.filter((sale) => sale.id !== id);
        dispatch({
          type: SaleActionTypes.DELETE_SALE_SUCCESS,
          payload: newSalesArray,
        });
        const { startDate, endDate } = getStartDateEndDateForQuarter();
        dispatch(loadTeamSalesStart(startDate, endDate));
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const fetchSalesStart = () => ({
  type: SaleActionTypes.FETCH_SALES_START,
});

export const loadTeamSalesStart = (dateStart, dateEnd) => ({
  type: SaleActionTypes.LOAD_TEAM_SALES_START,
  payload: { dateStart, dateEnd },
});

export const loadDataInSequenceStart = () => ({
  type: SaleActionTypes.LOAD_DATA_IN_SEQUENCE_START,
});

export const loadTotalSalesAndCommForRens = (dateStart, dateEnd, status) => ({
  type: SaleActionTypes.LOAD_TOTAL_SALES_AND_COMM_FOR_RENS_START,
  payload: { dateStart, dateEnd, status },
});

export const loadTotalSalesAndCommForProjects = (
  dateStart,
  dateEnd,
  status
) => ({
  type: SaleActionTypes.LOAD_TOTAL_SALES_BY_PROJECTS_START,
  payload: { dateStart, dateEnd, status },
});

export const loadTeamSalesAndSendTelegram = (dateStart, dateEnd, chatId) =>({
  type: SaleActionTypes.LOAD_TEAM_SALES_AND_SEND_TELEGRAM,
  payload: {dateStart, dateEnd, chatId}
})

export const sendSalesReportToTelegram = (dateStart, dateEnd, chatId) => ({
  type: SaleActionTypes.SEND_SALES_REPORT_TO_TELEGRAM,
  payload: {dateStart, dateEnd, chatId}
})