import React from 'react';
import { createStructuredSelector } from 'reselect';
import { selectUserUsers } from '../../Redux/user/user.selector';
import { connect } from 'react-redux';

import './user-page.styles.scss';
import UserEdit from '../../components/user-edit/user-edit.component';

class UserEditPage extends React.Component {
    
    render(){
        const{match, users} = this.props;
        const {id} = match.params
        const user = users.find(user => user.id === id);
       
        return(
            <div className='user-page'>
                <UserEdit user={user}/>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    users: selectUserUsers
  });

export default connect(mapStateToProps)(UserEditPage)