import {CampaignActionTypes} from './campaign.types';

const INITIAL_STATE = {
   campaigns: [],
   campaignTotalSales: [],
};

const campaignReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case CampaignActionTypes.CREATE_CAMPAIGN:
            return{
                ...state,
            }
        case CampaignActionTypes.CREATE_CAMPAGIN_SUCCESS:
            return{
                ...state,
            }
        case CampaignActionTypes.LOAD_CAMPAIGNS_SUCCESS:
            return{
                ...state,
                campaigns: action.payload
            }
        case CampaignActionTypes.LOAD_CAMPAIGN_SALES_SUCCESS:
            return{
                ...state,
                campaignTotalSales: action.payload
            }
        default:
            return state
    }
}

export default campaignReducer