import {PhotoActionTypes} from './photo.types';

export const loadPhotos = (imageTag) => ({
    type: PhotoActionTypes.LOAD_PHOTOS_START,
    payload: {imageTag}
})

export const loadPhotosSuccess = (urls) => ({
    type: PhotoActionTypes.LOAD_PHOTOS_SUCCESS,
    payload: urls
})