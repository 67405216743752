import { CommissionActionTypes } from './commission.types';
import { firestore } from '../../firebase/firebase.utils';
import { convertSnapshotToMap } from '../functions/reducer-functions';
// import {addNumberOfCommStructures} from '../parameter/parameter.actions';
// import {convertCommissionsSnapshotToMap, updateCommissionInArray} from './commission.utils';

export const editCommission = commission => ({
    type: CommissionActionTypes.EDIT_COMM,
    payload: commission,
});

export const addCommStructure = commStructure => {
    return (dispatch, getState) => {
        const collectionRef = firestore.collection('commStructures')
        collectionRef.add(commStructure)
        .then(()=>{
            const oldCommStructureArray = getState().commission.commStructures;
            let newCommStructureArray = [...oldCommStructureArray];
            newCommStructureArray.push(commStructure);
            console.log(newCommStructureArray)
            console.log('New Comm Strcuture Added into Firestore~!')
            dispatch({
                type: CommissionActionTypes.ADD_COMM_STRUCTURE_SUCCESS,
                payload: newCommStructureArray,
            })
            // dispatch(addNumberOfCommStructures());
        })
    }
}

export const fetchCommStructuresStartAsync = () =>{
    return (dispatch) => {
        const collectionRef = firestore.collection('commStructures');

        collectionRef
            .get()
            .then(snapShot =>{
                const commStructureMap = convertSnapshotToMap(snapShot)
                console.log(commStructureMap);
                dispatch({
                    type: CommissionActionTypes.FETCH_COMM_STRUCTURES_SUCCESS,
                    payload: commStructureMap,

                })
            }).catch(err=>{
                console.log(err)
            })
    }
}

export const fetchCommStructure = (commStructureFid) => {
    return (dispatch, getState) => {

            const commStructures = getState().commission.commStructures;
            const currentCommStructure = commStructures.find(commStructure => commStructure.id === commStructureFid);
           
            dispatch({
                type: CommissionActionTypes.FETCH_COMM_STRUCTURE_SUCCESS,
                payload: currentCommStructure,
            })
    }
}


export const updateCommStructureCommRate = (newRate, newCommStructure) => {
    return async (dispatch, getState) => {
        const docRef = firestore.collection('commStructures').doc(newCommStructure.id);
        const commStructures = getState().commission.commStructures;
        let newCommStructures = [...commStructures];
        const index = commStructures.findIndex(commStructure => commStructure.id === newCommStructure.id);
        newCommStructures[index].commRate = newRate;
        
        docRef.set({
            ...newCommStructure,
            commRate: newRate,
        }).then(()=>{
            
            dispatch({
                type: CommissionActionTypes.UPDATE_COMM_RATE_SUCCESS,
                payload: {newCommStructures: newCommStructures, currentCommStructure: newCommStructure}
            })
        })
    }
}

