import React from 'react';
import '../users-sales-list/users-sales-list.styles.scss';
import uuid from 'react-uuid';

import SaleFlattened from '../sale/sale-flattened.component';
import SalesTotalChart from '../sales-total-chart/sales-total-chart.component';
import { withRouter } from "react-router-dom";

class UsersTotalSalesList extends React.Component {

    render(){
        const {totalSalesAndCommForRens, displayStyle} = this.props

        let salesToDisplay = [];
        totalSalesAndCommForRens.forEach((sale, i) => {
            salesToDisplay.push({
                ...sale,
                number: i + 1,
                renNameForTotalSales: sale.renName,
            })
        })

        let fieldToDisplay = {
            number: 'No.',
            renNameForTotalSales: 'Name',
            totalSales: 'Total Sales',
            totalComm: 'Total Comm',
        };

        let chartFieldToDisplay = {
            number: 'No.',
            renNameForTotalSales: 'Name',
            totalSales: 'Total Sales',
            barChart: ''
        }

        let barChartLengths = [];
        totalSalesAndCommForRens.forEach((sale, i)=>{
            barChartLengths[i] = Math.ceil(sale.totalSales / totalSalesAndCommForRens[0].totalSales * 30);
        })

        return(
                <React.Fragment>
                {salesToDisplay.map((item,i)=>{
                    return(
                        <div key={`sale${i}`} style={{cursor: 'unset'}} className='slc-row'>
                        {
                            {   'Table' : <SaleFlattened key={uuid()} sale={item} i={i} fieldToDisplay={fieldToDisplay} handleClick={this.handleClick}/> ,
                                'Chart' : <SalesTotalChart key={uuid()} sale ={item} n={i} chartFieldToDisplay={chartFieldToDisplay} barChartLengths={barChartLengths} handleClick={this.handleClick} />,
                        }[displayStyle]
                        }
                        </div>
                    )
                })}
                </React.Fragment>
            )   
    }
}

export default withRouter(UsersTotalSalesList);

