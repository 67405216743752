import React from "react";
import "./users-page.styles.scss";
import { checkIsLoaded } from "../../util-functions/checkIsLoaded";

import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

import UserList from "../../components/user-list/user-list.component";
import {
  selectUserisFetching,
  selectCurrentUser,
  selectUserUsers,
  selectUserIsLoaded,
  selectUserIsSigningIn,
  selectUserDownlineUsers,
  selectUserIsDownlinesLoaded,
} from "../../Redux/user/user.selector";
import {
  fetchUsersStart,
  fetchUserDownlinesStart,
} from "../../Redux/user/user.actions";
import FormInput from "../../components/form-input/form-input.component";
import uuid from "react-uuid";
import WithSpinner from "../../components/with-spinner/with-spinner.component";

class UsersPage extends React.Component {
  state = {
    search: "",
  };

  componentDidMount() {
    const { isLoaded } = this.props;
    const {
      fetchUsersStart,
      fetchUserDownlinesStart,
      isDownlinesLoaded,
    } = this.props;
    if (!checkIsLoaded(isLoaded)) {
      fetchUsersStart();
    }
    if (!checkIsLoaded(isDownlinesLoaded)) {
      fetchUserDownlinesStart();
    }
    window.scroll(0, 0);
  }

  handleChange = (event) => {
    const { id, value } = event.target;
    this.setState({
      ...this.state,
      [id]: value,
    });
  };

  render() {
    const {
      isFetching,
      currentUser,
      users,
      fetchUsersStart,
      isSigningIn,
      downlineUsers,
    } = this.props;
    let usersToDisplay = "";
    let filteredUsers = [];

    if (isSigningIn) {
      return (
        <div className="upc-table">
          Signing in...
          <WithSpinner />
        </div>
      );
    } else {
      if (currentUser) {
        if (currentUser.isAdmin) {
          usersToDisplay = users;
        } else {
          usersToDisplay = downlineUsers;
        }

        if (!downlineUsers || !downlineUsers.length) {
          usersToDisplay = [currentUser];
        }

        filteredUsers = usersToDisplay.filter((user) => {
          if (user.displayName) {
            return user.displayName
              .toLowerCase()
              .includes(this.state.search.toLowerCase());
          } else {
            return null;
          }
        });

        return (
          <div className="up-table">
            <div className="upc-header">
              <div className="upc-icon" onClick={fetchUsersStart}>
                <div>&#8635;</div>
              </div>
              <div className="spc-search">
                <FormInput
                  type="text"
                  name={uuid()}
                  id="search"
                  value={this.state.search}
                  onChange={this.handleChange}
                  label="Search users"
                />
              </div>
            </div>
            {isFetching ? (
              <div className="upc-loading">Loading...</div>
            ) : (
              <UserList users={filteredUsers} />
            )}
          </div>
        );
      } else {
        return <div className="upc-sign-in">Please sign in</div>;
      }
    }
  }
}

const mapStateToProps = createStructuredSelector({
  isFetching: selectUserisFetching,
  currentUser: selectCurrentUser,
  users: selectUserUsers,
  isLoaded: selectUserIsLoaded,
  isSigningIn: selectUserIsSigningIn,
  downlineUsers: selectUserDownlineUsers,
  isDownlinesLoaded: selectUserIsDownlinesLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsersStart: () => dispatch(fetchUsersStart()),
  fetchUserDownlinesStart: () => dispatch(fetchUserDownlinesStart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
