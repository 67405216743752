import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import "./sale-report.styles.scss";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  selectSaleSaleReport,
  selectSaleSales,
} from "../../Redux/sale/sale.selectors";
import { dateFormat } from "../../util-functions/date-format";
import { numberWithDecimals } from "../../util-functions/numer-formating";
import { firestore } from "../../firebase/firebase.utils";
import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import {
  selectCurrentUser,
  selectUserUsers,
} from "../../Redux/user/user.selector";
import EmailsChipsInput from "../../components/emails-chips-input/emails-chips-input.component";
import { withRouter } from "react-router-dom";
import UploadPhoto from "../../components/file-upload/file-upload.component";
import ListPhotos from "../../components/list-photos/list-photos.component";

const SaleReport = ({ saleReport, currentUser, users, match, sales }) => {
  const INITIAL_STATE = {
    date: dateFormat(new Date()),
    toEmails: "",
    message: "",
    fromEmail: "davechong@eliteone.com.my",
    replyTo: "",
    saleReportObj: "",
    ccEmails: [],
    paramsId: "",
    sale: "",
  };

  const [state, setState] = useState(INITIAL_STATE);

  useEffect(() => {
    const paramsId = match.params.id;
    if (paramsId) {
      // eslint-disable-next-line
      saleReport = sales.find((sale) => sale.id === paramsId);
    }
    console.log(paramsId);
    console.log(saleReport);

    if (saleReport) {
      const ccEmails = saleReport.rens.map((ren) => {
        let user = users.find((user) => user.id === ren.userFid);
        if (user) {
          if (user.email) {
            return user.email;
          }
          return null;
        } else {
          return null;
        }
      });

      console.log(ccEmails);

      const saleReportObj = (
        <React.Fragment>
          <div>Date: {dateFormat(saleReport.date)}</div>
          {saleReport.rens.map((ren, i) => {
            return (
              <div key={`REN${i + 1}`}>
                REN {i + 1}: {ren.renName} ({ren.renPercentage}%)
              </div>
            );
          })}
          <div>Project: {saleReport.projectName}</div>
          <div>Unit No: {saleReport.unitNumber}</div>
          <div>Size: {saleReport.unitSize} sf</div>
          <div>Buyer: {saleReport.buyerName}</div>
          <div>SPA Price: RM {numberWithDecimals(saleReport.spaPrice)}</div>
          <div>Nett Price: RM {numberWithDecimals(saleReport.netPrice)}</div>
          <div>Package: {saleReport.package}</div>
          <div>Remark: {saleReport.remark}</div>
          <div>Team: Eliteone</div>
        </React.Fragment>
      );

      setState({
        ...state,
        saleReportObj: saleReportObj,
        ccEmails: ccEmails,
        replyTo: currentUser.email,
        sale: saleReport,
      });
    }
  }, [saleReport]);

  const sendEmail = (e) => {
    e.preventDefault();
    const {
      date,
      toEmails,
      message,
      fromEmail,
      saleReportObj,
      ccEmails,
      replyTo,
    } = state;
    const saleReportEmail = ReactDOMServer.renderToStaticMarkup(saleReportObj);

    const emailToBeSent = {
      to: toEmails,
      from: fromEmail,
      cc: ccEmails,
      replyTo: replyTo,
      message: {
        subject:
          state.sale.projectName +
          " | " +
          state.sale.unitNumber +
          " | Sale Report Status Update ",
        html:
          saleReportEmail +
          `<p><b>${dateFormat(date)}</b></p><div><h3>${message}</h3></div>`,
      },
    };
    console.log(state);
    console.log(emailToBeSent);

    if (window.confirm("Confirm sending email?")) {
      firestore
        .collection("cloudMails")
        .add(emailToBeSent)
        .then(() => {
          console.log("Email sent!");
          setState({ ...state, message: "" });
        })
        .catch((err) => {
          console.log(err.message);
          alert(err.message);
        });
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setState({ ...state, [id]: value });
  };

  const handleEnter = (emails) => {
    setState({ ...state, toEmails: emails });
  };

  useEffect(() => {
    console.log(state.toEmails);
  }, [state.toEmails]);

  const { message, saleReportObj } = state;

  if (!saleReportObj) {
    return <div className="src-table">No Sale Report</div>;
  }

  return (
    <div className="src-table">
      <h1>Sale Report</h1>
      <div className="src-row-0">
        <div className="src-item">{saleReportObj}</div>
      </div>
      <div className="src-row-0">
        <div className="src-item">{message}</div>
      </div>

      <div className="src-row-0">
        <div className="src-item">
          <EmailsChipsInput handleEnter={handleEnter} />
        </div>
      </div>
      <div className="src-row-0">
        <form className="src-form" onSubmit={sendEmail}>
          <FormInput
            type="textarea"
            name="message"
            id="message"
            value={message}
            onChange={handleChange}
            label="Message"
            required
          />
          <CustomButton type="submit">Send Email</CustomButton>
        </form>
      </div>
      <div className="src-row-0">
        <div className="src-project">
          <p></p>
          <div>
            <b>Emails References</b>
          </div>
          <div>klproject@iqiglobal.com</div>
          <div>penangproject@iqiglobal.com</div>
          <div>sabahproject@iqiglobal.com</div>
          <div>sarawakproject@iqiglobal.com</div>
          <div>johorproject@iqiglobal.com</div>
          <div>perakproject@iqiglobal.com</div>
          <div>melakaproject@iqiglobal.com</div>
        </div>
      </div>
      <div className="src-row-0">
        <div className="src-project">
          <ListPhotos imageTag={state.sale.id} />
        </div>
      </div>
      <div className="src-row-0">
        <div className="src-project">
          <h2>Upload Images</h2>
          <UploadPhoto imageTag={state.sale.id} />
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = createStructuredSelector({
  saleReport: selectSaleSaleReport,
  currentUser: selectCurrentUser,
  users: selectUserUsers,
  sales: selectSaleSales,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStatesToProps, mapDispatchToProps)(SaleReport)
);
