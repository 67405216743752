import React from 'react'
import './lead.styles.scss'
import {connect} from 'react-redux'
import { toggleLeadFormHidden, setCurrentLead } from '../../Redux/lead/lead.actions';
import { createStructuredSelector } from 'reselect';
import { selectLeadLeadFormHidden, selectLeadLeadFormId } from '../../Redux/lead/lead.selector';
import LeadForm from '../lead-form/lead-form.component';
import {dateFormat} from '../../util-functions/date-format';
import { Link } from 'react-router-dom';

const Lead = ({lead, toggleLeadFormHidden, leadFormHidden, leadFormId, i}) => {
        const {date, eventTitle, name, email, phone, referral, affiliateId, remark} = lead;

        const handleClickToggle = () => {
            toggleLeadFormHidden(lead.id);
        }

        //formate date to yyyy-mm-dd
        const formatted_date = dateFormat(date);

        //display lead form pop up
        const toDisplay = !leadFormHidden && (leadFormId === lead.id);

        //render header of table if i = 0
        let Header = null;
        if(i===0){
            Header = (
            <div className='lc-row lc-header'>
                <div className='lc-count'>No.</div>
                <div className='lc-date'>Date</div> 
                <div className='lc-event'>Event Title</div>
                <div className='lc-name'>Name</div>
                <div className='lc-email'>Email</div>
                <div className='lc-phone'>Phone</div>
                <div className='lc-referral'>Referral</div>
                <div className='lc-remark'>Remark</div>
                <div className='lc-icon'></div>
            </div>
            )
        } else { Header = null}
            return(
            <React.Fragment>
            {Header}
            <div className='lc-row'>
                <div className='lc-count'>{i+1}</div>
                <div className='lc-date'>{formatted_date}</div> 
                <div className='lc-event'>{eventTitle}</div>
                <div className='lc-name'>{name}</div>
                <div className='lc-email'>{email}</div>
                <div className='lc-phone'>{phone}</div>
                <div className='lc-referral'>{referral}{affiliateId}</div>
                <div className='lc-remark'>{(remark) ? <div>{remark}</div> : 'remarks'}</div>
                <div className='lc-icon' onClick={handleClickToggle}>&#9776;</div>
                {(!toDisplay) ? null : <LeadForm lead={lead}/>}
                <Link className='lc-icon' to={`/lead/${lead.id}`}>&#9876;</Link>
            </div>
            </React.Fragment>
        )    
}

const mapStatesToProps = createStructuredSelector({
    leadFormHidden: selectLeadLeadFormHidden,
    leadFormId: selectLeadLeadFormId
})

const mapDispatchToProps = dispatch => ({
    toggleLeadFormHidden: (id) => dispatch(toggleLeadFormHidden(id)),
    setCurrentLead: (lead)=>dispatch(setCurrentLead(lead))
  });

export default connect(mapStatesToProps, mapDispatchToProps)(Lead) 