import {SaleActionTypes} from "./sale.types";
import {takeLatest, call, put, all, select} from "redux-saga/effects";
import {firestore} from "../../firebase/firebase.utils";
import {convertSalesDocsToMap, sendSaleToTelegram} from "./sale.utils";
import {getRen, getProject, getCommissionRate} from "./sale.utils";
import {fetchUserDownlinesStartAsync, fetchUsersStartAsync} from "../user/user.sagas";
import {fetchSalesStart} from "./sale.actions";
import {numberToPercentage} from "../../util-functions/numer-formating";
import {addNumberOfSales} from "../parameter/parameter.actions";
import {getStartDateEndDateForQuarter} from "../../util-functions/date-format";
import {UserActionTypes} from "../user/user.types";
import {sendTelegramMessage} from "../../telegram/sendTelegramMessage";

const getCurrentUser = (state) => state.user.currentUser;
const getUserDownlines = (state) => state.user.downlines;
const getProjectProjects = (state) => state.project.projects;
const getCommissionCommStructures = (state) => state.commission.commStructures;
const getUserUsers = (state) => state.user.users;
const getSaleSales = (state) => state.sale.sales;
const getSaleTeamSales = (state) => state.sale.teamSales;
const getSaleTotalSalesAndCommForRens = (state) => state.sale.totalSalesAndCommForRens;

export function* fetchSalesStartAsync() {
	let currentUser = yield select(getCurrentUser);
	let downlines = yield select(getUserDownlines);

	if (!currentUser) {
		console.log("No current User.");
		return null;
	}

	if (!downlines) {
		console.log("No downlines.");
		downlines = [
			{
				downlines: [{userFid: currentUser.id, userName: currentUser.displayName}],
			},
		];
	}

	//get all personalSalesIndex from each downline and push to teamSalesIndex
	let teamSalesIndex = [];
	let downlinesDocs = yield all(
		downlines[0].downlines.map((downline) => {
			return firestore.collection("users").doc(downline.userFid).get();
		})
	);
	downlinesDocs.forEach((downlineDoc) => {
		try {
			if (downlineDoc.data().personalSalesIndex && downlineDoc.data().personalSalesIndex.length) {
				downlineDoc.data().personalSalesIndex.forEach((saleFid) => {
					if (saleFid !== "") {
						teamSalesIndex.push(saleFid);
					}
				});
			}
		} catch (err) {
			console.log(err);
			console.log(downlineDoc);
		}
	});
	//remove duplicated saleIds
	teamSalesIndex = teamSalesIndex.filter((v, i) => teamSalesIndex.findIndex((index) => index === v) === i);

	//start isFetching to turn withSpinner on
	yield put(fetchSalesStart());

	let docs = "";
	let snapshot = "";

	try {
		if (!currentUser) {
			return null;
		}
		// const isAdmin = false;
		if (currentUser.isAdmin) {
			const collectionRef = firestore.collection("sales");
			snapshot = yield collectionRef.get();
			docs = snapshot.docs;
		} else {
			//use yield all to wait for all returned docs to resolved
			docs = yield all(
				teamSalesIndex.map((saleId) => {
					return firestore.collection("sales").doc(saleId).get();
				})
			);
		}
		
		docs = docs.filter((doc) => doc.exists); //filter out documents that do not exist

		const salesMap = convertSalesDocsToMap(docs);
		yield put({type: SaleActionTypes.FETCH_SALES_SUCCESS, payload: salesMap});
	} catch (err) {
		console.log(err.message);
	}
}

export function* loadTeamSales({payload: {dateStart, dateEnd}}) {
	console.log(dateStart);
	let downlines = yield select(getUserDownlines);
	let flattenedSales = [];
	const projects = yield select(getProjectProjects);
	const commStructures = yield select(getCommissionCommStructures);
	const users = yield select(getUserUsers);
	let sales = yield select(getSaleSales);
	sales = sales.filter(
		(sale) => new Date(sale.date) >= new Date(dateStart) && new Date(sale.date) <= new Date(dateEnd)
	);
	console.log(dateStart, dateEnd);
	console.log("LOAD TEAM SALES START");
	sales.forEach((sale) => {
		const {rens, ...others} = sale;
		const project = getProject(sale.projectFid, projects);

		const commStructureFid = project.commStructureFid;
		const commRate = getCommissionRate(commStructureFid, sale.date, commStructures);
		rens.forEach((ren, i) => {
			const commForRen = !sale.manualCommission
				? parseFloat(commRate) / 100 * parseFloat(sale.netPrice) * parseFloat(ren.renPercentage) / 100
				: parseFloat(sale.manualCommission) * parseFloat(ren.renPercentage) / 100;
			const netPriceForRen = parseFloat(ren.renPercentage) / 100 * parseFloat(sale.netPrice);
			const saleObject = {
				...others,
				spaPrice: parseFloat(sale.spaPrice),
				netPrice: parseFloat(sale.netPrice),
				renName: getRen(ren.userFid, users).displayName ? getRen(ren.userFid, users).displayName : ren.renName,
				renId: ren.userFid,
				renPercentage: numberToPercentage(ren.renPercentage),
				projectName: project.projectName,
				netPriceForRen: netPriceForRen,
				commissionRate: commRate,
				commForRen: commForRen,
				order: i,
			};
			flattenedSales.push(saleObject);
		});
	});

	flattenedSales.sort((a, b) => new Date(b.date) - new Date(a.date));
	console.log(flattenedSales);
	yield put({
		type: SaleActionTypes.LOAD_FLATTENED_SALES,
		payload: flattenedSales,
	});

	if (downlines && downlines.length) {
		downlines = downlines[0].downlines;
		let teamSales = [];
		let salesIdArray = [];

		downlines.forEach((downline) => {
			flattenedSales.forEach((sale) => {
				if (sale.renId === downline.userFid) {
					salesIdArray.push(sale.id);
					teamSales.push(sale);
				}
			});
		});

		teamSales.sort((a, b) => new Date(b.date) - new Date(a.date));
		yield put({
			type: SaleActionTypes.LOAD_TEAM_SALES_SUCCESS,
			payload: teamSales,
		});
	} else {
		console.log("Downlines not loaded!");
		yield put({
			type: SaleActionTypes.LOAD_TEAM_SALES_FAILURE,
			payload: flattenedSales,
		});
	}
}

export function* loadTotalSalesByRens({payload: {dateStart, dateEnd, status}}) {
	const teamSales = yield select(getSaleTeamSales);
	const downlines = yield select(getUserDownlines);

	if (!teamSales || !teamSales.length) {
		console.log("No Sales loaded!");
		yield put({
			type: SaleActionTypes.LOAD_TOTAL_SALES_AND_COMM_FOR_RENS_SUCCESS,
			payload: [],
		});
		return [];
	}
	if (!downlines || !downlines.length) {
		console.log("No downlines loaded");
		yield put({
			type: SaleActionTypes.LOAD_TOTAL_SALES_AND_COMM_FOR_RENS_SUCCESS,
			payload: [],
		});
		return [];
	}
	console.log(dateStart, dateEnd);

	const getTotalSalesAndComm = (ren, dateStart, dateEnd, status) => {
		let salesForRen = teamSales.filter((teamSale) => teamSale.renId === ren.userFid);
		salesForRen = salesForRen.filter((sale) => {
			if (new Date(sale.date) >= new Date(dateStart) && new Date(sale.date) <= new Date(dateEnd)) {
				return true;
			} else {
				return false;
			}
		});

		let totalSales = 0;
		let totalComm = 0;
		switch (status) {
			case "Booked":
				totalSales = salesForRen.reduce((accumulativeSales, sale) => {
					if (sale.status !== "Cancelled") {
						return accumulativeSales + sale.netPriceForRen;
					} else {
						return accumulativeSales;
					}
				}, 0);

				totalComm = salesForRen.reduce((accumulativeComm, sale) => {
					if (sale.status !== "Cancelled") {
						return accumulativeComm + sale.commForRen;
					} else {
						return accumulativeComm;
					}
				}, 0);
				break;
			case "Converted":
				totalSales = salesForRen.reduce((accumulativeSales, sale) => {
					if (sale.status === "Converted") {
						return accumulativeSales + sale.netPriceForRen;
					} else {
						return accumulativeSales;
					}
				}, 0);

				totalComm = salesForRen.reduce((accumulativeComm, sale) => {
					if (sale.status === "Converted") {
						return accumulativeComm + sale.commForRen;
					} else {
						return accumulativeComm;
					}
				}, 0);
				break;
			default:
		}
		return {totalSales, totalComm};
	};

	let totalSalesAndCommForRens = [];
	downlines[0].downlines.forEach((downline) => {
		const {totalSales, totalComm} = getTotalSalesAndComm(downline, dateStart, dateEnd, status);
		if (!totalSales) {
			return null;
		}
		totalSalesAndCommForRens.push({
			renName: downline.userName,
			totalSales: totalSales,
			totalComm: totalComm,
		});
	});
	totalSalesAndCommForRens.sort((a, b) => b.totalSales - a.totalSales);

	yield put({
		type: SaleActionTypes.LOAD_TOTAL_SALES_AND_COMM_FOR_RENS_SUCCESS,
		payload: totalSalesAndCommForRens,
	});
}

export function* loadTotalSalesByProjects({payload: {dateStart, dateEnd, status}}) {
	let teamSales = yield select(getSaleTeamSales);
	const projects = yield select(getProjectProjects);

	teamSales = teamSales.filter(
		(sale) => new Date(sale.date) >= new Date(dateStart && new Date(sale.date) <= new Date(dateEnd))
	);
	const getProjectTotalSale = (project) => {
		if (status === "Booked") {
			let projectSales = teamSales.filter(
				(sale) =>
					(sale.projectFid === project.id && sale.status === "Booked") ||
					(sale.projectFid === project.id && sale.status === "Converted")
			);
			return projectSales.reduce((accumulativeSales, sale) => {
				return accumulativeSales + sale.netPriceForRen;
			}, 0);
		}
		if (status === "Converted") {
			let projectSales = teamSales.filter(
				(sale) => sale.projectFid === project.id && sale.status === "Converted"
			);
			return projectSales.reduce((accumulativeSales, sale) => {
				return accumulativeSales + sale.netPriceForRen;
			}, 0);
		}
	};

	const getProjectTotalComm = (project) => {
		if (status === "Booked") {
			return teamSales
				.filter(
					(sale) =>
						(sale.projectFid === project.id && sale.status === "Booked") ||
						(sale.projectFid === project.id && sale.status === "Converted")
				)
				.reduce((accumulativeComm, sale) => {
					return accumulativeComm + sale.commForRen;
				}, 0);
		}
		if (status === "Converted") {
			return teamSales
				.filter((sale) => sale.projectFid === project.id && sale.status === "Converted")
				.reduce((accumulativeComm, sale) => {
					return accumulativeComm + sale.commForRen;
				}, 0);
		}
	};

	let projectTotalSalesAndComm = [];
	projects.forEach((project) => {
		let totalSales = getProjectTotalSale(project);
		let totalComm = getProjectTotalComm(project);
		projectTotalSalesAndComm.push({
			projectFid: project.id,
			projectName: project.projectName,
			totalSales: totalSales,
			totalComm: totalComm,
		});
	});

	projectTotalSalesAndComm = projectTotalSalesAndComm.filter((sale) => sale.totalSales !== 0);
	projectTotalSalesAndComm.sort((a, b) => b.totalSales - a.totalSales);

	yield put({
		type: SaleActionTypes.LOAD_TOTAL_SALES_BY_PROJECTS_SUCCESS,
		payload: projectTotalSalesAndComm,
	});
}

export function* addNewSaleStart({payload: sale}) {
	const collectionRef = firestore.collection("sales");
	const parametersRef = firestore.collection("parameters").doc("parameters");
	const parametersSnapshot = yield parametersRef.get();
	const parameters = parametersSnapshot.data();

	const doc = yield collectionRef.add({
		...sale,
		saleId: parameters.numberOfSales + 1,
	});
	try {
		const newSale = {...sale, id: doc.id, saleId: parameters.numberOfSales + 1};
		const oldSalesArray = yield select(getSaleSales);
		const newSalesArray = [...oldSalesArray]; //spread operator to duplicate the array
		newSalesArray.push(newSale);
		console.log("Sale Added into Firestore");
		yield put({
			type: SaleActionTypes.ADD_SALE_SUCCESS,
			payload: newSalesArray,
		});
		yield put(addNumberOfSales());
		yield put({
			type: SaleActionTypes.UPDATE_SALE_REPORT,
			payload: newSale,
		});
		const {startDate, endDate} = getStartDateEndDateForQuarter();
		yield put({
			type: SaleActionTypes.LOAD_TEAM_SALES_START,
			payload: {startDate, endDate},
		});
		yield put({
			type: UserActionTypes.UPDATE_PERSONAL_SALES_INDEX_START,
			payload: newSale,
		});
		yield call(sendSaleToTelegram, newSale);
	} catch (error) {
		console.log(error.message);
	}
}

export function* loadDataSagas() {
	const sagas = [fetchUsersStartAsync, fetchUserDownlinesStartAsync, fetchSalesStartAsync];

	console.log("Loading Data in Sequence");
	for (let saga of sagas) {
		yield call(saga);
	}
}

export function* loadTeamSalesAndSendTelegram({payload: {dateStart, dateEnd, chatId}}) {
	yield call(loadTeamSales, {payload: {dateStart, dateEnd, chatId}});
	yield call(loadTotalSalesByRens, {payload: {dateStart, dateEnd, status: "Booked"}});
	yield call(sendSalesReportToTelegramStart, {payload: {dateStart, dateEnd, chatId}});
}

export function* sendSalesReportToTelegramStart({payload: {dateStart, dateEnd, chatId}}) {
	const currentUser = yield select(getCurrentUser);
	const sales = yield select(getSaleTotalSalesAndCommForRens);
	const salesLimit = sales.slice(0, 10);
	let salesToDisplay = "";
	salesLimit.forEach((sale, i) => {
		salesToDisplay =
			salesToDisplay + (i + 1) + " " + sale.renName + " : RM" + parseInt(sale.totalSales).toLocaleString() + "\n";
	});
	const message = "Date: " + dateStart + " to " + dateEnd + "\nTop 10 in your Team in 2020\n" + salesToDisplay;
	console.log(message);
	sendTelegramMessage(chatId, message, currentUser.displayName);
}

//////////////////
//Watchers Sagas
/////////////////
export function* onLoadTeamSalesStart() {
	yield takeLatest(SaleActionTypes.LOAD_TEAM_SALES_START, loadTeamSales);
}

export function* onLoadTotalSalesAndCommForRens() {
	yield takeLatest(SaleActionTypes.LOAD_TOTAL_SALES_AND_COMM_FOR_RENS_START, loadTotalSalesByRens);
}

export function* onLoadDataInSequenceStart() {
	yield takeLatest(SaleActionTypes.LOAD_DATA_IN_SEQUENCE_START, loadDataSagas);
}

export function* onLoadTotalSalesByProjects() {
	yield takeLatest(SaleActionTypes.LOAD_TOTAL_SALES_BY_PROJECTS_START, loadTotalSalesByProjects);
}

export function* onAddNewSale() {
	yield takeLatest(SaleActionTypes.ADD_NEW_SALE, addNewSaleStart);
}

export function* onLoadTeamSalesAndSendTelegram() {
	yield takeLatest(SaleActionTypes.LOAD_TEAM_SALES_AND_SEND_TELEGRAM, loadTeamSalesAndSendTelegram);
}

export function* onSendSalesReportToTelegram() {
	yield takeLatest(SaleActionTypes.SEND_SALES_REPORT_TO_TELEGRAM, sendSalesReportToTelegramStart);
}

export function* saleSagas() {
	yield all([
		call(onLoadTeamSalesStart),
		call(onLoadDataInSequenceStart),
		call(onLoadTotalSalesAndCommForRens),
		call(onLoadTotalSalesByProjects),
		call(onAddNewSale),
		call(onLoadTeamSalesAndSendTelegram),
		call(onSendSalesReportToTelegram),
	]);
}
