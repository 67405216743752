import { all, call } from 'redux-saga/effects';

import { userSagas } from './user/user.sagas';
import { saleSagas } from './sale/sale.sagas';
import { campaignSagas } from './campaign/campaign.sagas';
import { photoSagas } from './photo/photo.sagas';
import { trainingSagas } from './training/training.sagas';
// import { projectSagas } from './project/project.sagas';
// import { commissionSagas } from './commission/commission.sagas';
// import { parameterSagas } from './parameter/parameter.sagas';
// import { leadSagas } from './lead/lead.sagas';

export default function* rootSaga() {
  yield all([
        call(userSagas),
        call(saleSagas),
        call(campaignSagas),
        call(photoSagas),
        call(trainingSagas),
    ]);
}
