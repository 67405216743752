import React from 'react';
import './project.styles.scss';

import { withRouter } from "react-router-dom";
import {dateFormat} from '../../util-functions/date-format';
import { selectCurrentUser } from '../../Redux/user/user.selector';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux'


class Project extends React.Component{

    handleClick = (key) =>{
        const {history, project} = this.props;
        switch(key){
            case 'commStructureId':
                return history.push(`/comm-structure/${project['commStructureFid']}`);
            default:
                return null
        }
    }

    render(){
        const {project, i, fieldToDisplay} = this.props;

        return(
            <React.Fragment>
            {!i &&
            <div className='pc-row-header'>
                {Object.keys(fieldToDisplay).map((key,i) => {
                    return(
                        <div key={`header${i}`} className={`pc-item pc-header pc-${key}`}>{fieldToDisplay[key]}</div>
                    )
                })}
            </div>
            }
            <div className={'pc-row'}>
            {Object.keys(fieldToDisplay).map((key,i) => {
                return(
                    <div key={`${fieldToDisplay}${i}`} className={`pc-item pc-${key}`}>
                    {(()=>{
                        switch(key){
                        case 'commStructureId':
                            return <React.Fragment>{<div style={{cursor: 'pointer'}} onClick={()=>this.handleClick(key)}>{project[key]}</div>}</React.Fragment>
                        case 'createdAt':
                            return <div>{dateFormat(project[key])}</div>
                        default:
                            return <div>{project[key]}</div> 
                        }
                    })()}{/*execute the function*/}
                    </div>
                )
            })}
            </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
  });
  
const mapDispatchToProps = dispatch => ({
   
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Project));
