import { ParameterActionTypes } from './parameter.types';
import {firestore} from '../../firebase/firebase.utils';
import {convertParametersSnapshotToMap} from './parameter.utils';

export const addNumberOfSales = () => {
   return (dispatch) => {
    const docRef = firestore.collection('parameters').doc('parameters')
    let oldSalesParameters = '';
    docRef.get().then(doc=>{
        oldSalesParameters = doc.data()

        docRef.set({
            ...oldSalesParameters,
            numberOfSales: oldSalesParameters.numberOfSales + 1,
        }).then(()=>{
            dispatch({
                type: ParameterActionTypes.ADD_PARAMETER_SALE,
                payload: oldSalesParameters.numberOfSales + 1,
            })
            console.log('Firestore Parameters updated with newNumberOfSales' )
        }).catch(err=>{
            console.log(err.message)
        })

    }).catch(err=>{
        console.log(err.message)
    })
}
};

export const addNumberOfProjects = () => {
    return (dispatch) => {
     const docRef = firestore.collection('parameters').doc('parameters')
     console.log(docRef)
     let oldParameters = '';
     docRef.get().then(doc=>{
         console.log(doc.data())
         oldParameters = doc.data()
 
         docRef.set({
             ...oldParameters,
             numberOfProjects: oldParameters.numberOfProjects + 1,
         }).then(()=>{
             dispatch({
                 type: ParameterActionTypes.ADD_PARAMETER_PROJECT,
                 payload: oldParameters.numberOfProjects + 1,
             })
             console.log('Firestore Parameters updated with newNumberOfProjects' )
         }).catch(err=>{
             console.log(err.message)
         })
 
     }).catch(err=>{
         console.log(err.message)
     })
 }
 };

 export const addNumberOfCommStructures = () => {
    return (dispatch) => {
     const docRef = firestore.collection('parameters').doc('parameters')
     console.log(docRef)
     let oldParameters = '';
     docRef.get().then(doc=>{
         console.log(doc.data())
         oldParameters = doc.data()
 
         docRef.set({
             ...oldParameters,
             numberOfCommStructures: oldParameters.numberOfCommStructures + 1,
         }).then(()=>{
             dispatch({
                 type: ParameterActionTypes.ADD_PARAMETER_COMMISSION_STRUCTURE,
                 payload: oldParameters.numberOfCommStructures + 1,
             })
             console.log('Firestore Parameters updated with newNumberOfCommStructures' )
         }).catch(err=>{
             console.log(err.message)
         })
 
     }).catch(err=>{
         console.log(err)
     })
 }
 };

 export const addNumberOfProjectsAndCS = () => {
    return (dispatch) => {
     const docRef = firestore.collection('parameters').doc('parameters')
     console.log(docRef)
     let oldParameters = '';
     docRef.get().then(doc=>{
         console.log(doc.data())
         oldParameters = doc.data()
 
         docRef.set({
             ...oldParameters,
             numberOfProjects: oldParameters.numberOfProjects + 1,
             numberOfCommStructures: oldParameters.numberOfCommStructures + 1,
         }).then(()=>{
             dispatch({
                 type: ParameterActionTypes.ADD_PARAMETER_PROJECT_AND_CS,
                 payload: {
                     numberOfProjects: oldParameters.numberOfProjects + 1,
                     numberOfCommStructures: oldParameters.numberOfCommStructures + 1,
                 }
             })
             console.log('Firestore Parameters updated with newNumberOfProjectsAndCS' )
         }).catch(err=>{
             console.log(err.message)
         })
 
     }).catch(err=>{
         console.log(err.message)
     })
 }
 };

export const fetchParametersStartAsync = () =>{
    return (dispatch) => {
        const collectionRef = firestore.collection('parameters');

        collectionRef
            .get()
            .then(snapShot =>{
                const parametersMap = convertParametersSnapshotToMap(snapShot)
                dispatch({
                    type: ParameterActionTypes.FETCH_PARAMETERS_SUCCESS,
                    payload: parametersMap,

                })
            }).catch(err=>{
                console.log(err)
            })
    }
}