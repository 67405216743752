import React from 'react';
import './user-change-password.styles.scss';

import FormInput from '../../components/form-input/form-input.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import { connect } from 'react-redux';
import {selectCurrentUser, selectUserUsers} from '../../Redux/user/user.selector';
import {createStructuredSelector} from 'reselect';
import { auth } from '../../firebase/firebase.utils';

class UserChangePassword extends React.Component {

    constructor(){
        super();

        this.state={
            password: '',
            confirmPassword: '',
        }
    }

    handleChange = (event)=>{
        const {name, value} = event.target
        this.setState({...this.state, [name]: value });
    }

    handleSubmit = (event)=>{
        event.preventDefault();
        const firebaseUser = auth.currentUser;
        const {history} = this.props;

        if(this.state.password === this.state.confirmPassword){
            firebaseUser.updatePassword(this.state.password).then(() => {
                    alert('Password Changed successfully')
                    this.setState={
                        password: '',
                        confirmPassword: ''
                    }
                    history.push('/');
                }).catch((error) => {
                    alert(error.message);
            })
        }else{
            alert('Password must be the same with confirmPassword');
        }
    }

    render(){
        return(
            <div className='ucpc-table'>
                <div className='ucpc-header'>Change your Password</div>
                <div className='ucpc-row'>
                    <div className='ucpc-form'>
                        <form onSubmit={this.handleSubmit}>
                        <FormInput
                            type='password'
                            name='password'
                            value={this.state.password}
                            onChange={this.handleChange}
                            label='password'
                            required
                        />
                        <FormInput
                            type='password'
                            name='confirmPassword'
                            value={this.state.confirmPassword}
                            onChange={this.handleChange}
                            label='Confirm Password'
                            required
                        />
                        <CustomButton type='submit'>Change Password</CustomButton>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
    users: selectUserUsers,
  });

export default connect(mapStateToProps)(UserChangePassword)