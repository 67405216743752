import { ProjectActionTypes } from './project.types';

const INITIAL_STATE = {
    projects: [],
    isFetching: '',
    isProjectsLoaded:{ isLoaded: false, lastLoaded:'2020-05-01'},
};

const projectReducer = (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case ProjectActionTypes.FETCH_PROJECTS_START:
            return{
                ...state,
                isFetching: true,
                isProjectsLoaded: false,
            }
        case ProjectActionTypes.FETCH_PROJECTS:
            return{
                ...state,
                isFetching: true,
            }
        case ProjectActionTypes.FETCH_PROJECTS_SUCCESS:
            return{
                ...state,
                projects: action.payload,
                isFetching: false,
                isProjectsLoaded: {isLoaded: true, lastLoaded: new Date()},
            }
        case ProjectActionTypes.ADD_PROJECT_SUCCESS:
            return{
                ...state,
                projects: action.payload,
            }
        default:
            return state
        }
    }

export default projectReducer