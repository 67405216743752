export const SaleActionTypes = {
    FETCH_SALES_START: 'FETCH_SALES_START',
    ADD_NEW_SALE: 'ADD_NEW_SALE',
    ADD_SALE_SUCCESS: 'ADD_SALE_SUCCESS',
    FETCH_SALES_SUCCESS: 'FETCH_SALES_SUCCESS',
    UPDATE_SALE: 'UPDATE_SALE',
    LOAD_FLATTENED_SALES: 'LOAD_FLATTENED_SALES',
    DELETE_SALE_SUCCESS: 'DELETE_SALE_SUCCESS',
    LOAD_TEAM_SALES_START: 'LOAD_TEAM_SALES_START',
    LOAD_TEAM_SALES_SUCCESS: 'LOAD_TEAM_SALES_SUCCESS',
    LOAD_TEAM_SALES_FAILURE: 'LOAD_TEAM_SALES_FAILURE',
    SALE_SIGN_OUT: 'SALE_SIGN_OUT',
    LOAD_TOTAL_SALES_AND_COMM_FOR_RENS_START: 'LOAD_TOTAL_SALES_AND_COMM_FOR_RENS_START',
    LOAD_TOTAL_SALES_AND_COMM_FOR_RENS_SUCCESS: 'LOAD_TOTAL_SALES_AND_COMM_FOR_RENS_SUCCESS',
    UPDATE_SALE_REPORT: 'UPDATE_SALE_REPORT',
    LOAD_DATA_IN_SEQUENCE_START: 'LOAD_DATA_IN_SEQUENCE_START',
    LOAD_TOTAL_SALES_BY_PROJECTS_START: 'LOAD_TOTAL_SALES_BY_PROJECTS_START',
    LOAD_TOTAL_SALES_BY_PROJECTS_SUCCESS: 'LOAD_TOTAL_SALES_BY_PROJECTS_SUCCESS',
    LOAD_TEAM_SALES_AND_SEND_TELEGRAM: 'LOAD_TEAM_SALES_AND_SEND_TELEGRAM',
    SEND_SALES_REPORT_TO_TELEGRAM: 'SEND_SALES_REPORT_TO_TELEGRAM',
}
