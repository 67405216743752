import React from 'react';
import './user-downlines-page.styles.scss';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectUserDownlines } from '../../Redux/user/user.selector';


const UserDownlinesPage = ({downlines}) => {
    console.log(downlines)

    return(
        <div className='pcpc-table'>
            <div className='pcpc-row-0'>
                Downline ID = {downlines[0].id}
            </div>
        {downlines[0].downlines.map((downline,i)=>(
            <div className='pcpc-row-0' key={downline.userFid}>
                <div className='pcpc-item'>{i+1}</div>
                <div className='pcpc-item'>{downline.userFid}</div>
                <div className='pcpc-item'>{downline.userName}</div>
            </div>
        ))}
        </div>
    )
}
    

const mapStatesToProps = createStructuredSelector({
    downlines: selectUserDownlines,
    
});
  
const mapDispatchToProps = dispatch => ({

});

export default connect(mapStatesToProps, mapDispatchToProps)(UserDownlinesPage);
