import React from 'react';
import './sale-teamsale.styless.scss';

import uuid from 'react-uuid';
import { numberWithCommas } from '../../util-functions/numer-formating';
import { dateFormat } from '../../util-functions/date-format';
import { withRouter } from 'react-router-dom';
import { updateSale } from '../../Redux/sale/sale.actions';
import { createStructuredSelector} from 'reselect';
import { connect } from 'react-redux';
import { selectSaleSales } from '../../Redux/sale/sale.selectors';
import ItemsDropDown from '../items-dropdown/items-dropdown.component';

class SaleTeamSale extends React.Component{
    state={
        showDropDown: {status: false},
        status: '',
    }

    handleUpdateStatus = (item, name, key) => {
        const {updateSale} = this.props;
        console.log(item[key])
        let { sale, sales } = this.props;
        let returnedSale = sales.find(s => s.id === sale.id);
        returnedSale = {...returnedSale, date: new Date(returnedSale.date), status: item[key]}
        this.setState({...this.state, showDropDown: {status: false}});
        updateSale(returnedSale, 'status');
    }

    toggleDropDown = (name) => {
        this.setState({
            ...this.state,
            showDropDown: {
                         [name]:!this.state.showDropDown[name]
                        }
        })
    }

    handleReport = (sale) => {
        const {history} = this.props;
        history.push(`/sale-report/${sale.id}`)
    }

    render(){
        let {fieldToDisplay} = this.props;
        const {sale, i, handleClick, isFirstLine} = this.props;
     
        return(
            <React.Fragment>
            {!i &&
            <div className='stc-row-header'>
                {Object.keys(fieldToDisplay).map((key,i) => {
                    return(
                        <div key={uuid()} className={`stc-header stc-${key}`}>{fieldToDisplay[key]}</div>
                    )
                })}
            </div>
            }
            <div className={!isFirstLine ? 'stc-row' : 'stc-row-border'} >
            {Object.keys(fieldToDisplay).map((key,i) => {
                return(
                    <div key={uuid()} className={`stc-${key}`} >
                    {(()=>{
                        switch(key){
                            case 'netPrice':
                                return <React.Fragment>{!sale['order'] ? numberWithCommas(sale[key]) : null}</React.Fragment>
                            case 'saleId':
                                return <div className='stc-saleId-Item' onClick={()=>{handleClick(sale)}}>{!sale['order'] ? sale[key] : null}</div>
                            case 'remark':
                                return <React.Fragment>{!sale['order'] ? sale[key] : null}</React.Fragment>
                            case 'date':
                                return <React.Fragment>{!sale['order'] ? dateFormat(sale[key]) : null}</React.Fragment>
                            case 'projectName':
                                return <React.Fragment>{!sale['order'] ? sale[key] : null}</React.Fragment>
                            case 'unitNumber':
                                return <React.Fragment>{!sale['order'] ? sale[key] : null}</React.Fragment>
                            case 'buyerName':
                                return <React.Fragment>{!sale['order'] ? sale[key] : null}</React.Fragment>
                            case 'commForRen':
                                return <React.Fragment>{numberWithCommas(sale[key])}</React.Fragment>
                            case 'netPriceForRen':
                                return <React.Fragment>{numberWithCommas(sale[key])}</React.Fragment>
                            case 'status':
                                return <React.Fragment>
                                            <div className='stc-report' onClick={()=>this.handleReport(sale)}>Report</div>
                                            <div className='stc-update' onClick={()=>handleClick(sale)}>Update</div>
                                            <div className={`stc-status-${sale[key]} stc-status-item`} onClick={()=>{this.toggleDropDown('status')}}>{(sale[key])}</div>
                                            {this.state.showDropDown['status'] ?
                                            <ItemsDropDown
                                                            name='status'
                                                            type='text'
                                                            items = {[{status: 'Booked'}, {status: 'Converted'}, {status: 'Cancelled'}]} //Items to pass in
                                                            keyToMap = 'status' //List out the values corresposing to the key
                                                            clickItem = {this.handleUpdateStatus} //list state up function
                                                            toggleDropDown = {this.toggleDropDown}
                                                        /> : null
                                            }
                                        </React.Fragment>  
                            default:
                                return <React.Fragment>{sale[key]}</React.Fragment>
                        }
                    })()}
                    </div>
                )
            })}
            </div>
            </React.Fragment>
        )
    }
}

const mapStatesToProps = createStructuredSelector({
    sales: selectSaleSales,
});
  
const mapDispatchToProps = dispatch => ({
   updateSale: (sale, additionalOptions)=>dispatch(updateSale(sale, additionalOptions)),

});

export default withRouter(connect(mapStatesToProps, mapDispatchToProps)(SaleTeamSale));

