import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBKz0o7TIPwAXBuJa6q9nrw50AVbHJqDZ4",
  authDomain: "eliteone-react-sales-app.firebaseapp.com",
  databaseURL: "https://eliteone-react-sales-app.firebaseio.com",
  projectId: "eliteone-react-sales-app",
  storageBucket: "eliteone-react-sales-app.appspot.com",
  messagingSenderId: "293570880164",
  appId: "1:293570880164:web:3004eb0177ceaf6c3ffc31",
  measurementId: "G-M3954SWL64"
};

firebase.initializeApp(firebaseConfig);

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  const collectionRef = firestore.collection(collectionKey);

  const batch = firestore.batch();
  objectsToAdd.forEach(obj => {
    const newDocRef = collectionRef.doc();
    batch.set(newDocRef, obj);
  });

  return await batch.commit();
};

// if (window.location.hostname === "localhost") {
//   firebase.functions().useEmulator("localhost", 5001);
//   firebase.firestore().useEmulator("localhost", 8080);
//   firebase.auth().useEmulator("http://localhost:9099")
//   console.log("Using Local Emulator");
// }

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export default firebase;
