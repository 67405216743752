import React from 'react';
import './campaign.styles.scss'
import { withRouter } from 'react-router-dom';

const Campaign = ({campaign, fieldToDisplay, i, history})=>{

    return(
        <React.Fragment>
            {!i ? 
            <div className='cc-row-header'>
                {Object.keys(fieldToDisplay).map((key,i)=>(
                    <div key={`${key}${i}header`} className={`cc-item-header cc-${key}`}>{fieldToDisplay[key]}</div>
                ))}
            </div> : null}
            <div className='cc-row'>
                {Object.keys(fieldToDisplay).map((key,i)=>(
                    <div key={`${key}${i}`} 
                        className={`cc-item cc-${key}`} 
                        onClick={()=>{history.push(`/campaign-sale-page/${campaign.id}`)}}
                        style={{cursor: 'pointer'}}
                    >
                        {campaign[key]}
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

export default withRouter(Campaign)