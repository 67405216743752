import React from 'react';
import './project-create-page.styles.scss'
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import FormInput from '../../components/form-input/form-input.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import uuid from 'react-uuid';
import { dateFormat } from '../../util-functions/date-format';

import { selectParameterNumberOfCommStructures, selectParameterNumberOfProjects } from '../../Redux/parameter/paramater.selectors';
import { selectCommissionIsLoaded } from '../../Redux/commission/commission.selectors';

import { fetchParametersStartAsync, addNumberOfProjectsAndCS } from '../../Redux/parameter/parameter.actions';
import { fetchCommStructuresStartAsync } from '../../Redux/commission/commission.actions';
import { addProject, addProjectAndCommStructure, fetchProjectsStartAsync } from '../../Redux/project/project.actions';
import { addCommStructure } from '../../Redux/commission/commission.actions';

import { checkIsLoaded } from '../../util-functions/checkIsLoaded';
import { selectProjectIsLoaded } from '../../Redux/project/project.selector';


class ProjectCreatePage extends React.Component {
    constructor(){
        super();

        this.state={
            date: dateFormat(new Date()),
            projectName: '',
            developer: '',
            location: '',
            commission: '',
        }
    }

    componentDidMount(){
        const {fetchParametersStartAsync, fetchCommStructuresStartAsync, isCommStructuresLoaded, fetchProjectsStartAsync, isProjectsLoaded} = this.props;
        fetchParametersStartAsync();
        if(checkIsLoaded(isCommStructuresLoaded)){fetchCommStructuresStartAsync()};
        if(checkIsLoaded(isProjectsLoaded)){fetchProjectsStartAsync()}
    }

    handleChange = (event) => {
        const {id, value} = event.target;
        this.setState({
            [id]: value,
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const {date, projectName, developer, location, commission} = this.state;
        const { numberOfProjects, numberOfCommStructures, addNumberOfProjectsAndCS, addProjectAndCommStructure} = this.props;
        
        const newCommission = {
            commStructureId: numberOfCommStructures + 1,
            commRate: [{
                        date: date,
                        ren: commission,
                    }]
        }

        const newProject = {
            createdAt: date,
            projectId: (numberOfProjects + 1),
            projectName: projectName,
            developer: developer,
            location: location,
            commStructureId: newCommission.commStructureId
        }
        
        addProjectAndCommStructure(newProject, newCommission);
        // addProject(newProject);
        addNumberOfProjectsAndCS();

        this.setState({
            date: dateFormat(new Date()),
            projectName: '',
            developer: '',
            location: '',
            commission: '',
        })
    }

    render(){
     
        return(
            <div className='pcpc-table'>
                <div className='pcpc-row-0'>
                    <div className='pcpc-item'><h1>Create Project</h1></div>
                </div>
                <div className='pcpc-row-0'>
                <form onSubmit = {this.handleSubmit}>
                    <FormInput  
                            type="date" 
                            name={uuid()}
                            id='date'
                            value={this.state.date} 
                            onChange={this.handleChange} 
                            label='Date'
                            required
                    />
                    <FormInput  
                        type="text" 
                        name={uuid()}
                        id='projectName'
                        value={this.state.projectName} 
                        onChange={this.handleChange} 
                        label='Project Name'
                        required
                    />
                    <FormInput  
                        type="text" 
                        name='location'
                        id='location'
                        value={this.state.location} 
                        onChange={this.handleChange} 
                        label='Location'
                        required
                    />
                    <FormInput  
                        type="text" 
                        name='developer'
                        id='developer'
                        value={this.state.developer} 
                        onChange={this.handleChange} 
                        label='Developer'
                        required
                    />
                    <FormInput  
                        type="number" 
                        name='commission'
                        id='commission'
                        value={this.state.commission} 
                        onChange={this.handleChange} 
                        label='Commission'
                        required
                    />
                    <CustomButton type='submit'>Create Project</CustomButton>
                </form>
                </div>
            </div>
        )
    }}
    

const mapStatesToProps = createStructuredSelector({
    numberOfCommStructures: selectParameterNumberOfCommStructures,
    numberOfProjects: selectParameterNumberOfProjects,
    isCommStructuresLoaded: selectCommissionIsLoaded,
    isProjectsLoaded: selectProjectIsLoaded,
});
  
const mapDispatchToProps = dispatch => ({
    fetchParametersStartAsync: () => {dispatch(fetchParametersStartAsync())},
    addProject: (project) => {dispatch(addProject(project))},
    addCommStructure: (commStructure) => {dispatch(addCommStructure(commStructure))},
    addNumberOfProjectsAndCS: ()=>{dispatch(addNumberOfProjectsAndCS())},
    fetchCommStructuresStartAsync: ()=>{dispatch(fetchCommStructuresStartAsync())},
    addProjectAndCommStructure: (project, commStructure)=>{dispatch(addProjectAndCommStructure(project, commStructure))},
    fetchProjectsStartAsync: ()=>{dispatch(fetchProjectsStartAsync())},
});

export default connect(mapStatesToProps, mapDispatchToProps)(ProjectCreatePage);
